import { get, find, filter, orderBy } from "lodash";
import { getUser } from "../account/selectors";

export const getOrders = (state) => get(state, "orders.store.records", []);
export const getCurrentOrder = (state) => get(state, "orders.data", null);

export const getCurrentOrderLive = (state) => {
  const _id = get(state, "orders.data._id", null);
  if (_id) {
    return find(get(state, "orders.store.records", []), { _id });
  }
  return null;
};
export const getOrderById = (state, _id) => {
  if (_id === "new") {
    return state.orders.data;
  }
  return find(get(state, "orders.store.records", []), { _id });
};
export const getUnfinishedOrder = (state) =>
  find(getOrders(state), { status: "Order.Status.Unpaid" });

export const getCurrentUnassignedOrder = (state) => {
  const _id = get(state, "unassignedOrders.data._id", null);
  if (_id) {
    return find(get(state, "unassignedOrders.store.records", []), { _id });
  }
  return undefined;
};

export const getUnconfirmedOrders = (state) =>
  orderBy(
    filter(getOrders(state), (order) => order.status === "Order.Status.Unpaid"),
    ["createdAt"],
    ["desc"]
  );

export const getQuotedOrders = (state) =>
  orderBy(
    filter(getOrders(state), (order) => order.status === "Order.Status.Quoted"),
    ["createdAt"],
    ["desc"]
  );

const inProgressStatuses = [
  "Order.Status.Unassigned",
  "Order.Status.InProgress",
  "Order.Status.Suspended",
];
export const getInProgressOrders = (state) =>
  orderBy(
    filter(getOrders(state), (order) =>
      inProgressStatuses.includes(order.status)
    ),
    ["createdAt"],
    ["desc"]
  );

const completedStatuses = ["Order.Status.Complete", "Order.Status.Cancelled"];
export const getCompletedOrders = (state) =>
  orderBy(
    filter(getOrders(state), (order) =>
      completedStatuses.includes(order.status)
    ),
    ["completedAt"],
    ["desc"]
  );

export const isLoading = (state) =>
  state.orders.isLoading ||
  state.orders.isSaving ||
  state.unassignedOrders.isLoading ||
  state.unassignedOrders.isSaving;

// Specific selectors for translator :
export const getIsLoading = (state) =>
  state.orders.isLoading || state.orders.isSaving;

export const getError = (state) => state.orders.isError;

export const getOrderByIdForTranslator = (state, _id) =>
  find(get(state, "orders.store.records", []), { _id });


const isOffered = (order, translator) =>
  order.status === "Order.Status.Unassigned" &&
  find(order.offeredTo, (o) => o.translator === translator);

export const getOfferedOrders = (state) =>
  orderBy(
    filter(
      getOrders(state),
      (o) => !o.translator && isOffered(o, getUser(state))
    ),
    ["deadline"],
    ["asc"]
  );


export const getInProgressOrdersForTranslator = (state) =>
  orderBy(
    filter(getOrders(state), {
      status: "Order.Status.InProgress",
      translator: getUser(state),
    }),
    ["createdAt"],
    ["desc"]
  );
