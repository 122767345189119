import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { markNotificationsRead } from "../actions";
import { getProfile } from "../account/selectors";
import { getNotifications } from "../notifications/selectors";
import NotificationItem from "../notifications/NotificationItem";
import Header from "../components/Header";
import { Container, Row, Col } from "reactstrap";
import Card from "../layout/Card";

const profileHTML = (profile) =>
  profile ? (
    ""
  ) : (
    <div>
      <Link to="/login">
        <FormattedMessage id="Login" />
      </Link>
    </div>
  );

const notificationsHTML = (notifications) => {
  let html = [];
  const hasNotifications = notifications && notifications.length;
  if (hasNotifications) {
    html = notifications.map((notification) => (
      <NotificationItem key={notification._id} notification={notification} />
    ));
  }
  return html;
};

class NotificationsPage extends Component {
  componentDidMount() {
    this.props.doMarkNotificationsRead();
  }

  render() {
    const { profile, notifications } = this.props;
    return (
      <>
        <Row className="header-container">
          <Col className="content-container">
            <Header />
          </Col>
        </Row>

        <Container>
          <div className="content-container body">
            <Card>
              {profileHTML(profile)}
              {notificationsHTML(notifications)}
            </Card>
          </div>
        </Container>
      </>
    );
  }
}

NotificationsPage.propTypes = {
  profile: PropTypes.object,
  notifications: PropTypes.array,
};

const stateToProps = (state) => ({
  profile: getProfile(state),
  notifications: getNotifications(state),
});

export default connect(stateToProps, {
  doMarkNotificationsRead: markNotificationsRead,
})(NotificationsPage);
