import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const InvoiceLink = ({ invoice }) => (
  <div>
    <Link to={`/invoices/${invoice._id}`}>{invoice.translatorInvoiceId ? invoice.translatorInvoiceId : 'Incomplete invoice'}</Link>
  </div>
);

InvoiceLink.propTypes = {
  invoice: PropTypes.object.isRequired,
};

InvoiceLink.defaultProps = {
  invoice: null,
};

export default InvoiceLink;
