import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { manualSearch, requestRefund } from '../actions';
import Header from '../components/Header';
import { Col, Container, Row } from 'reactstrap';

const OrderRejectionHandler = ({ order, doManualSearch, doRequestRefund }) => {
  if (order) {
    return (
      <>
        <Row className="header-container">
          <Col className="content-container">
            <Header />
          </Col>
        </Row>

        <Container>
          <div className="content-container  body">
            <div>
              
              <div className="section-edit">
                <FormattedMessage id="Order.Status.Suspended" />
              </div>
              <div className="section-text">
                <FormattedMessage id={order.suspendedReason} />
              </div>

              

              {order.suspendedAction ? (
                <div className="section-general">
                  <FormattedMessage
                    id="Order.SuspendedAction"
                    values={{
                      action: (
                        <FormattedMessage
                          id={`${order.suspendedAction}.past`}
                        />
                      ),
                    }}
                  />
                </div>
              ) : (
                <Row className="section-general section-general--horizontal m-0">
                  <Col md="6" sm="12" className="p-1">
                    <button
                      onClick={() => doManualSearch(order._id)}
                      className="button longtext"
                    >
                      <FormattedMessage id="Order.Request.ManualSearch" />
                    </button>
                  </Col>
                  <Col md="6" sm="12" className="p-1">
                    <button
                      onClick={() => doRequestRefund(order._id)}
                      className="button longtext"
                    >
                      <FormattedMessage id="Order.Request.Refund" />
                    </button>
                  </Col>
                </Row>
              )}

              

              <div>
                <div className="section-edit">
                  <FormattedMessage
                    id={
                      order.proofreading
                        ? 'Order.View.Text.Proofreading'
                        : 'Order.View.Text.Translate'
                    }
                  />
                </div>
                <div className="section-text">{order.text}</div>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
  return null;
};

OrderRejectionHandler.propTypes = {
  order: PropTypes.object,
  doManualSearch: PropTypes.func.isRequired,
  doRequestRefund: PropTypes.func.isRequired,
};

OrderRejectionHandler.defaultProps = {
  order: undefined,
};

const actionsToProps = {
  doManualSearch: manualSearch,
  doRequestRefund: requestRefund,
};

export default connect(null, actionsToProps)(OrderRejectionHandler);
