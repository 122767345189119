import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import TextareaField from '../components/formfields/TextareaField';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

const Comment = ({ error, readonly, comment, index }) => {
  if (
    error === 'Order.Upload.Error.TextExtract' ||
    (error && error.includes('No text provided to get language'))
  ) {
    return <FormattedHTMLMessage id="File.Drop.Error.NoWord" />;
  }
  if (error) {
    return (
      <p>
        <FormattedMessage id={error} />
      </p>
    );
  }
  if (readonly) {
    return <p>{comment}</p>;
  }
  return (
    <Field
      name={`attachments[${index}].comment`}
      component={TextareaField}
      rows={2}
      placeholder="File.Comment.Prompt"
    />
  );
};

Comment.propTypes = {
  error: PropTypes.string,
  readonly: PropTypes.bool,
  comment: PropTypes.string,
  index: PropTypes.number,
};

Comment.defaultProps = {
  error: null,
  readonly: false,
  comment: '',
  index: 0,
};
export default Comment;
