import setFieldData from "final-form-set-field-data";
import PropTypes from "prop-types";
import React from "react";
import { Field, Form } from "react-final-form";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { removeFile, updateOrder } from "../actions";
import Attachment from "../attachments/Attachment";
import TextareaField from "../components/formfields/TextareaField";
import OrderPropType from "./../common/proptypes/OrderPropType";
import OrderFormSpy from "./OrderFormSpy";
import {
  calculatedValues,
  getIndexForAttachment,
  getReferenceUploads,
} from "./orderUtils";
import PricingSection from "./PricingSection";
import { getCurrentOrder } from "./selectors";
import Uploader from "./Uploader";
import Card from "../layout/Card";
import CustomTooltip from "../components/CustomTooltip";
import {
  getDiscount,
  getDiscountReason,
  getProfile,
} from "../account/selectors";
import { Link } from "react-router-dom";
import NewButton from "../components/NewButton";
import MessageBox from "../components/MessageBox";
import accounting from "accounting";

const ContextForm = ({
  order,
  doRemoveFile,
  history,
  doUpdateOrder,
  user,
  discountedPrice,
  price,
}) => {
  const { _id, text } = order;
  const attachments = getReferenceUploads(order);

  if (!order) return null;
  return (
    <Form
      initialValues={order}
      onSubmit={() => {
        if (!order.selectedLanguage && !order.proofreading) {
          order.selectedLanguage = order.detectedLanguage;
        }
        doUpdateOrder({ order, browserHistory: history });
      }}
      mutators={{ setFieldData }}
    >
      {({
        handleSubmit,
        submitting,
        values,
        form: { mutators },
        hasValidationErrors,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="sectionBox">
            <div className="orderTitle flex items-center">
              <FormattedMessage id="Order.Confirm.PriceAndDeadline" />
            </div>
            <div className="blue" style={{ marginBottom: 20 }}>
              <FormattedMessage id="Order.Opti" />
            </div>
            <div style={{ marginBottom: 20 }}>
              <PricingSection mutators={mutators} order={order} />
            </div>
            {order.averageRedundancy > 0 && (
              <MessageBox
                content={
                  <div>
                    <FormattedHTMLMessage id="Order.Reduction" />{" "}
                    {accounting.formatMoney((price - discountedPrice).toFixed(2), "", 2, ".", ",")}€
                  </div>
                }
                noMargin
                color="#5FB856"
              ></MessageBox>
            )}
          </div>

          {/* ------------------- Additional infos ---------------- */}
          <div className="sectionBox">
            <div className="orderTitle flex items-center">
              <FormattedMessage
                id={
                  order.proofreading
                    ? "Proofreading.Order.AdditionalInfo"
                    : "Order.AdditionalInfo"
                }
              />
              <CustomTooltip id="additional-info">
                <FormattedMessage
                  id={
                    order.proofreading
                      ? "Proofreading.Order.AdditionalInfo.Tooltip"
                      : "Order.AdditionalInfo.Tooltip"
                  }
                />
              </CustomTooltip>
            </div>
            <div className="blue" style={{ marginBottom: 20 }}>
              <FormattedMessage id="Order.Optional" />
            </div>
            <Field
              placeholder="Order.Instructions"
              component={TextareaField}
              name="additionalInfo"
            />
          </div>

          {/* ------------------- If not proofreading, Upload reference ---------------- */}
          {!text && !order.proofreading && (
            <div className="sectionBox">
              <div className="orderTitle flex items-center">
                <FormattedMessage id="Order.Drop.Context" />
                <CustomTooltip id="references-upload">
                  <FormattedMessage id="Order.Files.Upload.Reference.Tooltip" />
                </CustomTooltip>
              </div>
              <div className="blue" style={{ marginBottom: 20 }}>
                <FormattedHTMLMessage id="Order.Drop.Context.Description" />
              </div>
              {/* {attachments.map((u) => (
                <Attachment
                  key={`${u.name}${u.nonce}`}
                  attachment={u}
                  index={getIndexForAttachment(order, u)}
                  onRemove={() => doRemoveFile({ orderId: _id, file: u.path })}
                />
              ))} */}

              <Uploader
                attachments={attachments.filter(
                  (a) => a.name.split(".").at(-1) !== "tmx"
                )}
                order={order}
                doRemoveFile={doRemoveFile}
                getIndexForAttachment={getIndexForAttachment}
                type="reference"
              />
            </div>
          )}
          {/* ------------------- Submit ---------------- */}
          <div className="section-general flex text-center">
            {!user ? (
              <Link to="/login">
                <NewButton>
                  <FormattedMessage id="Login" />
                </NewButton>
              </Link>
            ) : (
              <button
                type="submit"
                className="button button--inline"
                disabled={submitting || hasValidationErrors}
              >
                <FormattedMessage id="Forms.Next" defaultMessage="Next" />
              </button>
            )}
          </div>
          <OrderFormSpy order={order} />
        </form>
      )}
    </Form>
  );
};

ContextForm.propTypes = {
  order: OrderPropType,
  doRemoveFile: PropTypes.func.isRequired,
  doUpdateOrder: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

ContextForm.defaultProps = {
  order: null,
};

const stateToProps = (state) => {
  const discount = getDiscount(state);
  const discountReason = getDiscountReason(state);

  const order = getCurrentOrder(state);
  return {
    order: getCurrentOrder(state),
    user: getProfile(state),
    ...calculatedValues(state, order, discount, discountReason),
  };
};
const dispatchToProps = {
  doRemoveFile: removeFile,
  doUpdateOrder: updateOrder,
};

export default connect(stateToProps, dispatchToProps)(ContextForm);
