import { triggerGoogleAnalyticsEvent } from './analyticsHelpers';

export const googleAnalyticsEventsMiddleware = () => (next) => (action) => {
  const { meta } = action;
  if (meta && meta.googleAnalyticsEvent) {
    const {
      category, action: analyticsAction, label, value, fieldsObject,
    } = meta.googleAnalyticsEvent;
    triggerGoogleAnalyticsEvent(category, analyticsAction, label, value, fieldsObject);
  }

  return next(action);
};

export const withGoogleAnalyticsEvent = (category, action, label = '', value = 0, fieldsObject = {}) => (wrappedPayload) => ({
  ...wrappedPayload,
  googleAnalyticsEvent: {
    category,
    action,
    label,
    value,
    fieldsObject,
  },
});
