import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import AccountPropType from "../common/proptypes/AccountPropType";

import {
  Container,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Alert,
  Button,
} from "reactstrap";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import { updateUser } from "../actions";
import Card from "../layout/Card";
import { getLang, getSettingsSpecialities, settings } from "../selectors";
import styles from "./AccountDetailsMyFavoriteTranslators.module.scss";
import { getProfile } from "./selectors";
import ActionLink from "../components/ActionLink";
import SelectField, {
  UncontrolledSelectField,
} from "../components/formfields/SelectField";
import app from "../feathers";

const ShowTranslator = ({
  translator,
  i,
  settingsSpecialities,
  userLang,
  profile,
  doUpdateUser,
}) => {
  const speciality = settingsSpecialities.find(
    (s) => s.key === translator?.specialityId
  )?.speciality[userLang];

  const favorite = !!profile?.myFavoriteTranslators[
    translator?.specialityId
  ]?.find((e) => e === translator.translatorId);

  const patchFavorite = async (specialityId, translatorId) => {
    const newFavorite = { ...profile.myFavoriteTranslators };

    if (newFavorite[specialityId]?.find((e) => e === translatorId)) {
      newFavorite[specialityId] = newFavorite[specialityId].filter(
        (e) => e != translatorId
      );
    } else {
      if (!newFavorite[specialityId])
        newFavorite[specialityId] = [];
      newFavorite[specialityId].push(translatorId);
    }
    //return;

    const user = { ...profile, myFavoriteTranslators: newFavorite };
    doUpdateUser({ user });

    // await app
    //   .service("users")
    //   .patch(profile._id, { myFavoriteTranslators: newFavorite });
  };

  return (
    <div className="basic-box flex items-center" key={i}>
      <div style={{ width: "30%" }}>
        <img
          className="mr-s"
          style={{ borderRadius: "50%" }}
          width={50}
          src={translator.picture}
          alt={translator.givenName}
        />
        <span className="blue bold">{translator.givenName}</span>
      </div>
      <div className="blue" style={{ width: "35%" }}>
        <FormattedMessage id="Profil.Speciality" /> : {speciality}
      </div>

      <div className="blue uppercase" style={{ width: "30%" }}>
        {translator.selectedLanguage}
        {" > "}
        {translator.destinationLanguage}
      </div>
      <div style={{ width: "5%" }}>
        <input
          className="pointer"
          onChange={() =>
            patchFavorite(translator.specialityId, translator.translatorId)
          }
          type="checkbox"
          checked={favorite}
        />
      </div>
    </div>
  );
};

const AccountDetailsMyFavoriteTranslators = ({
  profile,
  languagesList,
  doUpdateUser,
  settingsSpecialities,
  userLang,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [myFavoriteTranslators, setMyFavoriteTranslators] = useState(
    profile.myFavoriteTranslators || []
  );
  const [translatorToRemove, setTranslatorToRemove] = useState(undefined);
  const [givenName, setGivenName] = useState(undefined);

  // const getCountryName = (iso) => {
  //   return languagesList[profile.lang].find((lang) => lang.key === iso);
  // };

  const openModalForRemove = (translator) => {
    setTranslatorToRemove(translator);
    setGivenName(translator.givenName);
    openModal();
  };

  const openModal = () => {
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  // const test = {"1664543534406" : ["6335a2d9abf18f3e4ac32a54"]}

  // useEffect(() => {
  //   const patchTranslator = async () => {
  //     const { data } = await app
  //       .service("users")
  //       .patch(profile._id, { myFavoriteTranslators: test });
  //     console.log(data);
  //   };
  //   patchTranslator();
  // }, []);

  /**
   * Modal confirmation suppresion
   * */
  // const removeTranslator = () => {
  //   const fav = myFavoriteTranslators.filter(
  //     (obj) => obj !== translatorToRemove
  //   );
  //   setMyFavoriteTranslators(fav);
  //   closeModal();
  //   const user = { ...profile, myFavoriteTranslators: fav };
  //   doUpdateUser({ user });
  // };

  const getFormattedSpecialities = () => {
    return settingsSpecialities.map((spe) => ({
      value: spe.key,
      text: spe.speciality[userLang],
    }));
  };

  const [selectedSpe, setSelectedSpe] = useState(
    localStorage.getItem("categoryFilter") || settingsSpecialities[0].key
  );
  const [translators, setTranslators] = useState([]);

  console.log(profile);

  useEffect(() => {
    if (
      settingsSpecialities.find(
        (s) => s.key === localStorage.getItem("categoryFilter")
      )
    ) {
      localStorage.setItem("categoryFilter", selectedSpe);
    } else {
      localStorage.setItem("categoryFilter", settingsSpecialities[0].key);
    }
    const fetchUserCommands = async () => {
      const { data } = await app.service("orders").find({
        query: { status: "Order.Status.Complete", specialityId: selectedSpe },
      });
      const translators = data.map((o) => {
        return {
          translatorId: o.translator,
          ...o.translatorDetails,
          specialityId: o.specialityId,
          selectedLanguage: o.selectedLanguage,
          destinationLanguage: o.destinationLanguage,
        };
      });
      setTranslators(translators);
    };
    fetchUserCommands();
  }, [selectedSpe]);

  return (
    <>
      <Card
        fill
        faIcon={faHeart}
        title="Account.MyFavoriteTranslators"
        className="relative"
      >
        <div className="flex align-center" style={{ marginBottom: 20 }}>
          <span
            className="blue"
            style={{
              fontSize: 16,
              marginRight: 10,
            }}
          >
            <FormattedMessage id="Order.Speciality" />
          </span>
          <UncontrolledSelectField
            input={{
              onChange: (e) => setSelectedSpe(e.target.value),
              value: selectedSpe,
              style: { maxWidth: 250 },
            }}
            options={getFormattedSpecialities()}
          />
        </div>
        {translators.map(function (translator, i) {
          return (
            <ShowTranslator
              translator={translator}
              i={i}
              settingsSpecialities={settingsSpecialities}
              userLang={userLang}
              profile={profile}
              doUpdateUser={doUpdateUser}
            />
          );
        })}
        {myFavoriteTranslators.length === 0 && (
          <Row className="my-3">
            <Col sm="12" className="text-left">
              <p>
                <FormattedMessage id="Account.FavoriteTranslators.explanations" />
              </p>
            </Col>

            <Col sm="12" className="text-center">
              <Alert color="danger">
                <p className="font-weight-bold" style={{ marginBottom: 0 }}>
                  <FormattedMessage id="Account.NoFavoriteTranslator" />
                </p>
              </Alert>
            </Col>
          </Row>
        )}
        {/* <Modal isOpen={modalIsOpen} toggle={closeModal}>
          <ModalHeader toggle={closeModal}>
            <FormattedMessage id="ModalConfirm" />
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <p>
                  <FormattedMessage
                    id="Account.ConfirmRemoveFavorite"
                    values={{ givenName }}
                  />
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 4, offset: 1 }} xs="6" className="text-center">
                <Button className="button" onClick={() => closeModal()}>
                  <FormattedMessage id="No" />
                </Button>
              </Col>
              <Col md={{ size: 4, offset: 2 }} xs="6" className="text-center">
                <Button
                  className="button button--green"
                  onClick={() => removeTranslator()}
                >
                  <FormattedMessage id="Yes" />
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal> */}
      </Card>
    </>
  );
};
AccountDetailsMyFavoriteTranslators.propTypes = {
  profile: AccountPropType.isRequired,
  languagesList: PropTypes.object.isRequired,
  doUpdateUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  profile: getProfile(state),
  languagesList: settings(state).languagesList,
  settingsSpecialities: getSettingsSpecialities(state),
  userLang: getProfile(state) ? getProfile(state).lang : getLang(state),
});
const mapDispatchToProps = {
  doUpdateUser: updateUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsMyFavoriteTranslators);
