import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import style from "./Button.module.scss";

const Button = ({ onClick, disabled, buttonType, children }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={classnames({
      [style.Button]: true,
      [style[buttonType]]: true
    })}
  >
    {children}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  buttonType: PropTypes.oneOf(["CTA", "Primary", "Secondary"]),
  children: PropTypes.element.isRequired
};

Button.defaultProps = {
  disabled: false,
  buttonType: "Primary"
};

export default Button;
