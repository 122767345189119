import { hideChat, showChat, startLogin } from "../actions";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import ChatWindow from "../components/chat/ChatWindow";
import ChatWindowTranslator from "../components/chat/ChatWindowTranslator";
import Loading from "../components/Loading";
import UserHasCompletedProfile from "../components/UserHasCompletedProfile";
import UserIsAuthenticated from "../components/UserIsAuthenticated";
import ContactPage from "../contact/ContactPage";
import { getLang, getSettingsLoaded } from "../selectors";
import { triggerGoogleAnalyticsPageView } from "../utils/analyticsHelpers";
import AccountPage from "./AccountPage";
import ConfirmOrderPage from "./ConfirmOrderPage";
import EditOrderPage from "./EditOrderPage";
import HomePage from "./HomePage";
import Login from "./Login";
import MobilePaymentPage from "./MobilePaymentPage";
import NotificationsPage from "./NotificationsPage";
import PageNotFound from "./PageNotFound";
import SettingsPage from "./SettingsPage";
import ViewOrderPage from "./ViewOrderPage";
import { useLastLocation } from "react-router-last-location";
// translators includes
//TODO: insert login
import TranslationPageTranslator from "../pages/TranslationPageTranslator";
import ReviewPageTranslator from "../pages/ReviewPageTranslator";
import SettingsPageTranslator from "./SettingsPageTranslator";
import NotificationsPageTranslator from "../pages/NotificationsPageTranslator";
import HistoryPage from "./HistoryPageTranslator";
import InvoicesPageTranslator from "./InvoicesPageTranslator";
import EditInvoicePageTranslator from "./EditInvoicePageTranslator";
import UserIsTranslatorAuthenticated from "../components/UserIsTranslatorAuthenticated";
import { isTranslator } from "../account/selectors";
import { useHistory } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { StatusBar, Style } from "@capacitor/status-bar";
import ModalProvider from "../context/ModalContext";

const App = function ({ notifications, showChat, lang, ready, isTranslator }) {
  const lastLocation = useLastLocation();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    triggerGoogleAnalyticsPageView(location.pathname);
    window.scrollTo(0, 0);
    if (window.innerWidth < 576) {
      // Mobile breakpoint
      hideChat();
    }
  }, [location.pathname]);

  if (Capacitor.isNativePlatform()) {
    PushNotifications.addListener("pushNotificationActionPerformed", () => {
      history.push("/notifications");
    });

    StatusBar.setStyle({ style: Style.Light });
  }

  // if (!lastLocation || lastLocation.pathname !== location.pathname) {
  //   triggerGoogleAnalyticsPageView(location.pathname);
  //   window.scrollTo(0, 0);
  //   if (window.innerWidth < 576) {
  //     // Mobile breakpoint
  //     hideChat();
  //   }
  // }

  // const notifsModal = useMemo(() => {
  //   return notifs.filter(
  //     (n) => n.subject === "Notification.FavTransNotAvailable" && !n.clicked
  //   );
  // }, [notifs])

  return ready ? (
    <div>
      <Helmet htmlAttributes={{ lang }} />
      <Switch>
        <Route exact path="/settings" component={SettingsPage} />
        <Route
          path="/settings/account"
          component={UserIsAuthenticated(AccountPage)}
        />
        <Route exact path="/settings/contact" component={ContactPage} />
        <Route path="/order/:id" component={EditOrderPage} />
        <Route path="/proofreading/:id" component={EditOrderPage} />
        <Route
          exact
          path="/confirm/:id"
          component={UserHasCompletedProfile(ConfirmOrderPage)}
        />
        <Route
          exact
          path="/view/:id"
          component={UserIsAuthenticated(
            UserHasCompletedProfile(ViewOrderPage)
          )}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/mobilepayment" component={MobilePaymentPage} />

        <Route
          exact
          path="/notifications"
          component={UserIsAuthenticated(
            UserHasCompletedProfile(NotificationsPage)
          )}
        />
        <Route exact path="/" component={UserHasCompletedProfile(HomePage)} />

        {/* translators routes */}
        <Route
          exact
          path="/translate/:id"
          component={UserIsAuthenticated(TranslationPageTranslator)}
        />
        <Route
          exact
          path="/review/:id"
          component={UserIsAuthenticated(ReviewPageTranslator)}
        />
        <Route
          exact
          path="/translator/settings"
          component={UserIsTranslatorAuthenticated(SettingsPageTranslator)}
        />
        <Route
          exact
          path="/translator/notifications"
          component={UserIsAuthenticated(NotificationsPageTranslator)}
        />
        <Route
          exact
          path="/translator/history"
          component={UserIsAuthenticated(HistoryPage)}
        />
        <Route
          exact
          path="/translator/invoices"
          component={UserIsAuthenticated(InvoicesPageTranslator)}
        />
        <Route
          exact
          path="/invoices/:id"
          component={UserIsAuthenticated(EditInvoicePageTranslator)}
        />

        <Route component={PageNotFound} />
      </Switch>
      {isTranslator && (
        <ChatWindowTranslator orderId={showChat} admin={showChat === "ADMIN"} />
      )}
      {!isTranslator && (
        <ChatWindow orderId={showChat} admin={showChat === "ADMIN"} />
      )}
      <Notifications notifications={notifications} />
      <ModalProvider/>
    </div>
  ) : (
    <Loading />
  );
};

App.propTypes = {
  notifications: PropTypes.array,
  showChat: PropTypes.string,
  lang: PropTypes.string.isRequired,
  profile: PropTypes.object,
};

App.defaultProps = {
  notifications: [],
  showChat: null,
  isTranslator: false,
};

const stateToProps = (state) => ({
  notifications: state.notificationReducer,
  showChat: state.app.showChat,
  lang: getLang(state),
  ready: getSettingsLoaded(state),
  isTranslator: isTranslator(state),
});

const dispatchToProps = {
  doStartLogin: startLogin,
  doHideChat: hideChat,
  doShowChat: showChat,
};

export default connect(stateToProps, dispatchToProps)(App);
