import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import TextareaFieldTranslator from '../components/formfields/TextareaFieldTranslator';

const Comment = ({
  error, readonly, comment, index,
}) => {
  if (error) {
    return <p>{error}</p>;
  }
  if (readonly) {
    return <p>{comment}</p>;
  }
  return (
    <Field
      name={`attachments[${index}].comment`}
      component={TextareaFieldTranslator}
      rows={2}
      placeholder="File.Comment.TranslatorPrompt"
    />
  );
};

Comment.propTypes = {
  error: PropTypes.string,
  readonly: PropTypes.bool,
  comment: PropTypes.string,
  index: PropTypes.number,
};

Comment.defaultProps = {
  error: null,
  readonly: false,
  comment: '',
  index: 0,
};
export default Comment;
