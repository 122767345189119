import React from "react";

import styles from "./NewButton.module.scss";

const NewButton = ({ children, onClick = () => {}, inverted, type, disabled }) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={disabled ? styles.disabled : styles.button}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default NewButton;
