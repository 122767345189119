import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { getProfile } from '../account/selectors';
import { logout, showChat } from '../actions';
import ActionLink from '../components/ActionLink';
import Header from '../components/Header';
import AccountPropType from './../common/proptypes/AccountPropType';
import styles from './SettingsPage.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

const SettingsPage = ({ profile, doLogout, doShowChat }) => (
  <>
    <Row className="header-container">
      <Col className="content-container">
        <Header />
      </Col>
    </Row>

    <Container >
      <h1> youpipippip</h1>
      <div className="content-container body">
        <Row>
          <Col>
            {profile && (
              <div className="section-panel">
                <div className="section-panel-title">
                  <FontAwesomeIcon icon={faCog} />
                  <FormattedMessage id="Settings.General" />
                </div>
                <Link to="/settings/account" className="section-panel-link">
                  <FormattedMessage id="Settings.Account" />
                </Link>
              </div>
            )}

            <div className="section-panel">
              <div className="section-panel-title">
                <span className="icon-info"></span>
                <FormattedMessage id="Settings.Help" />
              </div>
              {profile ? (
                <ActionLink onClick={doShowChat} className={styles.Link}>
                  <FormattedMessage id="Settings.Contact" />
                </ActionLink>
              ) : (
                  <Link to="/settings/contact" className="section-panel-link">
                    <FormattedMessage id="Settings.Contact" />
                  </Link>
                )}
            </div>


            <div className="section-general section-general--no-border section-general--grey">
              <p className="version-info">MyBrian Client v[VERSION_STRING]</p>
            </div>

            {profile ? (
              <button type="button" onClick={doLogout} className="button">
                <FormattedMessage id="Logout" />
              </button>
            ) : (
                <Link to="/login" className="button">
                  <FormattedMessage id="Login" />
                </Link>
              )}
          </Col>
        </Row>
      </div>
    </Container>
  </>
);

SettingsPage.propTypes = {
  doLogout: PropTypes.func.isRequired,
  doShowChat: PropTypes.func.isRequired,
  profile: AccountPropType,
};

SettingsPage.defaultProps = {
  profile: undefined,
};

const stateToProps = (state) => ({
  profile: getProfile(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  doLogout: () => dispatch(logout({ browserHistory: ownProps.history })),
  doShowChat: () => dispatch(showChat('ADMIN')),
});

export default withRouter(connect(stateToProps, dispatchToProps)(SettingsPage));
