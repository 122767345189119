import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import cookie from '../cookie';
const UserIsTranslatorAuthenticated = connectedRouterRedirect({
  authenticatedSelector: (state) => (!!state.auth.user && state.auth.user.role === 'translator'),
  redirectPath: '/',
  authenticatingSelector: (state) => state.auth.isLoading || !!cookie.getItem('feathers-jwt'),
  wrapperDisplayName: 'UserIsTranslatorAuthenticated', // a nice name for this auth check
});

export default UserIsTranslatorAuthenticated;
