import setFieldData from "final-form-set-field-data";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { connect } from "react-redux";

import { removeFile, saveOrderProgress, stepUpdateOrder } from "../actions";
import Attachment from "../attachments/Attachment";
import LanguagePicker from "../components/formfields/LanguagePicker";
import SpecificityPicker from "../components/formfields/SpecificityPicker";
import TextareaField from "../components/formfields/TextareaField";
import Loading from "../components/Loading";
import nToBr from "../utils/nToBr";
import OrderPropType from "./../common/proptypes/OrderPropType";
import OrderFormSpy from "./OrderFormSpy";
import {
  countWordsInOrder,
  getClientUploads,
  getIndexForAttachment,
  getReferenceUploads,
} from "./orderUtils";
import { getCurrentOrder } from "./selectors";
import { getLang, getSettingsSpecialities } from "../selectors";
import Uploader from "./Uploader";
import { getProfile } from "../account/selectors";
import { OnChange } from "react-final-form-listeners";
import { Row, Col, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faSpinner,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import MessageBox from "../components/MessageBox";
import SelectField from "../components/formfields/SelectField";
import NewButton from "../components/NewButton";
import { Link } from "react-router-dom";
import CustomTooltip from "../components/CustomTooltip";
import app from "../feathers";

const required = (value) => (value ? undefined : "Order.Text.Error");

const OrderForm = ({
  order,
  doRemoveFile,
  history,
  location,
  doStepUpdateOrder,
  settingsSpecialities,
  userLang,
  user,
}) => {
  const { _id, text, destinationLanguage, selectedLanguage, proofreading } =
    order;
  const words = countWordsInOrder(order);
  const attachments = getClientUploads(order);
  const references = getReferenceUploads(order);
  const errors = attachments.find((a) => a.error);
  const fileLanguage = attachments?.[0]?.lang || "";
  let fileLanguageOrigin = undefined;
  const [selectedLng, setSelectedLng] = useState(selectedLanguage);
  const [destinationLng, setDestinationLng] = useState(destinationLanguage);
  const [showSpecMessage, setShowSpecMessage] = useState(true);
  const [loader, setLoader] = useState(false);
  const redundancy =
    attachments?.reduce((prev, attach) => attach.redundancy + prev, 0) /
    (attachments?.length || 1);

  let errorMessage = null;
  if (!!errors && errors.words === null)
    errorMessage = <FormattedHTMLMessage id="File.Drop.Error.NoWord" />;
  if (location.pathname === "/proofreading/new") order.proofreading = true;
  // Si traduction : selectedLanguage=langue du fichier uploade (attachments)
  if (!order.proofreading) {
    if (!order.selectedLanguage && !!fileLanguage) {
      order.selectedLanguage = fileLanguage;
      setSelectedLng(fileLanguage);
    }
  } else {
    // Si relecture : destinationLanguage=langue du fichier reference (references)
    // fileLanguageOrigin = langue du fichier origine (avant trad)
    fileLanguageOrigin = references.map((u) => u.lang).toString();
    if (!order.destinationLanguage && !!fileLanguage) {
      order.destinationLanguage = fileLanguage;
      setDestinationLng(fileLanguage);
    }
    if (!order.selectedLanguage && !!fileLanguageOrigin) {
      order.selectedLanguage = fileLanguageOrigin;
      setSelectedLng(fileLanguageOrigin);
    }
  }

  const getFormattedSpecialities = () => {
    return settingsSpecialities.map((spe) => ({
      value: spe.key,
      text: spe.speciality[userLang],
    }));
  };

  const speciality = settingsSpecialities.find(
    (s) => s.key === order.specialityId
  )?.speciality[userLang];

  const waitForRedundancy = async () => {
    setLoader(true);
    let res = {};
    try {
      res = await app.service("redundancy").create({
        orderId: order._id,
        selectedLanguage: order.selectedLanguage,
        destinationLanguage: order.destinationLanguage,
        specialityId: order.specialityId,
      });
    } catch (e) {
      console.log(e);
    }
    return res;
    setLoader(false);
  };

  if (!order) return <Loading />;
  return (
    <Form
      initialValues={order}
      onSubmit={async () => {
        const { averageRedundancy, redundantWords, basePrice } =
          await waitForRedundancy();
        doStepUpdateOrder({
          order: { ...order, averageRedundancy, redundantWords, basePrice },
          browserHistory: history,
        });
        setLoader(false);
      }}
      mutators={{ setFieldData }}
    >
      {({
        handleSubmit,
        submitting,
        form: { mutators },
        hasValidationErrors,
        values,
        modified,
      }) => {
        // console.log(
        //   hasValidationErrors,
        //   words,
        //   submitting,
        //   errors,
        //   !destinationLng || (!proofreading && !selectedLng),
        //   destinationLng,
        //   !selectedLng,
        //   proofreading
        // );
        setSelectedLng(values.selectedLanguage);
        setDestinationLng(values.destinationLanguage);
        return (
          <form onSubmit={handleSubmit}>
            {/* {!text && ( */}
            <>
              {/* ------------------- upload files to translate ---------------- */}

              <div
                className="sectionBox flex"
                style={{
                  minHeight: "200px",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div className="share-flex">
                  <div className="orderTitle flex items-center">
                    <FormattedMessage
                      id={
                        order.proofreading
                          ? "Proofreading.Files.Upload.ToTranslate"
                          : "Order.Files.Upload.ToTranslate"
                      }
                    />
                    {/* <CustomTooltip id="docs">
                      <div style={{ color: "white" }}>
                        <FormattedMessage id="Order.Files.Upload.Tooltip">
                          {nToBr}
                        </FormattedMessage>
                      </div>
                    </CustomTooltip> */}
                  </div>
                  <div className="blue" style={{ marginBottom: 20 }}>
                    <FormattedMessage id="Order.Drop.Description" />
                  </div>
                  {/* <div
                    className="d-flex flex-row"
                    style={{
                      padding: "9px 20px",
                    }}
                  >
                    {attachments.map((u) => (
                      <Attachment
                        key={`${u.name}${u.nonce}`}
                        attachment={u}
                        index={getIndexForAttachment(order, u)}
                        onRemove={() =>
                          doRemoveFile({ orderId: _id, file: u.path })
                        }
                      />
                    ))}
                  </div> */}

                  <Uploader
                    attachments={attachments}
                    //wordsNumber={wordsNumber}
                    getIndexForAttachment={getIndexForAttachment}
                    doRemoveFile={doRemoveFile}
                    order={order}
                    type="client"
                  />

                  {/* )} */}
                  {/* ------------------- text to translate ---------------- */}
                  {!attachments.length && (
                    <div className="section-textarea">
                      <div className="section-textarea__words">
                        <FormattedMessage
                          id="Order.Words"
                          defaultMessage={
                            "{words, number} {words, plural, one {word} other {words}}"
                          }
                          values={{ words }}
                        />
                      </div>
                      <div className="section-lang">
                        <Field
                          name="text"
                          placeholder={
                            order.proofreading
                              ? "Proofreading.Text"
                              : "Order.Text"
                          }
                          validate={required}
                          component={TextareaField}
                          rows={5}
                          style={{ borderRadius: 10 }}
                          className="textarea"
                        />
                      </div>
                    </div>
                  )}
                </div>
                {redundancy > 0 && (
                  <div style={{ maxWidth: 400 }}>
                    <MessageBox
                      badgeIcon={
                        <FontAwesomeIcon
                          color="white"
                          icon={faTag}
                          fontSize="15px"
                        />
                      }
                      color="#5FB856"
                      title={<FormattedMessage id="Widget.Red.Title" />}
                      content={
                        !user ? (
                          <FormattedMessage id="Widget.Red.Body" />
                        ) : (
                          <FormattedMessage id="Widget.Red.Body.Login" />
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </>

            {/* ------------------- Languages (for translating and proofreading) ---------------- */}
            <div className="sectionBox">
              <div
                className="orderTitle flex items-center"
                style={{ marginBottom: 20 }}
              >
                <FormattedMessage id="Order.Langage.Title" />
                <div style={{ marginLeft: 15 }}>
                  <FontAwesomeIcon icon={faInfoCircle} fontSize="20px" />
                </div>
                {/* <FormattedMessage
                  id={
                    order.proofreading
                      ? "Proofreading.Read.Translated"
                      : "Language.Choose"
                  }
                /> */}
              </div>
              <div style={{ marginBottom: 10 }}>
                <LanguagePicker
                  selectedLanguage={values.selectedLanguage}
                  destinationLanguage={values.destinationLanguage}
                  fileDetectedLanguage={fileLanguage}
                  fileDetectedLanguageOrigin={fileLanguageOrigin}
                  order={order}
                />
              </div>
              <div className="flex align-center" style={{ marginBottom: 10 }}>
                <span
                  className="blue"
                  style={{
                    fontSize: 16,
                    marginRight: 10,
                  }}
                >
                  <FormattedMessage id="Order.Speciality" />
                </span>
                <div onChange={() => setShowSpecMessage(false)}>
                  <Field
                    component={SelectField}
                    name="specialityId"
                    //label="Speciality.Choice"
                    options={getFormattedSpecialities()}
                    //validate={showPicker && required}
                  />
                </div>
                {/* <Field component={SelectField} name="test" options={[]} /> */}
              </div>
              {order.specialityId && showSpecMessage && (
                <span
                  className="blue"
                  style={{
                    fontStyle: "italic",
                    fontSize: 16,
                  }}
                >
                  <FormattedMessage id="Order.Speciality.Start" /> {speciality}.{" "}
                  <FormattedMessage id="Order.Speciality.End" />
                </span>
              )}
            </div>
            {order.proofreading && (
              <div className="sectionBox">
                <div className="orderTitle flex items-center">
                  <FormattedMessage id="Proofreading.Files.Upload.Reference" />
                  <CustomTooltip id="proofreading-upload">
                    <FormattedMessage id="Proofreading.Files.Upload.Reference.Tooltip" />
                  </CustomTooltip>
                </div>

                {/* {references.map((u) => (
                  <Attachment
                    key={`${u.name}${u.nonce}`}
                    attachment={u}
                    index={getIndexForAttachment(order, u)}
                    onRemove={() =>
                      doRemoveFile({ orderId: _id, file: u.path })
                    }
                  />
                ))} */}

                <Uploader
                  order={order}
                  doRemoveFile={doRemoveFile}
                  getIndexForAttachment={getIndexForAttachment}
                  attachments={references}
                  type="reference"
                />
              </div>
            )}

            {/* ------------------- Specificities ---------------- */}
            {/* <div className="sectionBox">
              <div className="orderTitle">
                <FormattedMessage id="Order.Specificity" />
              </div>
              <OnChange name="order.specificity">
                {order.specificity === "general" && (order.specialityId = "")}
              </OnChange>
              <SpecificityPicker
                mutators={mutators}
                settingsSpecialities={settingsSpecialities}
                userLang={userLang}
              />
            </div> */}

            {/* ------------------- TMX ---------------- */}

            {!order.proofreading && user && (
              <div className="sectionBox">
                <div className="orderTitle flex items-center">
                  <FormattedMessage id="Order.TMX.Title" />
                  <CustomTooltip id="TMX">
                    <FormattedMessage id="Order.TMX.Tooltip" />
                  </CustomTooltip>
                </div>
                <div className="blue" style={{ marginBottom: 20 }}>
                  <FormattedMessage id="Order.TMX.Description" />
                </div>
                <Uploader
                  order={order}
                  doRemoveFile={doRemoveFile}
                  getIndexForAttachment={getIndexForAttachment}
                  attachments={references.filter(
                    (a) => a.name.split(".").at(-1) === "tmx"
                  )}
                  type="reference"
                  tmx={true}
                />
              </div>
            )}

            {/* ------------------- Submit ---------------- */}
            <div className="section-general text-center flex">
              {!user ? (
                <Link to="/login">
                  <NewButton>
                    <FormattedMessage id="Login" />
                  </NewButton>
                </Link>
              ) : !loader ? (
                <button
                  type="submit"
                  className="button button--inline"
                  disabled={
                    hasValidationErrors ||
                    !words ||
                    submitting ||
                    errors ||
                    !destinationLng ||
                    (!proofreading && !selectedLng)
                  }
                >
                  <FormattedMessage id="Order.Next" defaultMessage="Next" />
                </button>
              ) : (
                <div className="flex-center w-full">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    title={"Loading"}
                    className={"fa-spin"}
                  />
                </div>
              )}
            </div>
            <OrderFormSpy order={order} />
          </form>
        );
      }}
    </Form>
  );
};

OrderForm.propTypes = {
  order: OrderPropType,
  doRemoveFile: PropTypes.func.isRequired,
  doStepUpdateOrder: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  settingsSpecialities: PropTypes.array.isRequired,
  userLang: PropTypes.string.isRequired,
};

OrderForm.defaultProps = {
  order: null,
};

const stateToProps = (state) => {
  return {
    order: getCurrentOrder(state),
    settingsSpecialities: getSettingsSpecialities(state),
    userLang: getProfile(state) ? getProfile(state).lang : getLang(state),
    user: getProfile(state),
  };
};

const dispatchToProps = {
  doRemoveFile: removeFile,
  doSaveOrderProgress: saveOrderProgress,
  doStepUpdateOrder: stepUpdateOrder,
};

export default connect(stateToProps, dispatchToProps)(OrderForm);
