import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { timezone } from '../constants';
import OrderPropType from './../common/proptypes/OrderPropType';
import { getClientUploads } from './orderUtils';

const OrderSummary = ({ order, history }) => {
  if (order) {
    const deadline = moment.tz(order.deadline, timezone);

    return (
      <div className="order-summary" onClick={() => history.push(`/view/${order._id}`)}>
        <div className="order-summary__key">
          <div className="order-summary__reference">{order.orderId}</div>
          <div className="order-summary__deadline">
            <FormattedMessage id="Order.Due" />
            {' '}
            {deadline.calendar()}
          </div>
        </div>
        <div className="order-summary__preview notranslate">
          {order.text ? `${order.text.substr(0, 150)}...` : getClientUploads(order).map((a) => a.name).join(', ')}

        </div>
      </div>
    );
  }
  return null;
};

OrderSummary.propTypes = {
  order: OrderPropType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(OrderSummary);
