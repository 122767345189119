import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink, Route, Switch, withRouter } from 'react-router-dom';
import Toggle from 'react-toggle';
import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap';

import { getProfile, isTranslator } from '../account/selectors';
import { setAvailability, showChat, logout } from '../actions';
import { getUnreadNotificationCount } from '../notifications/selectors';
import ActionLink from './ActionLink';

const Header = ({
  notifications,
  isLoggedIn,
  doShowChat,
  isTranslator,
  profile,
  doSetAvailability,
  doLogout,
  location,
}) => {
  const rootPath = [
    '/',
    '/Settings',
    '/notifications',
    '/translator/settings',
    '/translator/notifications',
  ];
  const leftPart = () => {
    return (
      <Switch>
        <Route exact path={rootPath.map((route) => route)}>
          {!isTranslator && (
            <div>
              <NavLink
                to={{ pathname: 'https://mybrian.fr' }}
                target="_blank"
                className="Website"
              >
                <FormattedMessage id="Header.MyBrian.title" />
              </NavLink>
            </div>
          )}
          {isTranslator && (
            <NavLink to="/">
              <span className="Website"></span>
            </NavLink>
          )}
        </Route>

        <Route>
          {({ history }) => (
            <ActionLink onClick={history.goBack} className="Back">
              <FormattedMessage id="Header.Back.title" />
            </ActionLink>
          )}
        </Route>
      </Switch>
    );
  };

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <header>
      <Navbar
        color="faded"
        light
        expand={isTranslator ? 'lg' : 'md'}
        className="align-items-end"
      >
        <div className="Brand">{leftPart()}</div>

        {/* ----------------- NOT CONNECTED MENU ------------------------ */}
        {!isLoggedIn && (
          <NavItem className="IconLink ml-auto">
            <NavLink
              to="/settings/contact"
              className="IconLink flex-md-row-reverse mb-1 mt-1"
              activeClassName="active"
            >
              <span className="d-none d-md-block">
                <FormattedMessage id="Header.Assistance" />
              </span>
              <span className="Icon Assistance"></span>
            </NavLink>
          </NavItem>
        )}
        {isLoggedIn && (
          <>
            {/* ----------------- TRANSLATOR AVAILABILITY ------------------------ */}
            {isTranslator && (
              <NavItem className="availability grey ml-4">
                <label className="availability__label">
                  <div className="grey">
                    <FormattedMessage id="Translator.Available.Short" />
                  </div>
                  {profile && (
                    <Toggle
                      className="availability__toggle"
                      onChange={doSetAvailability}
                      checked={profile.available}
                    />
                  )}
                  {/*   <div className="d-none d-sm-inline-block">
                              <FormattedMessage id="Translator.Available" />
                            </div> 
                      */}
                </label>
              </NavItem>
            )}

            <NavbarToggler
              onClick={toggleNavbar}
              navbar="true"
              style={{ alignSelf: 'center' }}
            />
            <Collapse isOpen={!collapsed} navbar>
              {/* ----------------- CLIENT MENU ------------------------ */}
              {isLoggedIn && !isTranslator && (
                <Nav className="mr-auto" navbar>
                  <NavItem className="grey">
                    <NavLink
                      exact
                      to="/"
                      activeClassName="active"
                      className="flex-md-row-reverse IconLink mb-1 mt-1 "
                    >
                      <span className="menuTxt">
                        <FormattedMessage id="Header.Dashboard" />
                      </span>
                      <span className="Icon Dashboard"></span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="grey">
                    <NavLink
                      to="/settings/account"
                      activeClassName="active"
                      className="IconLink flex-md-row-reverse mb-1 mt-1"
                    >
                      <FormattedMessage id="Header.Settings" />
                      <span className="Icon Settings"></span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="grey">
                    <NavLink
                      data-badge={notifications}
                      to="/notifications"
                      className={classnames({
                        Badge: !!notifications,
                        IconLink: true,
                        Notifications: true,
                        'flex-md-row-reverse mb-1 mt-1': true,
                      })}
                      activeClassName="active"
                    >
                      <FormattedMessage id="Header.Notifications" />
                      <span className="Icon Notifications"></span>
                    </NavLink>
                  </NavItem>
                </Nav>
              )}
              {/* ----------------- TRANSLATOR MENU ------------------------ */}
              {isLoggedIn && isTranslator && (
                <>
                  <Nav className="mr-auto" navbar>
                    <NavItem className="grey">
                      <NavLink
                        exact
                        to="/"
                        activeClassName="active"
                        className="flex-lg-row-reverse IconLink mb-1 mt-1 "
                      >
                        <span className="menuTxt">
                          <FormattedMessage id="Header.Dashboard" />
                        </span>
                        <span className="Icon Dashboard"></span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="grey">
                      <NavLink
                        to="/translator/settings"
                        activeClassName="active"
                        className="IconLink flex-lg-row-reverse mb-1 mt-1"
                      >
                        <FormattedMessage id="Header.Settings" />
                        <span className="Icon Settings"></span>
                      </NavLink>
                    </NavItem>

                    <NavItem className="grey">
                      <NavLink
                        to="/translator/notifications"
                        activeClassName="active"
                        data-badge={notifications}
                        className={classnames({
                          Badge: !!notifications,
                          IconLink: true,
                          Notifications: true,
                          'flex-lg-row-reverse mb-1 mt-1': true,
                        })}
                      >
                        <FormattedMessage id="Header.Notifications" />
                        <span className="Icon Notifications"></span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </>
              )}
              <hr className="d-md-none text-white-50"></hr>
              <NavItem className="IconLink">
                <ActionLink
                  onClick={doShowChat}
                  className={
                    isTranslator
                      ? 'IconLink flex-lg-row-reverse mb-1 mt-1'
                      : 'IconLink flex-md-row-reverse mb-1 mt-1'
                  }
                >
                  <>
                    <span>
                      <FormattedMessage id="Header.Assistance" />
                    </span>
                    <span className="Icon Assistance"></span>
                  </>
                </ActionLink>
              </NavItem>
              <NavItem className="IconLink">
                <ActionLink
                  onClick={doLogout}
                  className="IconLink flex-lg-row-reverse mb-1 mt-1"
                >
                  <>
                    <span
                      className={
                        isTranslator
                          ? 'd-inline d-lg-none'
                          : 'd-inline d-md-none'
                      }
                    >
                      <FormattedMessage id="Header.Disconnect" />
                    </span>
                    <span className="Icon Logout"></span>
                  </>
                </ActionLink>
              </NavItem>
            </Collapse>
          </>
        )}
      </Navbar>
    </header>
  );
};

Header.defaultProps = {
  profile: undefined,
  notifications: 0,
  left: null,
  right: null,
  center: null,
  chat: null,
};

Header.propTypes = {
  profile: PropTypes.object,
  notifications: PropTypes.number,
  isLoggedIn: PropTypes.bool.isRequired,
  isTranslator: PropTypes.bool,
  doShowChat: PropTypes.func.isRequired,
  doSetAvailability: PropTypes.func.isRequired,
  left: PropTypes.any,
  right: PropTypes.any,
  center: PropTypes.any,
  chat: PropTypes.bool,
  doLogout: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  profile: getProfile(state),
  notifications: getUnreadNotificationCount(state),
  isLoggedIn: !!getProfile(state),
  isTranslator: isTranslator(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  doLogout: () => {
    dispatch(logout({ browserHistory: ownProps.history }));
  },
  doShowChat: () => dispatch(showChat('ADMIN')),
  doSetAvailability: (e) => {
    dispatch(
      setAvailability({
        available: e.target.checked,
        browserHistory: ownProps.history,
      })
    );
  },
});

export default withRouter(connect(stateToProps, dispatchToProps)(Header));
