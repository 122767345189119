import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import TextField from './formfields/TextField';

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha';
import { useIntl } from 'react-intl';
const NbCharForCaptcha = 6;

const SimpleCaptcha = ({ isCaptchaValidated }) => {
  useEffect(() => {
    loadCaptchaEnginge(NbCharForCaptcha, '#fff', '#828282', 'lower');
  }, []);
  const intl = useIntl();

  const checkCaptcha = (user_captcha) => {
    if (
      !user_captcha ||
      (user_captcha && user_captcha.length !== NbCharForCaptcha)
    ) {
      isCaptchaValidated(false);
    } else {
      if (validateCaptcha(user_captcha) === true) {
        isCaptchaValidated(true);
      } else {
        isCaptchaValidated(false);
      }
    }
  };
  return (
    <>
      <Row>
        <Col xs={6} className="my-2">
          <LoadCanvasTemplate
            reloadText={intl.formatMessage({
              id: `SendQuote.reloadCaptcha`,
            })}
            reloadColor="#ff5f6b"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={TextField}
            name="user_captcha_input"
            id="user_captcha_input"
            placeholder="SendQuote.Captcha.Placeholder"
          />
          <OnChange name="user_captcha_input">
            {(value, previous) => {
              checkCaptcha(value);
            }}
          </OnChange>
        </Col>
      </Row>
    </>
  );
};

export default SimpleCaptcha;
