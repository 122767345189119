import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { removeOrder } from "../actions";
import ActionLink from "../components/ActionLink";
import { isComplete, isInProgress, orderStatus } from "./orderUtils";

import styles from "./OrderStatus.module.scss";
import OrderPropType from "./../common/proptypes/OrderPropType";
import { FormGroup } from "reactstrap";

const confirmRemove = ({ order, doRemoveOrder, intl }) => {
  if (order && window.confirm(intl.formatMessage({ id: "Confirm.Remove" }))) {
    doRemoveOrder(order._id);
  }
};

const OrderStatus = ({ order, doRemoveOrder }) => {
  const intl = useIntl();
  let orderStatusHTML = [];
  const status = orderStatus(order, "client");
  if (isInProgress(order)) {
    orderStatusHTML = (
      <div className={styles.Container}>
        <img
          src={order.translatorDetails.picture}
          className={styles.Avatar}
          alt={order.translatorDetails.givenName}
        />
        <span>{status.human}</span>
      </div>
    );
  } else if (!isComplete(order)) {
    orderStatusHTML = (
      <div className={styles.AwaitingContainer}>
        <span>{status.human}</span>
        {status.code === "Order.Status.Unpaid" && (
          <ActionLink
            className={styles.DeleteButton}
            onClick={() => confirmRemove({ order, doRemoveOrder, intl })}
          >
            <span className="icon-bin" />
          </ActionLink>
        )}
      </div>
    );
  }
  return orderStatusHTML;
};

OrderStatus.propTypes = {
  order: OrderPropType.isRequired,
  doRemoveOrder: PropTypes.func.isRequired,
};

export default connect(null, { doRemoveOrder: removeOrder })(OrderStatus);
