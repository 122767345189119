import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getProfile } from "../account/selectors";
import {
  changeOrder,
  removeFile,
  showChat,
  updateOrder,
  upload,
  uploadComplete,
  uploadError,
  uploadProgress,
} from "../actions";
import Attachment from "../attachments-translator/Attachment";
import AttachmentPairs from "../attachments-translator/AttachmentPairs";
import Error from "../components-translator/Error";
import SectionOptions from "../components-translator/SectionOptions";
import TextareaFieldTranslator from "../components/formfields/TextareaFieldTranslator";
import Languages from "../components/Languages";
import Card from "../layout/Card";
import SplitPaneTranslator from "../layout/SplitPaneTranslator";
import StarRating from "../components/StarRating";
import { getCurrentOrder } from "../orders/selectors";
import AccountPropType from "./../common/proptypes/AccountPropType";
import OrderPropType from "./../common/proptypes/OrderPropType";
import {
  Row,
  Col,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import cookie from "../cookie";
import request from "superagent";
import {
  getClientUploads,
  getReferenceUploads,
  getTranslatorUploads,
  getUploadsForFile,
  getTranslatorIntermediateUploads,
  getClientIntermediateUploads,
  getIndexForAttachment,
  isDefinitlyCompleted,
} from "./../orders/orderUtils";

import styles from "./TranslationForm.module.scss";
import UploadField from "../components/formfields/UploadField";
import CheckboxField from "../components/formfields/CheckboxField";

const handleFormChanges = (event, order, doChangeOrder, doUpdateOrder) => {
  if (!isEqual(order, event.values)) {
    doChangeOrder(event.values);
  }
};

const getError = (order) => {
  if (order.status === "Order.Status.Cancelled") {
    return "Translator.Order.Error.Cancelled";
  }
  if (getClientUploads(order).length === 0 && !order.translation) {
    return "Translator.Order.Error.EnterTranslation";
  }
  if (getTranslatorUploads(order).some((u) => u.error)) {
    return "File.Error";
  }
  return null;
};

const TranslationForm = ({
  order,
  account,
  onSubmit,
  doChangeOrder,
  doUpdateOrder,
  doShowChat,
  doRemoveFile,
  notifyUpload,
  notifyUploadProgress,
  notifyUploadComplete,
  notifyUploadError,
}) => {
  const [displayConfirmDiag, setDisplayConfirmDiag] = useState(false);

  if (!order) return null;

  if (order.translator !== account._id) {
    return (
      <Error
        name="Error.Error"
        message="Error.Translator.Order.SomeoneElseAccepted"
      />
    );
  }

  const type = "translator-intermediate";
  const orderIsDefinitlyCompleted = isDefinitlyCompleted(order);

  const clientUploads = getClientUploads(order);
  const translatorIntermediateUploads = getTranslatorIntermediateUploads(order);
  const clientIntermediateUploads = getClientIntermediateUploads(order);
  const referenceUploads = getReferenceUploads(order);
  const translatorUploads = getTranslatorUploads(order);
  const alertCount = referenceUploads.length + (order.additionalInfo ? 1 : 0);
  const error = getError(order);
  const givenName = order.userDetails.givenName;
  const orderId = order.orderId;

  const uploadFiles = (acceptedFiles, tmx = undefined) => {
    acceptedFiles.forEach((file) => {
      const nonce = [...Array(10)]
        .map(() => (~~(Math.random() * 36)).toString(36))
        .join("");
      const { name } = file;
      console.log(type, tmx, account.role);
      notifyUpload({
        name,
        percent: 0,
        error: null,
        words: null,
        path: null,
        uploading: true,
        nonce,
        authorId: account._id,
        type: type !== undefined ? (tmx ? "translator" : type) : account.role,
        translationFor: acceptedFiles.path,
      });
      request
        .post(`${process.env.REACT_APP_API_URL}/uploads`)
        .set("Authorization", cookie.getItem("feathers-jwt"))
        .query({
          $client: {
            orderId: order._id,
            type: tmx ? "translator" : type,
            translationFor: acceptedFiles.path,
            tmx,
          },
        })
        .attach("uri", file, name)
        .on("progress", (event) =>
          notifyUploadProgress({ name, percent: event.percent, nonce })
        )
        .then(
          ({ body }) =>
            notifyUploadComplete({
              name,
              words: body.words,
              path: body.id,
              nonce,
            }),
          (error) =>
            notifyUploadError({
              name,
              error: error.response.body.message,
              nonce,
            })
        );
    });
  };
  let submit;
  return (
    <Form initialValues={order} onSubmit={onSubmit}>
      {({ handleSubmit, values }) => {
        submit = handleSubmit;
        return (
          <form onSubmit={handleSubmit}>
            <div className="Title">
              <FormattedMessage
                id="TranslationPage.Title"
                values={{ givenName, orderId }}
              />
            </div>

            {/* ---------------------------- Informations générales --------------------------------*/}

            <Card
              fill
              title={
                order.proofreading
                  ? "TranslationPage.GeneralInfoProofreading"
                  : "TranslationPage.GeneralInfoTranslation"
              }
              rightTitle={
                alertCount && <span className={styles.Alert}>{alertCount}</span>
              }
            >
              <SectionOptions deadline={order.deadline} price={order.pay} />
              <br />
              <Languages order={order} />
            </Card>

            {order.additionalInfo && (
              <Card title="Translator.Order.AdditionalInfo" fill>
                {order.additionalInfo}
              </Card>
            )}
            {!!referenceUploads.length && (
              <Card title="Order.View.Reference" fill>
                {referenceUploads
                  .filter((a) => a.name.split(".").at(-1) !== "tmx")
                  .map((a) => (
                    <Attachment
                      key={a.path}
                      readonly
                      link
                      attachment={a}
                      showRemove={false && !orderIsDefinitlyCompleted}
                    />
                  ))}
              </Card>
            )}
            {/* ---------------------------- Fichier(s) à traduire --------------------------------*/}
            <Card
              fill
              title={
                clientUploads.length
                  ? order.proofreading
                    ? "Order.View.File.Proofreading"
                    : "Order.View.Files"
                  : order.proofreading
                  ? "Order.View.Text.Proofreading"
                  : "Order.View.Text.Translate"
              }
            >
              <div className="w-full flex justify-between flex-wrap">
                {!!clientUploads.length && (
                  <>
                    <div>
                      {clientUploads.map((sourceAttachment) => (
                        <Attachment
                          key={sourceAttachment.path}
                          attachment={sourceAttachment}
                          index={getIndexForAttachment(order, sourceAttachment)}
                          link
                          readonly
                          showRemove={false}
                        />
                      ))}
                    </div>
                    <div
                      style={{
                        padding: 20,
                        borderRadius: 15,
                        backgroundColor: "white",
                        height: "fit-content",
                      }}
                    >
                      <div
                        style={{ width: 250 }}
                        className="blue flex space-between"
                      >
                        <span>
                          <FormattedMessage id="Order.Words.Unique" /> :
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {order.words - order.redundantWords}{" "}
                          <FormattedMessage id="Order.Word" />
                        </span>
                      </div>
                      <div
                        style={{ width: 250 }}
                        className="blue flex space-between"
                      >
                        <span>
                          <FormattedMessage id="Order.Words.Repeated" /> :
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {order.redundantWords}{" "}
                          <FormattedMessage id="Order.Word" />
                        </span>
                      </div>
                      <div
                        style={{ width: 250, marginTop: 20 }}
                        className="blue flex space-between"
                      >
                        <span>
                          <FormattedMessage id="Order.Words.Total" /> :
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {order.words} <FormattedMessage id="Order.Word" />
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Card>
            {/* ---------------------------- TMX --------------------------------*/}
            {!order?.proofreading ? (
              <Card fill title="Translator.TMX.Title">
                <Row>
                  <Col xs="6" style={{ paddingLeft: "26px" }}>
                    <div>
                      <p>
                        <FormattedMessage id="Translator.TMX.Client" />
                      </p>
                      {referenceUploads.filter(
                        (a) => a.name.split(".").at(-1) === "tmx"
                      )?.length > 0 ? (
                        referenceUploads
                          .filter((a) => a.name.split(".").at(-1) === "tmx")
                          ?.map((sourceAttachment) => (
                            <div
                              key={sourceAttachment.path}
                              style={{ marginBottom: 10 }}
                            >
                              <Attachment
                                key={sourceAttachment.path}
                                attachment={sourceAttachment}
                                index={getIndexForAttachment(
                                  order,
                                  sourceAttachment
                                )}
                                link
                                readonly={true}
                                showRemove={false}
                              />
                            </div>
                          ))
                      ) : (
                        <p className="section-text no-border alert text-danger">
                          <FormattedMessage id="TranslationPage.NoFile" />
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col xs="6" style={{ paddingLeft: "26px" }}>
                    <div>
                      <p>
                        <FormattedMessage id="Translator.TMX.PostTrad" />
                      </p>
                      {translatorUploads.filter(
                        (a) => a?.name?.split?.(".")?.at?.(-1) === "tmx"
                      )?.length > 0 ? (
                        translatorUploads
                          .filter((a) => a?.name?.split?.(".")?.at?.(-1) === "tmx")
                          ?.map((sourceAttachment) => (
                            <div
                              key={sourceAttachment.path}
                              style={{ marginBottom: 10 }}
                            >
                              <Attachment
                                key={sourceAttachment.path}
                                attachment={sourceAttachment}
                                index={getIndexForAttachment(
                                  order,
                                  sourceAttachment
                                )}
                                link
                                readonly={true}
                                showRemove={false}
                              />
                            </div>
                          ))
                      ) : (
                        <p className="section-text no-border alert text-danger">
                          <FormattedMessage id="TranslationPage.NoFile" />
                        </p>
                      )}
                      <UploadField
                        accept={".tmx"}
                        className={styles.Upload}
                        handleDrop={(files) => uploadFiles(files, true)}
                        uploadMessage={"File.Drop"}
                        type={"translator"}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            ) : null}
            {/* ---------------------------- Fichiers  intermédiaires --------------------------------*/}
            <Card fill title="TranslationPage.IntermediateFiles">
              {translatorIntermediateUploads &&
                translatorIntermediateUploads.length > 0 && (
                  <Row>
                    <p
                      className="alert text-danger"
                      style={{ paddingLeft: "26px" }}
                    >
                      <FormattedMessage id="TranslationPage.IntermediateFiles.Info" />
                    </p>
                  </Row>
                )}
              <Row>
                <Col xs="6" style={{ paddingLeft: "26px" }}>
                  <div>
                    <p>
                      <FormattedMessage id="TranslationPage.FilesSentByClient" />
                    </p>
                    {!clientIntermediateUploads ||
                      (clientIntermediateUploads.length === 0 && (
                        <p className="section-text no-border alert text-danger">
                          <FormattedMessage id="TranslationPage.NoFile" />
                        </p>
                      ))}
                    {clientIntermediateUploads &&
                      clientIntermediateUploads.length > 0 &&
                      clientIntermediateUploads.map((sourceAttachment) => (
                        <Attachment
                          key={sourceAttachment.path}
                          attachment={sourceAttachment}
                          index={getIndexForAttachment(order, sourceAttachment)}
                          link
                          readonly={true}
                          showRemove={false}
                        />
                      ))}
                  </div>
                </Col>
                <Col xs="6" style={{ paddingLeft: "26px" }}>
                  <div>
                    <p>
                      <FormattedMessage id="TranslationPage.FilesSentByYou" />
                    </p>
                    {!translatorIntermediateUploads ||
                      (translatorIntermediateUploads.length === 0 && (
                        <p className="section-text no-border text-center alert text-danger">
                          <FormattedMessage id="TranslationPage.NoFile" />
                        </p>
                      ))}
                    {translatorIntermediateUploads &&
                      translatorIntermediateUploads.length > 0 &&
                      translatorIntermediateUploads.map((sourceAttachment) => (
                        <Attachment
                          key={sourceAttachment.path}
                          attachment={sourceAttachment}
                          index={getIndexForAttachment(order, sourceAttachment)}
                          readonly={true}
                          showRemove={!orderIsDefinitlyCompleted}
                          onRemove={() =>
                            doRemoveFile({
                              orderId: order._id,
                              file: sourceAttachment.path,
                            })
                          }
                        />
                      ))}
                  </div>
                </Col>
                <Col>
                  <UploadField
                    className={styles.Upload}
                    handleDrop={(files) => uploadFiles(files, false)}
                    uploadMessage={"File.Drop"}
                  />
                </Col>
              </Row>
            </Card>
            {/* --------------  Appréciations traduction et retour client    ------------*/}
            {order.translatorRating && (
              <Card title="Feedback" className={styles.Feedback} fill>
                <SplitPaneTranslator
                  left={
                    <Card fill title="Order.YourRating">
                      <dl>
                        <dt>
                          <FormattedMessage id="Order.Rating.Title.Content" />
                        </dt>
                        <dd>
                          <StarRating stars={order.translatorRating.Content} />
                        </dd>
                        <dt>
                          <FormattedMessage id="Order.Rating.Title.Money" />
                        </dt>
                        <dd>
                          <StarRating stars={order.translatorRating.Money} />
                        </dd>
                        <dt>
                          <FormattedMessage id="Order.Rating.Title.Deadline" />
                        </dt>
                        <dd>
                          <StarRating stars={order.translatorRating.Deadline} />
                        </dd>
                      </dl>
                    </Card>
                  }
                  right={
                    <>
                      <Card fill title="Order.ClientFeedback">
                        {order.clientReview && <p> {order.clientReview}</p>}
                        {order.clientFeedback ? (
                          <>
                            <StarRating stars={order.clientStars} />
                            <dt>
                              <FormattedMessage
                                id={
                                  order.clientStars < 5
                                    ? "Feedback.Bad.Question"
                                    : "Feedback.Good.Question"
                                }
                              />
                            </dt>
                            <dd>
                              <ul>
                                {order.clientFeedback.map((item) => (
                                  <li key={item}>
                                    <FormattedMessage id={item} />
                                  </li>
                                ))}
                              </ul>
                              {order.clientFeedbackOther}
                            </dd>
                          </>
                        ) : (
                          <FormattedMessage id="Feedback.None" />
                        )}
                      </Card>
                      {order.setToFavoriteTranslator === true && (
                        <div className="alert alert-success">
                          <span>
                            <FormattedMessage
                              id="Order.ClientFeedback.FavoriteTranslator"
                              values={{ givenName }}
                            />
                          </span>
                        </div>
                      )}
                    </>
                  }
                />
              </Card>
            )}

            {/* --------------  Dialoguez avec le client    ------------*/}
            <Card fill centre title="TranslationPage.Chat.Title">
              <div className={styles.ButtonBar}>
                <button
                  type="button"
                  onClick={() => doShowChat(order._id)}
                  className="button button--inline"
                >
                  <FormattedMessage id="TranslationPage.Chat" />
                </button>
              </div>
            </Card>

            {/* ---------------------------- Fichier final à uploader --------------------------------*/}
            <Card
              fill
              title={
                clientUploads.length
                  ? order.proofreading
                    ? "TranslationPage.FinalProofreading"
                    : "TranslationPage.FinalFile"
                  : order.proofreading
                  ? "TranslationPage.FinalTextProofreading"
                  : "TranslationPage.FinalText"
              }
            >
              <Row>
                <Col>
                  {clientUploads.length > 0 ? (
                    <AttachmentPairs
                      key={clientUploads[0].path}
                      order={order}
                      editable
                      sourceAttachment={clientUploads[0]}
                      destinationAttachments={getUploadsForFile(
                        order,
                        clientUploads[0]
                      )}
                    />
                  ) : null}
                  {!clientUploads.length ? (
                    <Row>
                      <Col>{order.text} </Col>
                      <Col>
                        <Field
                          name="translation"
                          component={TextareaFieldTranslator}
                          rows={10}
                          placeholder="Translator.Order.EnterTranslation"
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
              {!order.text ? (
                <Row>
                  <Col>
                    <div className="feedback-modal__details">
                      <div className="feedback-modal__details-inner">
                        <>
                          <FormGroup>
                            <Field
                              component={CheckboxField}
                              name="isLayoutRespected"
                              type="checkbox"
                              customLabel={
                                <FormattedMessage id="Translator.Order.Layout.CheckboxCommitment" />
                              }
                            />
                          </FormGroup>
                        </>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Card>

            {/* --------------  Valider la traduction après upload    ------------*/}
            <div className="section-general text-center">
              <Button
                className="button--green"
                disabled={
                  !!error ||
                  (!order.text && !values.isLayoutRespected) ||
                  (clientUploads?.length > 0 &&
                    getUploadsForFile(order, clientUploads?.[0]).length === 0)
                }
                onClick={() => setDisplayConfirmDiag(true)}
              >
                <FormattedMessage
                  id={
                    order.proofreading
                      ? "TranslationPage.NextProofreading"
                      : "TranslationPage.Next"
                  }
                  defaultMessage="Next"
                />
              </Button>
              <Modal
                isOpen={displayConfirmDiag}
                toggle={() => setDisplayConfirmDiag(false)}
              >
                <ModalHeader toggle={() => setDisplayConfirmDiag(false)}>
                  Attention
                </ModalHeader>
                <ModalBody>
                  <FormattedMessage id="TranslationPage.ConfirmDiag.Description" />
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="button--green"
                    onClick={() => {
                      setDisplayConfirmDiag(false);
                      submit();
                    }}
                  >
                    <FormattedMessage id="TranslationPage.ConfirmDiag.Yes" />
                  </Button>{" "}
                  <Button onClick={() => setDisplayConfirmDiag(false)}>
                    <FormattedMessage id="TranslationPage.ConfirmDiag.No" />
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
            <FormSpy
              subscription={{ values: true }}
              onChange={(event) =>
                handleFormChanges(event, order, doChangeOrder, doUpdateOrder)
              }
            />
          </form>
        );
      }}
    </Form>
  );
};

TranslationForm.propTypes = {
  order: OrderPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  doChangeOrder: PropTypes.func.isRequired,
  doShowChat: PropTypes.func.isRequired,
  doRemoveFile: PropTypes.func.isRequired,
  account: AccountPropType.isRequired,
};

const stateToProps = (state) => ({
  order: getCurrentOrder(state),
  account: getProfile(state),
});

const actionsToProps = {
  doChangeOrder: changeOrder,
  doUpdateOrder: updateOrder,
  doShowChat: showChat,
  doRemoveFile: removeFile,
  notifyUpload: upload,
  notifyUploadProgress: uploadProgress,
  notifyUploadError: uploadError,
  notifyUploadComplete: uploadComplete,
};
export default connect(stateToProps, actionsToProps)(TranslationForm);
