import { get, head } from "lodash";

let settingsCache;

const notEmpty = (obj) =>
  obj && !(Object.keys(obj).length === 0 && obj.constructor === Object);

export const getCompany = (state) =>
  head(get(state, "companies.store.records", []));

export const getInProgressForm = (state) =>
  get(state, "form.OrderFormWizard.values", {});

export const getRedirect = (state) =>
  get(state, "routing.locationBeforeTransitions.query.redirect", null);
export const getLang = (state) => state.intl.locale;

export const getDayStart = (state) => {
  const company = getCompany(state);

  return company && notEmpty(company.dayStart)
    ? company.dayStart
    : settings(state).dayStart;
};

export const getDayEnd = (state) => {
  const company = getCompany(state);

  return company && notEmpty(company.dayEnd)
    ? company.dayEnd
    : settings(state).dayEnd;
};

export const getWeekend = (state) => {
  const company = getCompany(state);
  return get(company, "weekendsAvailable", false);
};

export const getSettingsLoaded = (state) =>
  !state.settings.isLoading && state.settings.queryResult.length;

export const settings = (state) => {
  if (settingsCache) {
    return settingsCache;
  }
  const serverSettings = get(state, "settings.queryResult", false);
  if (serverSettings && serverSettings.length) {
    settingsCache = serverSettings.reduce((a, b) => {
      a[b.key] = b.value;
      return a;
    }, {});
    return settingsCache;
  }
};

export const getClientInvoices = (state) =>
  get(state, "invoices.store.records", []);

//export const getSettingsSpecialities = (state) => settings(state).specialities;
export const getSettingsSpecialities = (state) =>
  settings(state).specialities.filter((s) => s.status !== "REMOVED")?.sort((a, b) => a.speciality.fr.localeCompare(b.speciality.fr));
