import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import style from "./Error.module.scss";

const Error = ({ name, message }) => (
  <div className={style.Container}>
    <h1>
      <FormattedMessage id={name} />
    </h1>
    <p>
      <FormattedMessage id={message} />
    </p>
    <p>
      <Link to="/">
        <FormattedMessage id="Home" />
      </Link>
    </p>
  </div>
);

Error.propTypes = {
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Error;
