import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const MaybeLink = ({
  to,
  link,
  activeClassName,
  inactiveClassName,
  children
}) => {
  if (link) {
    return (
      <Link to={to} className={activeClassName}>
        {children}
      </Link>
    );
  }

  return <div className={inactiveClassName}>{children}</div>;
};

MaybeLink.propTypes = {
  to: PropTypes.string.isRequired,
  link: PropTypes.bool.isRequired,
  activeClassName: PropTypes.string,
  inactiveClassName: PropTypes.string,
  children: PropTypes.element.isRequired
};

MaybeLink.defaultProps = {
  activeClassName: null,
  inactiveClassName: null
};
export default MaybeLink;
