import { createAction } from 'redux-actions';

// Common to client and translator
export const ACCEPT_ORDER = 'mybrian/orders/accept';
export const AUTH_FULFILLED = 'SERVICES_AUTHENTICATION_AUTHENTICATE_FULFILLED';
export const AUTH_REJECTED = 'SERVICES_AUTHENTICATION_AUTHENTICATE_REJECTED';
export const AUTHENTICATION_LOGOUT = 'SERVICES_AUTHENTICATION_LOGOUT';
export const CHANGE_FILE_COMMENT = 'mybrian/orders/file/comment';
export const changeFileComment = createAction(CHANGE_FILE_COMMENT);
export const CHANGE_ORDER = 'mybrian/orders/change';
export const changeOrder = createAction(CHANGE_ORDER);
export const DEBUG = 'mybrian/app/debug';
export const HIDE_CHAT = 'mybrian/chat/hide';
export const hideChat = createAction(HIDE_CHAT);
export const LOAD_SETTINGS = 'mybrian/settings/load';
export const loadSettings = createAction(LOAD_SETTINGS);
export const LOGOUT = 'mybrian/app/logout';
export const logout = createAction(LOGOUT);
export const MARK_NOTIFICATIONS_READ = 'mybrian/notifications/mark-read';
export const markNotificationsRead = createAction(MARK_NOTIFICATIONS_READ);
export const MARK_NOTIFICATION_CLICKED = 'mybrian/notification/mark-clicked';
export const markNotificationClicked = createAction(MARK_NOTIFICATION_CLICKED);
export const NOTIFICATIONS_CREATE_RECEIVED =
  'SERVICES_NOTIFICATIONS_CREATE_RECEIVED';
export const createNotification = createAction(NOTIFICATIONS_CREATE_RECEIVED);
export const NOTIFY_VIEWING_CHAT = 'mybrian/chat/notify';
export const notifyViewingChat = createAction(NOTIFY_VIEWING_CHAT);
export const ORDERS_LOAD_FULFILLED = 'SERVICES_ORDERS_LOAD_FULFILLED';
export const fulfillLoadOrder = createAction(ORDERS_LOAD_FULFILLED);
export const PATCH_ORDERS_FULFILLED = 'SERVICES_ORDERS_PATCH_FULFILLED';
export const REJECT_ORDER = 'mybrian/orders/reject';
export const rejectOrder = createAction(REJECT_ORDER);
export const REMOVE_FILE = 'mybrian/orders/file/remove';
export const removeFile = createAction(REMOVE_FILE);
export const REVIEW_ORDER = 'mybrian/order/review';
export const reviewOrder = createAction(REVIEW_ORDER);
export const SAVE_ORDER_PROGRESS = 'mybrian/orders/save-progress';
export const saveOrderProgress = createAction(SAVE_ORDER_PROGRESS);
export const SET_TRANSLATOR_AVAILABLE = 'mybrian/translator/available';
export const setAvailability = createAction(SET_TRANSLATOR_AVAILABLE);
export const SHOW_CHAT = 'mybrian/chat/show';
export const showChat = createAction(SHOW_CHAT);
export const START_LOGIN = 'mybrian/app/login';
export const startLogin = createAction(START_LOGIN);
export const UPDATE_INVOICE_FAILED = 'SERVICES_INVOICES_UPDATE_REJECTED';
export const UPDATE_INVOICE_FULFILLED = 'SERVICES_INVOICES_UPDATE_FULFILLED';
export const UPDATE_ORDER = 'mybrian/orders/update';
export const updateOrder = createAction(UPDATE_ORDER);
export const UPDATE_ORDER_FAILED = 'SERVICES_ORDERS_UPDATE_REJECTED';
export const UPDATE_ORDER_FULFILLED = 'SERVICES_ORDERS_UPDATE_FULFILLED';
export const UPDATE_USER = 'mybrian/user/update';
export const UPDATE_USER_FULFILLED = 'SERVICES_USERS_UPDATE_FULFILLED';
export const UPDATE_USER_FAILED = 'SERVICES_USERS_UPDATE_REJECTED';
export const updateUser = createAction(UPDATE_USER);
export const LOAD_ORDER = 'mybrian/order/load';
export const loadOrder = createAction(LOAD_ORDER);

export const UPLOAD = 'mybrian/orders/file/upload';
export const upload = createAction(UPLOAD);
export const UPLOAD_PROGRESS = 'mybrian/orders/file/upload/progress';
export const uploadProgress = createAction(UPLOAD_PROGRESS);
export const UPLOAD_ERROR = 'mybrian/orders/file/upload/error';
export const uploadError = createAction(UPLOAD_ERROR);
export const UPLOAD_COMPLETE = 'mybrian/orders/file/upload/complete';
export const uploadComplete = createAction(UPLOAD_COMPLETE);
export const USERS_PATCH_FULFILLED = 'SERVICES_USERS_PATCH_FULFILLED';
export const GET_INVOICE = 'mybrian/invoice/get';
export const getInvoice = createAction(GET_INVOICE);
export const UPDATE_INVOICE = 'mybrian/invoice/update';
export const updateInvoice = createAction(UPDATE_INVOICE);

// Exclusive client
export const AGREE_TO_TERMS = 'mybrian/account/agree-to-terms';
export const agreeToTerms = createAction(AGREE_TO_TERMS);
export const AUTH_STARTED = 'mybrian/app/auth-started';
export const AUTO_SAVE_COMPLETED = 'mybrian/auto-save-completed';
export const completeAutoSave = createAction(AUTO_SAVE_COMPLETED);
export const AUTO_SAVE_START = 'mybrian/auto-save-start';
export const startAutoSave = createAction(AUTO_SAVE_START);
export const CANCEL_ORDER = 'mybrian/orders/cancel';
export const CHANGE_ORDER_INFO = 'mybrian/orders/change-info';
export const changeAdditionalInfo = createAction(CHANGE_ORDER_INFO);
export const CHANGE_ORDER_LANGUAGE = 'mybrian/orders/change-language';
export const changeLanguage = createAction(CHANGE_ORDER_LANGUAGE);
export const CHANGE_ORDER_SPECIFICITY = 'mybrian/orders/change-specificity';
export const changeSpecificity = createAction(CHANGE_ORDER_SPECIFICITY);
export const CHANGE_ORDER_TEXT = 'mybrian/orders/change-text';
export const changeOrderText = createAction(CHANGE_ORDER_TEXT);
export const CHANGE_ORDER_TIME = 'mybrian/orders/change-time';
export const changeOrderTime = createAction(CHANGE_ORDER_TIME);
export const CLEAR_ERROR = 'mybrian/orders/clear-errors';
export const clearError = createAction(CLEAR_ERROR);
export const CLEAR_ORDER = 'mybrian/order/clear';
export const clearOrder = createAction(CLEAR_ORDER);
export const COMPLETE_ORDER = 'mybrian/orders/complete';
export const completeOrder = createAction(COMPLETE_ORDER);
export const CONFIRM_ORDER = 'mybrian/orders/confirm';
export const confirmOrder = createAction(CONFIRM_ORDER);

export const CREATE_ORDER = 'mybrian/orders/create';
export const createOrder = createAction(CREATE_ORDER);
export const CREATE_ORDER_FAILED = 'SERVICES_ORDERS_CREATE_REJECTED';
export const CREATE_ORDER_FULFILLED = 'SERVICES_ORDERS_CREATE_FULFILLED';
export const EDIT_ORDER = 'mybrian/order/edit';
export const editOrder = createAction(EDIT_ORDER);
export const GET_USER = 'mybrian/user/get';
export const getUser = createAction(GET_USER);
export const GO_HOME = 'mybrian/nav/gohome';
export const goHome = createAction(GO_HOME);
export const LOAD_LOCAL_ORDER = 'mybrian/orders/local/load';
export const loadLocalOrder = createAction(LOAD_LOCAL_ORDER);
export const LOAD_LOCAL_ORDER_FULFILLED = 'mybrian/orders/local/load/fulfilled';
export const LOAD_ORDERS = 'mybrian/orders/load-all';
export const loadOrders = createAction(LOAD_ORDERS);
export const LOGIN = 'mybrian/app/login';
export const login = createAction(LOGIN);

export const ORDERS_PATCH_RECEIVED = 'SERVICES_ORDERS_PATCH_RECEIVED';
export const ORDERS_REMOVE_FULFILLED = 'SERVICES_ORDERS_REMOVE_FULFILLED';
export const PATCH_ORDER_FAILED = 'SERVICES_ORDERS_PATCH_REJECTED';
export const PATCH_ORDER_FULFILLED = 'SERVICES_ORDERS_PATCH_FULFILLED';
export const PATCH_USER_RECEIVED = 'SERVICES_USERS_PATCH_RECEIVED';
export const RATE_ORDER = 'mybrian/order/rate';
export const rateOrder = createAction(RATE_ORDER);
export const REMOVE_LOCAL_ORDER_FULFILLED =
  'mybrian/orders/local/remove/fulfilled';
export const REMOVE_ORDER = 'mybrian/orders/remove';
export const removeOrder = createAction(REMOVE_ORDER);
export const REMOVE_ORDER_FULFILLED = 'SERVICES_ORDERS_REMOVE_FULFILLED';
export const REQUEST_MANUAL_SEARCH = 'mybrian/order/request-manual-search';
export const manualSearch = createAction(REQUEST_MANUAL_SEARCH);
export const REQUEST_REFUND = 'mybrian/order/refund';
export const requestRefund = createAction(REQUEST_REFUND);
export const SERVER_CONNECTION_OFFLINE = 'mybrian/server/offline';
export const showOffline = createAction(SERVER_CONNECTION_OFFLINE);
export const SERVER_CONNECTION_ONLINE = 'mybrian/server/online';
export const showOnline = createAction(SERVER_CONNECTION_ONLINE);
export const SET_SERVER_ERROR = 'mybrian/server/error';
export const setServerError = createAction(SET_SERVER_ERROR);
export const SHOW_CREDIT_CARD_FORM = 'mybrian/orders/show-credit-card-form';
export const showCreditCardForm = createAction(SHOW_CREDIT_CARD_FORM);
export const STEP_UPDATE_ORDER = 'mybrian/orders/step-update';
export const stepUpdateOrder = createAction(STEP_UPDATE_ORDER);
export const UPDATE_LAST_ROLE = 'mybrian/users/update-last-role';
export const updateLastRole = createAction(UPDATE_LAST_ROLE);
export const UPDATE_LOCALE = 'mybrian/user/update-locale';
export const updateLocale = createAction(UPDATE_LOCALE);
export const UPDATE_USER_RECEIVED = 'SERVICES_USERS_UPDATE_RECEIVED';
export const VIEW_ORDER = 'mybrian/order/view';
export const viewOrder = createAction(VIEW_ORDER);
export const SETTINGS_LOADED = 'SERVICES_SETTINGS_FIND_FULFILLED';
// exclusive translator
export const ACCEPT_START_ORDER = 'mybrian/order/accept-start';
export const acceptThenStartOrder = createAction(ACCEPT_START_ORDER);
export const CLEAR_REVIEW_ORDER = 'mybrian/orders/review/clear';
export const clearReviewOrder = createAction(CLEAR_REVIEW_ORDER);
export const REJECT_ORDER_REDIRECT = 'mybrian/orders/reject-redirect';
export const rejectOrderAndRedirect = createAction(REJECT_ORDER_REDIRECT);
export const UPDATE_USER_LANGUAGES = 'mybrian/user/updateLanguage';
export const updateUserLanguages = createAction(UPDATE_USER_LANGUAGES);
export const LOAD_INVOICES = 'mybrian/invoices/load';
export const loadInvoices = createAction(LOAD_INVOICES);
