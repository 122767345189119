import PropTypes from 'prop-types';

export default PropTypes.shape({
  _id: PropTypes.string.isRequired,
  orderId: PropTypes.string,
  time: PropTypes.number,
  text: PropTypes.string,
  status: PropTypes.string,
  createdAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  user: PropTypes.string,
  translator: PropTypes.string,
  translatorDetails: PropTypes.shape({
    givenName: PropTypes.string,
    picture: PropTypes.string,
    online: PropTypes.bool,
    createdAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
  }),
  words: PropTypes.number,
  price: PropTypes.number,
  priceWithTax: PropTypes.number,
  discountedPrice: PropTypes.number,
  discountedPriceWithTax: PropTypes.number,
  discountReason: PropTypes.string,
  deadline: PropTypes.string,
  additionalInfo: PropTypes.string,
  specificity: PropTypes.oneOf(['general', 'specialist']),
  specialityId: PropTypes.string,
  paymentReference: PropTypes.string,
  confirmedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  translation: PropTypes.string,
  translatedUpload: PropTypes.string,
  upload: PropTypes.string,
  paymentToken: PropTypes.string,
  companyId: PropTypes.string,
  suspendedReason: PropTypes.string,
  suspendedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  suspendedAction: PropTypes.string,
  clientFeedback: PropTypes.arrayOf(PropTypes.string),
  clientFeedbackOther: PropTypes.string,
  clientStars: PropTypes.number,
  setToFavoriteTranslator: PropTypes.bool,
  completedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  selectedLanguage: PropTypes.string,
  destinationLanguage: PropTypes.string,
  stripe: PropTypes.shape({
    token: PropTypes.string,
  }),
  payBy: PropTypes.string,
  paymentProcessedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      path: PropTypes.string,
      authorId: PropTypes.string,
      type: PropTypes.oneOf([
        'client',
        'translator',
        'reference',
        'additional',
        'client-intermediate',
        'translator-intermediate',
      ]).isRequired,
      version: PropTypes.number,
      words: PropTypes.number,
      comment: PropTypes.string,
    })
  ),
  quoteEmail: PropTypes.string,
  quoteLang: PropTypes.string,
  statusQuote: PropTypes.string,
  proofreading: PropTypes.bool,
});
