import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './ActionLink.module.scss';

const ActionLink = ({ onClick, className, children }) => (
  <button type="button" onClick={onClick} className={classnames({ [style.ActionLink]: true, [className]: !!className })}>
    {children}
  </button>
);

ActionLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.element,
};

ActionLink.defaultProps = {
  className: null,
};

export default ActionLink;
