import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getOrders } from '../orders/selectors';
import { FormattedMessage } from 'react-intl';
import { isComplete } from '../orders/orderUtils';
import { Link } from 'react-router-dom';
import TranslateLink from '../components/TranslateLink';
import Header from '../components/Header';
import { Container, Row, Col } from 'reactstrap';
import { getProfile } from '../account/selectors';

const stateToProps = (state) => ({
  acceptedOrders: getOrders(state),
  profile: getProfile(state),
});

class HistoryPageTranslator extends Component {
  render() {
    const { acceptedOrders, profile } = this.props;
    const ordersHTML = this._completeOrdersHTML(acceptedOrders, profile);
    return (
      <>
        <Row className="header-container">
          <Col className="content-container">
            <Header
              left={<Link to="settings/" className="icon-arrow-left pink" />}
              center={<Link to="/" className="header__icon icon-my-brian" />}
            />
          </Col>
        </Row>

        <Container >
          <div className="content-container body">
            <Row>
              <Col>
                <div className="Card">
                  <div className="Title">
                    <FormattedMessage id="HistoryPage.Title" />
                  </div>

                  {ordersHTML}
                </div>
              </Col>
            </Row>
           
          </div>
        </Container>
      </>
    );
  }

  // generate HTML list of accepted translations
  _completeOrdersHTML = (orders, profile) => {
    if (!orders) return [];

    let html = [];

    // filter orders to keep complete ones only and sort
    orders = orders
      .filter((order) => isComplete(order))
      .filter((order) => order.translator === profile._id);

    if (!orders.length) {
      html = (
        <div className="section-general section-general--grey">
          <FormattedMessage id="HistoryPage.NoHistory" />
        </div>
      );
    } else {
      html = orders.map((order) => {
        return <TranslateLink key={order._id} order={order} />;
      });
    }

    return html;
  };
}

HistoryPageTranslator.propTypes = {
  acceptedOrders: PropTypes.array.isRequired,
  review: PropTypes.func,
  reject: PropTypes.func,
  accept: PropTypes.func,
  acceptThenStart: PropTypes.func,
};

export default connect(stateToProps)(HistoryPageTranslator);
