import { get, find } from "lodash";
import { getCompany } from "../selectors";

export const getProfile = (state) => {
  const _id = getUser(state);
  if (_id && get(state, "users.store.connected", false)) {
    return find(get(state, "users.store.records", []), { _id });
  }
  return get(state, "auth.user", null);
};
export const getUser = (state) => get(state, "auth.user._id", null);
export const isLoggingIn = (state) => state.auth.isLoading;

export const isProfileComplete = (state) => {
  const profile = getProfile(state);

  return profile.givenName && profile.familyName && profile.email;
};

export const isTranslator = (state) => {
  const role = get(state, "auth.user.role", null);
  if (role && role === "translator") {
    return true;
  } else {
    return false;
  }
};
export const getDiscountReason = (state) => {
  const company = getCompany(state);
  if (company) {
    return company.discountReason;
  }
  const user = getUser(state);

  return user ? user.discountReason : null;
};

export const getDiscount = (state) => {
  const company = getCompany(state);
  if (company) {
    return company.discount;
  }
  const user = getUser(state);

  return user ? user.discount : 0;
};
