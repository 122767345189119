import { findIndex, get } from "lodash";
import moment from "moment-timezone";
import React from "react";
import { FormattedMessage } from "react-intl";

import { getProfile } from "../account/selectors";
import { canBeExcludedFromTax, eu, tax, timezone } from "../constants";
import { getPrice, wordCount } from "./pricing";
import {
  generateMarks,
  getEarliestTime,
  mustStartTime,
  timeFromIncrements,
} from "./times";
import {settings} from "../selectors";

export function countWordsInOrder(order) {
  if (order) {
    const { text } = order;
    const attachments = getAllUploads(order).filter((a) => a.type === "client");
    if (attachments && attachments.length) {
      return attachments
        .map((o) => o.words)
        .reduce((prev, curr) => prev + curr, 0);
    }
    return wordCount(text);
  }
  return 0;
}

const calculBasePriceWidget = (order, settingsApp) => {
  try {
    const specialities = JSON.parse(localStorage.getItem("specialities"));
    const lngCouples = JSON.parse(localStorage.getItem("lngCouples"))
    const couple = `${order.selectedLanguage}-${order.destinationLanguage}`;

    const basePrice = settingsApp[order.proofreading ? "proofreadingBasePrice" : "basePrice"];

    const speciality = specialities.find(
        (spec) => spec.key === order.specialityId
    );
    const lng = lngCouples.find((spec) => spec.couple === couple);
    const specialityFactor = speciality ? speciality.factor : 1;
    const languageCoupleFactor = lng ? lng.factor : 1;

    console.log("newPrice")
    return basePrice * specialityFactor * languageCoupleFactor;
  } catch (e) {
    return 0.125;
  }
};

export function calculatedValues(state, order, discount = 0, discountReason) {
  let {
    time,
    proofreading,
    basePrice = 0.125,
    averageRedundancy,
    redundantWords,
  } = order;
  const words = order.wordsNumber
    ? +order.wordsNumber
    : countWordsInOrder(order);
  console.log("ORDER CHANGE")
  if (order._id === "new") basePrice = calculBasePriceWidget(order, settings(state));
  const { marks, max } = generateMarks(state, words, null, proofreading);
  const start = getEarliestTime(state, words, null, proofreading);
  const deadline = timeFromIncrements(state, time, start, proofreading);
  const mst = mustStartTime(state, deadline, words, null, proofreading);
  const prices = getPrice(
    state,
    mst,
    words,
    basePrice,
    time,
    max,
    proofreading,
    averageRedundancy,
    redundantWords
  );
  console.log("PRICES:", prices);
  const price = prices[1];

  let taxRate = tax;

  let discountedPrice = averageRedundancy > 0 ? prices[0] : prices[1];
  discountedPrice =
    discountReason === "Order.DiscountReason.FirstOrderDiscount"
      ? discountedPrice
      : discountedPrice - discountedPrice * discount;

  const user = getProfile(state);

  const country = get(
    user,
    "companyDetails.billingCountry",
    get(user, "billingCountry", null)
  );
  const vatNumber = get(
    user,
    "companyDetails.billingVAT",
    get(user, "billingVAT", null)
  );

  if (country) {
    if (!eu.includes(country)) {
      taxRate = 0;
    } else if (canBeExcludedFromTax.includes(country) && vatNumber) {
      taxRate = 0;
    }
  }

  const priceWithTax = price + price * taxRate;
  const discountedPriceWithTax = discountedPrice + discountedPrice * taxRate;
  return {
    words,
    marks,
    max,
    start,
    deadline,
    price,
    priceWithTax,
    discountedPrice,
    discountedPriceWithTax,
  };
}
const getName = (order, role) => {
  if (role === "client") {
    return order.translatorDetails.givenName;
  }
  return `${order.userDetails.title} ${order.userDetails.givenName} ${order.userDetails.familyName}`;
};

/**
 * Gets the code and human status of the order
 * @param order: the order to check the status for
 * @param role: either "client" or "translator"
 * @returns {{code: string, human: <FormattedMessage />}}
 */
export const orderStatus = (order, role) => {
  let human = "";
  if (order && order.status) {
    if (order.status === "Order.Status.InProgress") {
      human = (
        <FormattedMessage
          id={`${order.status}.${role}`}
          values={{ name: getName(order, role) }}
        />
      );
    } else if (order.status === "Order.Status.Complete") {
      human = (
        <FormattedMessage
          id={`${order.status}.${role}`}
          values={{
            translator: getName(order, role),
            date: moment.tz(order.completedAt, timezone).format("DD/MM/YYYY"),
          }}
        />
      );
    } else {
      human = <FormattedMessage id={order.status} />;
    }
  }
  return {
    code: order.status,
    human,
  };
};

/**
 * Filter to extract "in progress" translatiosn
 * param  order
 * @return boolean
 */
export const isNotComplete = (order) =>
  [
    "Order.Status.Unassigned",
    "Order.Status.Unpaid",
    "Order.Status.InProgress",
    "Order.Status.Suspended",
  ].includes(order.status);

export const isUnassigned = (order) =>
  ["Order.Status.Unassigned"].includes(order.status);

export const isUnpaid = (order) =>
  ["Order.Status.Unpaid"].includes(order.status);

// to know if an order is "in progress"
export const isInProgress = (order) =>
  ["Order.Status.InProgress", "Order.Status.Complete"].includes(order.status);

export const isSuspended = (order) =>
  ["Order.Status.Suspended"].includes(order.status);

/**
 * Filter to extract completed transaltions
 * param  order
 * @return boolean
 */
export const isComplete = (order) =>
  ["Order.Status.Complete"].includes(order.status);

export const isDefinitlyCompleted = (order) =>
  isComplete(order) && moment().diff(moment(order.completedAt), "days") >= 10;

const getAllUploads = (order) => {
  const attachments = order.attachments ? [...order.attachments] : [];
  if (order.upload) {
    attachments.push({
      name: order.upload.split("/").pop(),
      path: order.upload,
      authorId: order.user,
      type: "client",
      words: order.words,
    });
  }
  if (order.translatorUpload) {
    attachments.push({
      name: order.translatorUpload.split("/").pop(),
      path: order.translatorUpload,
      authorId: order.translatorId,
      type: "translator",
    });
  }
  return attachments;
};
export const getClientUploads = (order) =>
  getAllUploads(order).filter((u) => u.type === "client");
export const getClientIntermediateUploads = (order) =>
  getAllUploads(order).filter((u) => u.type === "client-intermediate");
export const getTranslatorUploads = (order) =>
  getAllUploads(order).filter((u) => u.type === "translator");
export const getTranslatorIntermediateUploads = (order) =>
  getAllUploads(order).filter((u) => u.type === "translator-intermediate");
export const getReferenceUploads = (order) =>
  getAllUploads(order).filter((a) => a.type === "reference");

export const getUploadsForFile = (order, { path }) =>
  getTranslatorUploads(order).filter((u) => u.translationFor === path);

export const getUploadsForIntermediateTranslatorFile = (order, { path }) =>
  getTranslatorIntermediateUploads(order).filter(
    (u) => u.translationFor === path
  );

export const getUploadsForIntermediateClientFile = (order, { path }) =>
  getClientIntermediateUploads(order).filter((u) => u.translationFor === path);

export const getIndexForAttachment = (order, attachment) => {
  const attachments = getAllUploads(order);
  const { path, name, nonce } = attachment;
  const search = { name };
  if (path) {
    search.path = path;
  }
  if (nonce) {
    search.nonce = nonce;
  }
  return findIndex(attachments, search);
};
