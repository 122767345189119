import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { loadOrder, updateOrder } from '../actions';
import Header from '../components/Header';
import RatingModal from '../orders-translator/RatingModal';
import TranslationForm from '../orders-translator/TranslationForm';
import { getCurrentOrder } from '../orders/selectors';
import OrderPropType from './../common/proptypes/OrderPropType';
import { Modal, ModalBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const TranslationPageTranslator = ({
  order,
  getOrder,
  match,
  doUpdateOrder,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  useEffect(() => {
    getOrder({ id: match.params.id });
  }, [getOrder, match]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const history = useHistory();

  console.log(order)

  if (order) {
    const doSubmit = (order, doUpdateOrder, history) => {
      const updatedOrder = {
        ...order,
        translatorRating: order.translatorRating,
      };
      doUpdateOrder({ order: updatedOrder, history });
    };
    const openModal = () => {
      if (order && !order.translatorRating) {
        setIsOpen(true);
      } else {
        doSubmit(order, doUpdateOrder, history);
      }
    };
    return (
      <>
        <Row className="header-container">
          <Col className="content-container">
            <Header
              left={<Link to="/" className="icon-arrow-left pink" />}
              center={<Link to="/" className="header__icon icon-my-brian" />}
            />
          </Col>
        </Row>

        <Container >
          <div className="content-container body">
            <Row>
              <Col>
                <TranslationForm onSubmit={() => openModal()} />
                {/* ----------------------------- MODAL ------------------------------- */}
                <Modal
                  centered={true}
                  size="lg"
                  isOpen={modalIsOpen}
                  toggle={closeModal}
                >
                  <ModalBody>
                    <RatingModal backFromRatingModal={closeModal} />
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
  return null;
};

TranslationPageTranslator.propTypes = {
  order: OrderPropType,
  getOrder: PropTypes.func.isRequired,
  // (https://github.com/ReactTraining/react-router/issues/6177)
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  // doUpdateOrder: PropTypes.func.isRequired,
};

TranslationPageTranslator.defaultProps = {
  order: null,
  match: null,
};

const stateToProps = (state) => ({
  order: getCurrentOrder(state),
});

const actionsToProps = (dispatch) => ({
  getOrder: (id) => dispatch(loadOrder(id)),
  doUpdateOrder: (order) => dispatch(updateOrder(order)),
});

export default connect(stateToProps, actionsToProps)(TranslationPageTranslator);
