import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./ProfileView.module.scss";
import Flag from "../components/Flag";
import StarRating from "../components/StarRating";

const ProfileView = ({ profile, isTranslator, editable }) => (
  <div className={styles.Container}>
    <img
      className={styles.Picture}
      width={100}
      src={profile.picture}
      alt={profile.givenName}
    />
    {editable && (
      <p className={styles.Gravatar}>
        <FormattedMessage
          id="Account.Gravatar"
          values={{
            linkToGravatar: (
              <a
                href="https://gravatar.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gravatar
              </a>
            ),
          }}
        />
      </p>
    )}
    <p className={styles.Name}>{profile.givenName}</p>
    <div className={styles.NationalityHolder}>
      {profile.nationality && profile.nationality.length === 2 && (
        <>
          <FormattedMessage id="Account.Nationality" />
          <Flag
            country={profile.nationality}
            className={styles.Nationality}
            includeText={false}
          />
        </>
      )}
      {profile.dualNationality && (
        <Flag
          country={profile.dualNationality}
          className={styles.Nationality}
          includeText={false}
        />
      )}
    </div>
    {isTranslator && <StarRating stars={profile.averageRating} />}
    <div className={styles.PublicDetails}>
      <dl>
        {/* {profile.expertise && isTranslator && (
          <>
            <dt>
              <FormattedMessage id="Account.AreaOfExpertise" />
            </dt>
            <dd>{profile.expertise}</dd>
          </>
        )} */}
        {profile.residence && (
          <>
            <dt>
              <FormattedMessage id="Account.Residence" />
            </dt>
            <dd>
              <FormattedMessage id={`Country.${profile.residence}`} />
            </dd>
          </>
        )}
        {profile.yearsOfExperience && (
          <>
            <dt>
              <FormattedMessage id="Account.YearsOfExperience" />
            </dt>
            <dd>{profile.yearsOfExperience}</dd>
          </>
        )}
      </dl>
    </div>
    <div className={styles.Bio}>{profile.bio}</div>
  </div>
);

export default ProfileView;
