import PropTypes from "prop-types";
import React, { useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import Card from "../layout/Card";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import CountryPickerField from "../components/formfields/CountryPickerField";
import TextareaFieldTranslator from "../components/formfields/TextareaFieldTranslator";
import TextField from "../components/formfields/TextField";
import { updateUser } from "./../actions";
import AccountPropType from "./../common/proptypes/AccountPropType";

const required = (value) => (value ? undefined : "Forms.Required");

const AccountDetailsTranslatorInfo = ({ profile, doUpdateUser }) => {
  const browserHistory = useHistory();
  const intl = useIntl();
  const [formIsUpdated, setFormIsUpdated] = useState(false);

  return (
    <Form
      onSubmit={(user) => doUpdateUser({ user, browserHistory })}
      initialValues={profile}
    >
      {({ handleSubmit, values }) => (
        <div>
          <FormSpy
            subscription={{ dirty: true }}
            onChange={(props) => setFormIsUpdated(props.dirty)}
          />
          <form onSubmit={handleSubmit}>
            <Card fill faIcon={faGlobe} title="Account.TranslatorProfile">
              <Prompt
                when={formIsUpdated}
                message={intl.formatMessage({
                  id: "General.YouHaveUnsavedChanges",
                })}
              />
              <Field
                component={CountryPickerField}
                name="nationality"
                label="Account.Nationality"
                validate={required}
                showErrorBeforeTouched
                parse={(option) => option.value}
              />

              <Field
                component={CountryPickerField}
                name="dualNationality"
                label="Account.DualNationality"
                parse={(option) => option.value}
              />

              <Field
                component={CountryPickerField}
                name="residence"
                label="Account.Residence"
                parse={(option) => option.value}
              />

              <Field
                component={TextField}
                type="number"
                name="yearsOfExperience"
                label="Account.YearsOfExperience"
              />

              <div className="section-textarea">
                <Field
                  component={TextareaFieldTranslator}
                  name="bio"
                  label="Account.Bio"
                />
              </div>
            </Card>
            <Row>
              <Col align="center">
                <Button type="submit" className="button button--inline">
                  <FormattedMessage id="Forms.Submit" />
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      )}
    </Form>
  );
};

AccountDetailsTranslatorInfo.propTypes = {
  profile: AccountPropType.isRequired,
  doUpdateUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doUpdateUser: updateUser,
};

export default connect(null, mapDispatchToProps)(AccountDetailsTranslatorInfo);
