import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import { Button, ControlLabel } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from "react-intl";
import {
  agreeToTerms,
  confirmOrder,
  loadLocalOrder,
  loadOrder,
  updateLocale,
  updateOrder,
} from "../actions";
import { getDiscount, getDiscountReason } from "../account/selectors";
import { parse } from "query-string";

import PaymentButton from "../components/PaymentButton";
import CheckboxField from "../components/formfields/CheckboxField";
import { linkToTerms } from "../constants";
import { getCompany, getLang, getSettingsSpecialities } from "../selectors";
import { getProfile } from "../account/selectors";
import DeadlineAndPrice from "../components/DeadlineAndPrice";
import Header from "../components/Header";

import { getCurrentOrder } from "../orders/selectors";
import Languages from "../components/Languages";
import {
  getClientUploads,
  getReferenceUploads,
  calculatedValues,
  getIndexForAttachment,
} from "../orders/orderUtils";
import Attachment from "../attachments/Attachment";
import OrderPropType from "../common/proptypes/OrderPropType";
import ProgressBar from "../components/ProgressBar";
import { Link } from "react-router-dom";
import { canBeExcludedFromTax, eu, tax } from "../constants";
import { useState, useEffect } from "react";
import storage from "../cookie";
import { Container, Row, Col } from "reactstrap";
import PdfButton from "../components/PdfButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const ConfirmOrderPage = ({
  match,
  location,
  history,
  doLoadLocalOrder,
  doLoadOrder,
  doConfirm,
  doAgreeToTerms,
  order,
  user,
  company,
  deadline,
  price,
  discountedPrice,
  discountReason,
  doUpdateLocale,
  settingsSpecialities,
  userLang,
  doUpdateOrder,
}) => {
  const query = parse(location.search);
  const [submitting, setSubmitting] = useState(false);
  const [pdfIsSent, setpdfIsSent] = useState(false);
  useEffect(() => {
    if (!order && match.params.id === "new") {
      doLoadLocalOrder();
    } else {
      if (!order || match.params.id !== order._id) {
        doLoadOrder({ id: match.params.id, email: query.email || null });
      }
    }
  }, [match, doLoadLocalOrder, doLoadOrder, order, query]);

  useEffect(() => {
    if (order && order.quoteLang && !user) {
      doUpdateLocale(order.quoteLang);
    }
  }, [order, user, doUpdateLocale]);

  const confirm = () => {
    setSubmitting(true);
    doConfirm({ order, browserHistory: history, email: query.email });
  };

  discountReason = order?.averageRedundancy > 0 ? "Redundant" : undefined;
  const showError = /error/.test(window.location.search);

  const references = order?.attachments.filter((a) => a.type === "reference");

  if (order) {
    if (
      !["Order.Status.Unpaid", "Order.Status.Quoted"].includes(order.status)
    ) {
      if (!(!order || match.params.id !== order?._id))
        history.push(`/view/${order._id}`);
    }
    let taxRate = tax;

    const country = get(
      user,
      "companyDetails.billingCountry",
      get(user, "billingCountry", null)
    );

    const canEdit = order.status === "Order.Status.Unpaid";
    const locale = user ? user.lang : order.quoteLang;

    const uploadsToTranslate = getClientUploads(order);
    const uploadsForReference = getReferenceUploads(order);

    const pdfHasBeenSent = (response) => {
      setpdfIsSent(response);
    };
    const loggedIn = !!user;

    const vatNumber = get(
      user,
      "companyDetails.billingVAT",
      get(user, "billingVAT", null)
    );

    if (country) {
      if (!eu.includes(country)) {
        taxRate = 0;
      } else if (canBeExcludedFromTax.includes(country) && vatNumber) {
        taxRate = 0;
      }
    }
    taxRate += 1;
    const priceWithTax = (order.price || price) * taxRate;
    const discountedPriceWithTax =
      (order.discountedPrice || discountedPrice) * taxRate;

    const speciality = settingsSpecialities.find(
      (s) => s.key === order.specialityId
    )?.speciality[userLang];

    const priceQuote =
      order?.averageRedundancy > 0
        ? order?.discountedPrice || discountedPrice
        : order?.price || price;

    return (
      <>
        <Row className="header-container">
          <Col className="content-container">
            <Header />
          </Col>
        </Row>

        <Container>
          <div className="content-container body">
            <Row>
              <Col>
                {/* ----------------------------- SUCCESS MESSAGE ------------------------------- */}
                <div
                  className={`section-success-message ${
                    showError ? "section-success-message--show" : ""
                  }`}
                >
                  <FormattedMessage id="Order.ErrorProcessing" />
                </div>
                <ProgressBar />

                <div className="sectionBox">
                  <div className="orderTitle flex items-center space-between">
                    <FormattedMessage
                      id={
                        order.proofreading
                          ? "Proofreading.View.Header"
                          : "Order.View.Header"
                      }
                    />
                    {canEdit && (
                      <LinkContainer
                        className="updateText"
                        to={`/order/${order._id}`}
                      >
                        <Button>
                          <FontAwesomeIcon
                            style={{ marginRight: 5 }}
                            icon={faEdit}
                          />
                          <FormattedMessage id="Order.Modify" />
                        </Button>
                      </LinkContainer>
                    )}
                  </div>
                  <div
                    className="flex"
                    style={{ marginBottom: 20, flexWrap: "wrap" }}
                  >
                    {uploadsToTranslate &&
                      uploadsToTranslate.map((u, _i) => (
                        <div
                          style={{ marginRight: 20, marginBottom: 10 }}
                          key={`${_i}${new Date().toISOString()}`}
                        >
                          <Attachment
                            key={u.name}
                            attachment={u}
                            index={getIndexForAttachment(order, u)}
                            readonly
                          />
                        </div>
                      ))}
                  </div>
                  {!!order.text && (
                    <div style={{ marginBottom: 20 }}>{order.text}</div>
                  )}
                  <div style={{ width: 250, marginBottom: 20 }}>
                    <div className="blue flex space-between">
                      <span>
                        <FormattedMessage id="Order.Words.Unique" /> :
                      </span>
                      <span>
                        {order.words - order.redundantWords}{" "}
                        <FormattedMessage id="Order.Word" />
                      </span>
                    </div>
                    <div className="blue flex space-between">
                      <span>
                        <FormattedMessage id="Order.Words.Repeated" /> :
                      </span>
                      <span>
                        {order.redundantWords}{" "}
                        <FormattedMessage id="Order.Word" />
                      </span>
                    </div>
                    <div
                      style={{ fontWeight: "bold" }}
                      className="blue flex space-between"
                    >
                      <span>
                        <FormattedMessage id="Order.Words.Total" /> :
                      </span>
                      <span>
                        {order.words} <FormattedMessage id="Order.Word" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="sectionBox">
                  <div className="orderTitle flex items-center space-between">
                    <FormattedMessage id="Order.Confirm.PriceAndDeadline" />
                    {canEdit && (
                      <LinkContainer
                        className="updateText"
                        to={`/order/${order._id}/context`}
                      >
                        <Button>
                          <FontAwesomeIcon
                            style={{ marginRight: 5 }}
                            icon={faEdit}
                          />
                          <FormattedMessage id="Order.Modify" />
                        </Button>
                      </LinkContainer>
                    )}
                  </div>
                  <div className="blue" style={{ marginBottom: 20 }}>
                    <FormattedMessage id="Order.Opti" />
                  </div>
                  <div id="updatePanel">
                    <DeadlineAndPrice
                      deadline={deadline}
                      price={canEdit ? price : order.price}
                      priceWithTax={priceWithTax}
                      discountedPrice={
                        canEdit ? discountedPrice : order.discountedPrice
                      }
                      discountedPriceWithTax={discountedPriceWithTax}
                      discountReason={discountReason}
                      locale={locale}
                      order={order}
                    />
                  </div>
                  {user && !country && (
                    <Link
                      to={`/settings/account?return=/confirm/${order._id}${
                        query.email ? "?email=" + query.email : ""
                      }`}
                    >
                      <ControlLabel className={`section-textarea__message`}>
                        <FormattedMessage id="Billing.SetCountryPrompt" />
                      </ControlLabel>
                    </Link>
                  )}
                  <br />
                  <Languages order={order} />
                </div>

                <div className="sectionBox">
                  <div className="orderTitle flex items-center space-between">
                    <FormattedMessage id="Translator.Expertise" />
                    {canEdit && (
                      <LinkContainer
                        className="updateText"
                        to={`/order/${order._id}`}
                      >
                        <Button>
                          <FontAwesomeIcon
                            style={{ marginRight: 5 }}
                            icon={faEdit}
                          />
                          <FormattedMessage id="Order.Modify" />
                        </Button>
                      </LinkContainer>
                    )}
                  </div>
                  <div className="blue">
                    <FormattedMessage id="Expertise" /> : {speciality}
                  </div>
                </div>

                <div className="sectionBox">
                  <div className="orderTitle flex items-center space-between">
                    <FormattedMessage id="Translator.Instruction" />
                    {canEdit && (
                      <LinkContainer
                        className="updateText"
                        to={`/order/${order._id}/context`}
                      >
                        <Button>
                          <FontAwesomeIcon
                            style={{ marginRight: 5 }}
                            icon={faEdit}
                          />
                          <FormattedMessage id="Order.Modify" />
                        </Button>
                      </LinkContainer>
                    )}
                  </div>
                  <div className="blue" style={{ marginBottom: 20 }}>
                    <FormattedMessage id="Translator.For" />
                  </div>
                  {/* <div className="section-text notranslate"> */}
                  {order.additionalInfo === "" || !order.additionalInfo ? (
                    <FormattedMessage id="NotSpecified" />
                  ) : (
                    order.additionalInfo
                  )}
                </div>
                {/* </div> */}

                <div className="sectionBox">
                  <div className="orderTitle flex items-center space-between">
                    <FormattedMessage id="Translator.File.Context" />
                    {canEdit && (
                      <LinkContainer
                        className="updateText"
                        to={`/order/${order._id}/context`}
                      >
                        <Button>
                          <FontAwesomeIcon
                            style={{ marginRight: 5 }}
                            icon={faEdit}
                          />
                          <FormattedMessage id="Order.Modify" />
                        </Button>
                      </LinkContainer>
                    )}
                  </div>

                  <div
                    className="blue"
                    style={{ marginBottom: 10, marginRight: 20 }}
                  >
                    <FormattedMessage id="Translator.For" />
                  </div>
                  <div className="flex" style={{ flexWrap: "wrap" }}>
                    {references.filter(
                      (a) => a.name.split(".").at(-1) !== "tmx"
                    ) &&
                      references
                        .filter((a) => a.name.split(".").at(-1) !== "tmx")
                        ?.map((u) => (
                          <div style={{ marginRight: 20 }}>
                            <Attachment
                              key={u.name}
                              attachment={u}
                              index={getIndexForAttachment(order, u)}
                              readonly
                            />
                          </div>
                        ))}
                  </div>
                </div>

                {loggedIn && !order.proofreading && (
                  <div className="sectionBox">
                    <div className="orderTitle flex items-center space-between">
                      <FormattedMessage id="Translator.File.TMX" />
                      {canEdit && (
                        <LinkContainer
                          className="updateText"
                          to={`/order/${order._id}`}
                        >
                          <Button>
                            <FontAwesomeIcon
                              style={{ marginRight: 5 }}
                              icon={faEdit}
                            />
                            <FormattedMessage id="Order.Modify" />
                          </Button>
                        </LinkContainer>
                      )}
                    </div>
                    <FormattedMessage id="Translator.Already.TMX" />
                  </div>
                )}

                {/* <Card fill title="Order.Confirm.Specificity">
                  {canEdit && (
                    <LinkContainer
                      to={`/order/${order._id}`}
                      className="section-edit__link"
                    >
                      <Button>
                        <FormattedMessage
                          id="Order.Modify"
                          defaultMessage="Modify"
                        />
                      </Button>
                    </LinkContainer>
                  )}
                  <div className="section-text notranslate">
                    {order.specificity === "" || !order.specificity ? (
                      <FormattedMessage id="NotSpecified" />
                    ) : (
                      <p>
                        <span>
                          <FormattedMessage
                            id={`Order.Specificity.${order.specificity}`}
                          />
                        </span>
                        {order.specificity === "specialist" &&
                          !!order.specialityId &&
                          settingsSpecialities.length > 0 && (
                            <span>
                              {" "}
                              &nbsp;:{" "}
                              {
                                settingsSpecialities.find(
                                  (spe) => spe.key === order.specialityId
                                ).speciality[locale || "fr"]
                              }
                            </span>
                          )}
                      </p>
                    )}
                  </div>

                  <div className="section-edit">
                    <div className="section-edit__title">
                      <FormattedMessage id="Order.Confirm.AdditionalInfo" />
                    </div>
                    {canEdit && (
                      <LinkContainer
                        to={`/order/${order._id}/context`}
                        className="section-edit__link"
                      >
                        <Button>
                          <FormattedMessage id="Order.Modify" />
                        </Button>
                      </LinkContainer>
                    )}
                  </div>
                  <div className="section-text notranslate">
                    {order.additionalInfo === "" || !order.additionalInfo ? (
                      <FormattedMessage id="NotSpecified" />
                    ) : (
                      order.additionalInfo
                    )}
                  </div>
                </Card> */}

                {/* {!!uploadsForReference.length && (
                  <Card fill title="Order.View.Reference">
                    {canEdit && (
                      <LinkContainer
                        to={`/order/${order._id}/context`}
                        className="section-edit__link"
                      >
                        <Button>
                          <FormattedMessage id="Order.Modify" />
                        </Button>
                      </LinkContainer>
                    )}
                    {uploadsForReference.map((u) => (
                      <Attachment key={u.name} attachment={u} readonly />
                    ))}
                  </Card>
                )} */}

                <div className="section-general">
                  {loggedIn && (
                    <div style={{ padding: 20 }}>
                      <CheckboxField
                        input={{
                          name: "onlyFavTranslator",
                          value: order.onlyFavTranslator,
                          onChange: () =>
                            doUpdateOrder({
                              order: {
                                ...order,
                                onlyFavTranslator: !order.onlyFavTranslator,
                              },
                              history,
                            }),
                        }}
                        meta={{}}
                        customLabel={
                          <div>
                            <FormattedHTMLMessage id="Order.Restrict.Favorites" />
                          </div>
                        }
                      />
                      <CheckboxField
                        input={{
                          name: "agreedToTerms",
                          value: user.agreedToTerms,
                          disabled: user.agreedToTerms,
                          onChange: () => doAgreeToTerms(),
                        }}
                        meta={{}}
                        customLabel={
                          <FormattedMessage
                            id="Account.AgreeTerms"
                            values={{ linkToTerms }}
                          />
                        }
                      />
                    </div>
                  )}
                  <Row>
                    <Col md={8} xs={12} className="my-2">
                      <PaymentButton
                        priceWithTax={priceWithTax}
                        discountedPriceWithTax={discountedPriceWithTax}
                        discountReason={discountReason}
                        order={order}
                        user={user}
                        company={company}
                        onConfirm={confirm}
                        onLogin={() => {
                          if (order._id && order._id !== "new") {
                            storage.setItem(
                              "redirect-after",
                              `/confirm/${order._id}${
                                query.email ? "?email=" + query.email : ""
                              }`
                            );
                          }
                          history.push("/login");
                        }}
                        enabled={!submitting && user && !!user.agreedToTerms}
                      />
                    </Col>
                    <Col md={4} xs={12} className="my-2">
                      <PdfButton
                        order={{ ...order, price: priceQuote }}
                        pdfHasBeenSent={pdfHasBeenSent}
                        disabled={false}
                        messageId="SendQuote.ReceiveQuote"
                      />
                    </Col>
                  </Row>
                  {pdfIsSent && (
                    <Row>
                      <Col>
                        <p className="pink">
                          Le devis vous a été envoyé par mail.
                        </p>
                      </Col>
                    </Row>
                  )}
                </div>
                <div className="flex blue items-center">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ marginRight: 10 }}
                  />
                  <FormattedMessage id="Order.NotConfirmed" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
  return (
    <div className="loading-message">
      <FormattedMessage id="Loading" />
    </div>
  );
};

ConfirmOrderPage.propTypes = {
  order: OrderPropType,
  doConfirm: PropTypes.func.isRequired,
  user: PropTypes.object,
  company: PropTypes.object,
  doAgreeToTerms: PropTypes.func.isRequired,
  settingsSpecialities: PropTypes.array,
};

ConfirmOrderPage.defaultProps = {
  order: undefined,
  user: undefined,
  company: undefined,
};

const stateToProps = (state, ownProps) => {
  const user = getProfile(state);
  const order = getCurrentOrder(state);
  const company = getCompany(state);
  const discount = getDiscount(state);
  const discountReason = getDiscountReason(state);
  const settingsSpecialities = getSettingsSpecialities(state);
  const calc = order
    ? calculatedValues(state, order, discount, discountReason)
    : {};
  return {
    company,
    user,
    order,
    settingsSpecialities,
    ...calc,
    userLang: getProfile(state) ? getProfile(state).lang : getLang(state),
  };
};

const actionsToProps = {
  doConfirm: confirmOrder,
  doAgreeToTerms: agreeToTerms,
  doLoadLocalOrder: loadLocalOrder,
  doLoadOrder: loadOrder,
  doUpdateLocale: updateLocale,
  doUpdateOrder: updateOrder,
};

export default injectIntl(
  connect(stateToProps, actionsToProps)(ConfirmOrderPage)
);
