import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { updateUser } from '../actions';
import AccountPropType from '../common/proptypes/AccountPropType';
import CheckboxField from '../components/formfields/CheckboxField';
import SelectField from '../components/formfields/SelectField';
import TextareaFieldTranslator from '../components/formfields/TextareaFieldTranslator';
import TextField from '../components/formfields/TextField';
import { linkToTerms, titles } from '../constants';
import Card from '../layout/Card';
import SplitPaneTranslator from '../layout/SplitPaneTranslator';
import { settings } from '../selectors';
import composeValidators from '../utils/composeValidators';
import ProfileView from './ProfileView';
import { faUser, faGlobe } from '@fortawesome/free-solid-svg-icons';

const required = (value) => (value ? undefined : 'Forms.Required');
// FIXME: TWITTER LOGIN NOT RETURN name and email, server set whitespace for this fields, required fields
const requiredIfWhiteSpace = (value) =>
  value.trim() !== '' ? undefined : 'Forms.Required';

const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Forms.InvalidEmail'
    : undefined;
const agreeTermsRequired = (value) =>
  value ? undefined : 'Account.AgreeTerms.Error';
const linkToTermsTranslator = (
  <a
    href="http://mybrian.fr/Xies4ooz-Translator-Terms.html"
    target="_blank"
    rel="noopener noreferrer"
    className="pink"
  >
    <FormattedMessage id="Account.AgreeLink" />
  </a>
);

const AccountDetailsProfile = ({
  profile,
  isTranslator,
  supportedLanguages,
  doUpdateUser,
}) => {
  const browserHistory = useHistory();
  const intl = useIntl();
  const [formIsUpdated, setFormIsUpdated] = useState(false);

  const languageOptions = () => {
    return supportedLanguages.map((lang) => ({
      value: lang.iso6391Name,
      text: intl.formatMessage({ id: `Language.${lang.iso6391Name}` }),
    }));
  };
  const titleOptions = () => {
    const options = titles.map((title) => ({
      value: intl.formatMessage({ id: `Account.Title.${title}` }),
      text: intl.formatMessage({ id: `Account.Title.${title}` }),
    }));

    return uniqBy(options, 'value');
  };

  const validTitleSelection = (value) =>
    value && !titleOptions().find((o) => o.value === value)
      ? 'Forms.InvalidOption'
      : undefined;

  const submitForm = (user, browserHistory) => {
    doUpdateUser({ user, browserHistory });
  };
  if ((!profile.givenName.replace(/ /g,'') || !profile.familyName.replace(/ /g,''))) {
    profile.givenName =
      'name' +
      Math.floor(10000 * Math.random())
        .toString()
        .padStart(4, '0');
    profile.familyName =
      'fname' +
      Math.floor(10000 * Math.random())
        .toString()
        .padStart(4, '0');
  }

  return (
    <>
      <Prompt
        when={formIsUpdated}
        message={intl.formatMessage({ id: 'General.YouHaveUnsavedChanges' })}
      />
      <Form
        onSubmit={(user) => submitForm(user, browserHistory)}
        initialValues={profile}
      >
        {({ handleSubmit, values }) => (
          <div>
            {isTranslator && !profile.agreedToTranslatorTerms && (
              <Card>
                <div className="section-success-message section-success-message--show">
                  <FormattedMessage id="Account.TranslatorHelp" />
                </div>
              </Card>
            )}
            {!isTranslator && !profile.agreedToTerms && (
              <Card>
                <div className="section-success-message section-success-message--show">
                  <FormattedMessage id="Account.Help" />
                </div>
              </Card>
            )}
            <form onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ dirty: true }}
                onChange={(props) => setFormIsUpdated(props.dirty)}
              />
              <Card fill faIcon={faUser} title="Settings.PersonalInformation">
                <SplitPaneTranslator
                  left={
                    <Row>
                      <Col xs="12">
                        <Field
                          component={SelectField}
                          name="title"
                          label="Account.Title"
                          options={titleOptions()}
                          validate={composeValidators(
                            required,
                            validTitleSelection
                          )}
                          showErrorBeforeTouched
                        />
                      </Col>
                      <Col xs="12">
                        <Field
                          component={TextField}
                          name="givenName"
                          label="Account.GivenName"
                          validate={composeValidators(
                            required,
                            requiredIfWhiteSpace
                          )}
                          showErrorBeforeTouched
                        />
                      </Col>
                      <Col xs="12">
                        <Field
                          component={TextField}
                          name="familyName"
                          label="Account.FamilyName"
                          validate={composeValidators(
                            required,
                            requiredIfWhiteSpace
                          )}
                          showErrorBeforeTouched
                        />
                      </Col>
                      <Col xs="12">
                        <Field
                          component={TextField}
                          name="email"
                          label="Account.Email"
                          validate={composeValidators(required, email)}
                          showErrorBeforeTouched
                          type="email"
                        />
                      </Col>
                      <Col xs="12" md="4">
                        <Field
                          component={TextField}
                          name="phone"
                          label="Account.Phone"
                          validate={isTranslator ? required : null}
                          showErrorBeforeTouched
                        />
                      </Col>
                      <Col xs="12">
                        <div className="section-textarea">
                          <Field
                            component={TextareaFieldTranslator}
                            name="address"
                            label="Account.Address"
                            validate={isTranslator ? required : null}
                            showErrorBeforeTouched
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                  right={
                    <ProfileView
                      isTranslator={isTranslator}
                      profile={values}
                      editable
                    />
                  }
                />
              </Card>

              <Card fill faIcon={faGlobe} title="Settings.DisplayLanguage">
                <Row>
                  <Col xs="12" md="4">
                    <Field
                      component={SelectField}
                      name="lang"
                      className="w-25"
                      options={languageOptions()}
                    />
                  </Col>
                </Row>
              </Card>

              <Card fill>
                {isTranslator && (
                  <Field
                    component={CheckboxField}
                    validate={agreeTermsRequired}
                    name="agreedToTranslatorTerms"
                    type="checkbox"
                    customLabel={
                      <FormattedMessage
                        id="Account.AgreeTermsTranslator"
                        values={{ linkToTermsTranslator }}
                      />
                    }
                  />
                )}
                {!isTranslator && (
                  <Field
                    component={CheckboxField}
                    validate={agreeTermsRequired}
                    name="agreedToTerms"
                    type="checkbox"
                    customLabel={
                      <FormattedMessage
                        id="Account.AgreeTerms"
                        values={{ linkToTerms }}
                      />
                    }
                  />
                )}
              </Card>

              <Row>
                <Col align="center">
                  <Button type="submit" className="button button--inline">
                    <FormattedMessage id="Forms.Submit" />
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </Form>
    </>
  );
};

AccountDetailsProfile.propTypes = {
  profile: AccountPropType.isRequired,
  supportedLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      iso6391Name: PropTypes.string,
    })
  ).isRequired,
  doUpdateUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  supportedLanguages: settings(state).supportedLanguages,
});

const mapDispatchToProps = {
  doUpdateUser: updateUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsProfile);
