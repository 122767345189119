import React from "react";

import styles from "./MessageBox.module.scss";

const MessageBox = ({ title, content, color, badgeIcon, noMargin }) => {
  return (
    <div
      className={styles.Box}
      style={{ color, marginLeft: noMargin ? 0 : null }}
    >
      {title && (
        <p className="relative">
          {title}
          <span
            className="absolute flex-center"
            style={{
              left: -35,
              top: 0,
              height: 25,
              width: 25,
              backgroundColor: color,
              borderRadius: "50%",
            }}
          >
            {badgeIcon}
          </span>
        </p>
      )}
      <span>{content}</span>
    </div>
  );
};

export default MessageBox;
