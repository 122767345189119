import React, { useRef } from "react";

import style from "./ProgressBar.module.scss";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import MaybeLink from "./MaybeLink";
import Xarrow from "react-xarrows";

const ProgressBar = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const linkToConfirm = location.pathname.includes("confirm");
  const linkToContext = linkToConfirm || location.pathname.includes("context");
  const firstRef = useRef(null);

  return (
    <div className={style.Container}>
      <Link to={`/order/${id}`} className={style.Complete}>
        <span ref={firstRef}>1</span>
        <FormattedMessage id="ProgressBar.Step1" />
      </Link>
      <MaybeLink
        to={`/order/${id}/context`}
        link={linkToContext}
        inactiveClassName={style.Incomplete}
        activeClassName={style.Complete}
      >
        <>
          <span>2</span>
          <FormattedMessage id="ProgressBar.Step2" />
        </>
      </MaybeLink>
      <MaybeLink
        to={`/confirm/${id}`}
        link={linkToConfirm}
        inactiveClassName={style.Incomplete}
        activeClassName={style.Complete}
      >
        <>
          <span id="last">3</span>
          <FormattedMessage id="ProgressBar.Step3" />
        </>
      </MaybeLink>
      <Xarrow
        dashness
        showHead={false}
        color="#FE5F6B"
        strokeWidth={2}
        start={firstRef}
        end="last"
      />
    </div>
  );
};

export default ProgressBar;
