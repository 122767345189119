import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import { getProfile } from "../account/selectors";

const profileComplete = (profile) =>
  profile && profile.email;

const UserHasCompletedProfile = connectedRouterRedirect({
  authenticatedSelector: (state) =>
    !!(!getProfile(state) || profileComplete(getProfile(state))),
  authenticatingSelector: (state) => {
/*    console.log(
      "checking login status",
      !(!getProfile(state) || profileComplete(getProfile(state))),
      state.users.isSaving,
      state.auth.isLoading
    );*/
    return (
      !(!getProfile(state) || profileComplete(getProfile(state))) ||
      state.users.isSaving ||
      state.auth.isLoading
    );
  },

  redirectPath: "/settings/account",
  wrapperDisplayName: "UserHasCompleteProfile", // a nice name for this auth check
});

export default UserHasCompletedProfile;
