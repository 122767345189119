import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import momentPropTypes from 'react-moment-proptypes';

import { timezone } from '../constants';
import styles from './DateTime.module.scss';

const DateTime = ({ dateTime, title, locale }) => {
  const tzMoment = moment.tz(dateTime, timezone);
  return (
    <div className={styles.Container}>
      {title && (
        <div className={styles.Title}>
          <FormattedMessage id={title} />
        </div>
      )}
      <div className={styles.Date}>{tzMoment.calendar()}</div>
      <div className={styles.Time}>{tzMoment.format("LT")}</div>
    </div>
  );
};

DateTime.propTypes = {
  dateTime: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj])
    .isRequired,
  title: PropTypes.string,
  locale: PropTypes.string,
};

DateTime.defaultProps = {
  title: null,
  locale: "fr",
};

export default DateTime;
