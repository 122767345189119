import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Card.module.scss";
import ClickableDiv from "./ClickableDiv";

const Card = ({
  title,
  fill,
  centre,
  rightTitle,
  className,
  collapsible,
  children,
  rounded,
  icon,
  faIcon,
}) => {
  const [collapsed, setCollapsed] = useState(collapsible);

  return (
    <div
      className={classnames({
        [styles.Card]: true,
        [className]: !!className,
        [styles.Rounded]: rounded,
      })}
    >
      {!!title && (
        <ClickableDiv
          onClick={collapsible ? () => setCollapsed(!collapsed) : null}
          className={classnames({
            [styles.TitleNoIcon]: Boolean(!icon),
            [styles.Title]: Boolean(icon),
            [styles.Clickable]: !!collapsible,
          })}
        >
          <div
            data-icon={icon}
            className={classnames({
              [styles.Collapsed]: collapsible && collapsed,
              [styles.Expanded]: collapsible && !collapsed,
            })}
          >
            {faIcon && (
              <FontAwesomeIcon icon={faIcon} style={{ marginRight: "1em" }} />
            )}
            <FormattedMessage id={title} />
          </div>
          {!!rightTitle && (
            <div className={styles.RightTitle}>{rightTitle}</div>
          )}
        </ClickableDiv>
      )}
      {!collapsed && (
        <div
          className={classnames({
            [styles.Filled]: fill,
            [styles.Centre]: centre,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  fill: PropTypes.bool,
  centre: PropTypes.bool,
  rightTitle: PropTypes.node,
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  rounded: PropTypes.bool,
  icon: PropTypes.string,
};

Card.defaultProps = {
  title: null,
  fill: false,
  rightTitle: null,
  className: null,
  collapsible: false,
  centre: false,
  rounded: false,
};

export default Card;
