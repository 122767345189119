import PropTypes from "prop-types";
import React, { useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { faFileInvoiceDollar,faAddressCard } from "@fortawesome/free-solid-svg-icons";

import { updateUser } from "../actions";
import AccountPropType from "../common/proptypes/AccountPropType";
import ConditionalField from "../components/formfields/ConditionalField";
import CountryPickerField from "../components/formfields/CountryPickerField";
import SelectField from "../components/formfields/SelectField";
import TextField from "../components/formfields/TextField";
import { billTos, canBeExcludedFromTax } from "../constants";
import Card from "../layout/Card";
import composeValidators from "../utils/composeValidators";

const required = (value) => (value ? undefined : "Forms.Required");

const AccountDetailsClientInfo = ({ profile, doUpdateUser }) => {
  const browserHistory = useHistory();
  const intl = useIntl();
  const [formIsUpdated, setFormIsUpdated] = useState(false);

  const billToOptions = () => {
    return billTos.map((to) => ({
      value: `Account.BillTo.${to}`,
      text: intl.formatMessage({ id: `Account.BillTo.${to}` }),
    }));
  };

  const validBillToSelection = (value) =>
    value && !billToOptions().find((o) => o.value === value)
      ? "Forms.InvalidOption"
      : undefined;
  return (
    <>
      <Prompt
        when={formIsUpdated}
        message={intl.formatMessage({ id: "General.YouHaveUnsavedChanges" })}
      />
      <Form
        onSubmit={(user) => doUpdateUser({ user, browserHistory })}
        initialValues={profile}
      >
        {({ handleSubmit }) => (
          <div>
            <form onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ dirty: true }}
                onChange={(props) => setFormIsUpdated(props.dirty)}
              />
              <Card fill title="Account.myProfile" faIcon={faFileInvoiceDollar}>
                <Field
                  component={SelectField}
                  name="billTo"
                  label="Account.BillTo"
                  options={billToOptions()}
                  validate={composeValidators(required, validBillToSelection)}
                />

                <ConditionalField when="billTo" is="Account.BillTo.Company">
                  <Field
                    component={TextField}
                    name="billingName"
                    label="Account.BillingName"
                    validate={required}
                    showErrorBeforeTouched
                  />
                </ConditionalField>

                <Field
                  name="billingCountry"
                  label="Billing.Country"
                  component={CountryPickerField}
                  parse={(option) => option.value}
                />
                <ConditionalField
                  when="billingCountry"
                  condition={(country) =>
                    canBeExcludedFromTax.includes(country)
                  }
                >
                  <Field
                    name="billingVAT"
                    label="Billing.VAT"
                    component={TextField}
                  />
                </ConditionalField>
              </Card>
              <Card fill faIcon={faAddressCard} title="Account.Context">
                <Field
                  component={TextField}
                  name="jobTitle"
                  label="Account.JobTitle"
                />
                <Field
                  component={TextField}
                  name="department"
                  label="Account.Department"
                />

                <Field
                  component={TextField}
                  name="sector"
                  label="Account.Sector"
                />
              </Card>

              <Row>
                <Col align="center">
                  <Button type="submit" className="button button--inline">
                    <FormattedMessage id="Forms.Submit" />
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </Form>
    </>
  );
};

AccountDetailsClientInfo.propTypes = {
  profile: AccountPropType.isRequired,
  doUpdateUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doUpdateUser: updateUser,
};

export default connect(null, mapDispatchToProps)(AccountDetailsClientInfo);
