import React, { useState, useContext, createContext, useEffect } from "react";
import { connect } from "react-redux";
import { showChat } from "../actions";
import FavoriteNoResponse from "../notifications/modal-notif-content/FavoriteNoResponse";
import ModalNotif from "../notifications/ModalNotif";
import { getModalNotifications } from "../notifications/selectors";

export const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children, notifsModal, doShowChat }) => {
  const [modalContent, setModalContent] = useState(null);

  const close = () => {
    localStorage.setItem("modalLastOpening", new Date());
    setModalContent(null);
  };

  const open = (content) => {
    setModalContent(content);
  };

  useEffect(() => {
    const currentDate = new Date();
    const oldDate = new Date(localStorage.getItem("modalLastOpening"));
    const delay = Math.abs(currentDate - oldDate);
    const canBeOpen = delay > 300000;

    if (notifsModal?.length > 0 && canBeOpen) {
      open(
        <FavoriteNoResponse
          doShowChat={doShowChat}
          closeModal={close}
          favNotif={notifsModal[0]}
        />
      );
    }
  }, [notifsModal]);

  return (
    <ModalContext.Provider
      value={{
        open,
        close,
      }}
    >
      {children}
      {modalContent && (
        <ModalNotif closeModal={close}>{modalContent}</ModalNotif>
      )}
    </ModalContext.Provider>
  );
};

const stateToProps = (state) => ({
  notifsModal: getModalNotifications(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  doShowChat: () => dispatch(showChat("ADMIN")),
});

export default connect(stateToProps, dispatchToProps)(ModalProvider);
