import React from "react";
import AccountPropType from "../common/proptypes/AccountPropType";
import {
  useLocation,
  Switch,
  Route,
  NavLink as RRNavLink,
} from "react-router-dom";
import PropTypes from "prop-types";
import Card from "../layout/Card";
import AccountDetailsTranslatorCanTranslate from "../account/AccountDetailsTranslatorCanTranslate";
import AccountDetailsTranslatorInfo from "../account/AccountDetailsTranslatorInfo";
import AccountDetailsClientInvoices from "../account/AccountDetailsClientInvoices";
import styles from "./Account.module.scss";
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Tooltips from "../components/Tooltips";
import { FormattedMessage, useIntl } from "react-intl";
import AccountDetailsProfile from "./AccountDetailsProfile";
import AccountDetailsClientInfo from "./AccountDetailsClientInfo";
import AccountDetailsTranslatorBillingInfo from "./AccountDetailsTranslatorBillingInfo";
import AccountDetailsMyFavoriteTranslators from "./AccountDetailsMyFavoriteTranslators";
import AccountDetailsTranslatorProofreading from "./AccountDetailsTranslatorProofreading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import AccountDetailsSpecialities from "./AccountDetailsSpecialities";
import AccountDetailsTmx from "./AccountDetailsTmx";
import AccountDetailsTranslatorTMX from "./AccountDetailsTranslatorTMX";

const Account = ({ profile, isTranslator }) => {
  const location = useLocation();
  const intl = useIntl();
  const showMenu = () => {
    return (
      <Nav tabs>
        <NavItem key="profile">
          <NavLink
            tag={RRNavLink}
            to="/settings/account/profile"
            className={classnames({
              active:
                location.pathname === "/settings/account/profile" ||
                location.pathname === "/settings/account/" ||
                location.pathname === "/settings/account",
            })}
          >
            {/* <h3 className="d-block d-md-none text-center p-0">
              <FontAwesomeIcon icon={faUserCog} />
            </h3> */}

            <span>
              <FormattedMessage id="Account.MyAccount" />
            </span>
          </NavLink>
        </NavItem>
        {isTranslator && (
          <>
            <NavItem key="infoTranslator">
              <NavLink
                tag={RRNavLink}
                to="/settings/account/info-translator"
                className={classnames({
                  active: location.pathname === "/settings/account/info",
                })}
              >
                <span>
                  <FormattedMessage id="Account.InfosTranslator" />
                </span>
              </NavLink>
            </NavItem>
            <NavItem key="infoBilling">
              <NavLink
                tag={RRNavLink}
                to="/settings/account/info-billing"
                className={classnames({
                  active:
                    location.pathname === "/settings/account/info-billing",
                })}
              >
                <span>
                  <FormattedMessage id="Account.SubMenu.MyBilling" />
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                to="/settings/account/language"
                className={classnames({
                  active: location.pathname === "/settings/account/language",
                })}
              >
                <>
                  <span>
                    <FormattedMessage id="Account.SubMenu.Languages" />
                  </span>
                  {profile.canTranslate.length === 0 && (
                    <>
                      <span
                        className="Icon Assistance text-danger d-inline"
                        id="info"
                      ></span>
                      <Tooltips
                        content={intl.formatMessage({
                          id: `Account.NoLanguageSelected`,
                        })}
                        placement="top"
                        target="info"
                        className="danger"
                      />
                    </>
                  )}
                </>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                tag={RRNavLink}
                to="/settings/account/proofreading"
                className={classnames({
                  active:
                    location.pathname === "/settings/account/proofreading",
                })}
              >
                <>
                  <span>
                    <FormattedMessage id="Account.SubMenu.Proofreading" />
                  </span>
                </>
              </NavLink>
            </NavItem>
            {isTranslator && (
              <NavItem key="infoSpec">
                <NavLink
                  tag={RRNavLink}
                  to="/settings/account/specialities"
                  className={classnames({
                    active:
                      location.pathname === "/settings/account/specialities",
                  })}
                >
                  <span>
                    <FormattedMessage id="Account.SubMenu.Spec" />
                  </span>
                </NavLink>
              </NavItem>
            )}
            {isTranslator && (
              <NavItem key="infoTMX">
                <NavLink
                  tag={RRNavLink}
                  to="/settings/account/memory"
                  className={classnames({
                    active:
                      location.pathname === "/settings/account/memory",
                  })}
                >
                  <span>
                    <FormattedMessage id="Account.SubMenu.TMX" />
                  </span>
                </NavLink>
              </NavItem>
            )}
          </>
        )}

        {!isTranslator && (
          <>
            <NavItem key="infoClient">
              <NavLink
                tag={RRNavLink}
                to="/settings/account/info-client"
                className={classnames({
                  active: location.pathname === "/settings/account/info-client",
                })}
              >
                <span>
                  <FormattedMessage id="Account.InfosClient" />
                </span>
              </NavLink>
            </NavItem>
            <NavItem key="invoicesClient">
              <NavLink
                tag={RRNavLink}
                to="/settings/account/invoices"
                className={classnames({
                  active: location.pathname === "/settings/account/invoices",
                })}
              >
                <span>
                  <FormattedMessage id="Account.InvoicesClient" />
                </span>
              </NavLink>
            </NavItem>
            <NavItem key="infoFavoriteTranslators">
              <NavLink
                tag={RRNavLink}
                to="/settings/account/favorite-translators"
                className={classnames({
                  active:
                    location.pathname ===
                    "/settings/account/favorite-translators",
                })}
              >
                <span>
                  <FormattedMessage id="Account.InfosFavoriteTranslators" />
                </span>
              </NavLink>
            </NavItem>
            <NavItem key="infoFavoriteTmx">
              <NavLink
                tag={RRNavLink}
                to="/settings/account/tmx"
                className={classnames({
                  active: location.pathname === "/settings/account/tmx",
                })}
              >
                <span>
                  <FormattedMessage id="Mémoire et glossaire" />
                </span>
              </NavLink>
            </NavItem>
          </>
        )}
      </Nav>
    );
  };

  return (
    <Row>
      <Col>
        {showMenu()}
        <Switch>
          <Route
            exact
            path={[
              "/settings/account",
              "/settings/account/",
              "/settings/account/profile",
            ]}
            component={() => (
              <AccountDetailsProfile
                profile={profile}
                isTranslator={isTranslator}
              />
            )}
          />

          <Route
            path="/settings/account/info-translator"
            exact
            component={() => <AccountDetailsTranslatorInfo profile={profile} />}
          />
          <Route
            path="/settings/account/info-billing"
            exact
            component={() => (
              <AccountDetailsTranslatorBillingInfo profile={profile} />
            )}
          />
          <Route
            path="/settings/account/language"
            exact
            component={() => (
              <AccountDetailsTranslatorCanTranslate profile={profile} />
            )}
          />
          <Route
            path="/settings/account/proofreading"
            exact
            component={() => (
              <AccountDetailsTranslatorProofreading profile={profile} />
            )}
          />
          <Route
            path="/settings/account/specialities"
            exact
            component={() => <AccountDetailsSpecialities profile={profile} />}
          />
          <Route
            path="/settings/account/memory"
            exact
            component={() => <AccountDetailsTranslatorTMX profile={profile} />}
          />
          <Route
            path="/settings/account/info-client"
            exact
            component={() => <AccountDetailsClientInfo profile={profile} />}
          />
          <Route
            path="/settings/account/invoices"
            exact
            component={() => <AccountDetailsClientInvoices profile={profile} />}
          />
          <Route
            path="/settings/account/favorite-translators"
            exact
            component={() => <AccountDetailsMyFavoriteTranslators />}
          />
          <Route
            path="/settings/account/tmx"
            exact
            component={() => <AccountDetailsTmx />}
          />
        </Switch>
      </Col>
    </Row>
  );
};

Account.propTypes = {
  profile: AccountPropType.isRequired,
  isTranslator: PropTypes.bool,
};

export default Account;
