import { isEqual, uniqWith } from "lodash";
import { lang } from "moment-timezone";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";

import { getProfile } from "../../account/selectors";
import { widget } from "../../constants";
import { getLang, settings } from "../../selectors";
import style from "./LanguagePicker.module.scss";
import SelectField from "./SelectField";

// const requiredLanguage = (value) =>
//   value ? undefined : "Order.Source.Warning";
const required = (value) => (value ? undefined : "Language.Destination.Error");
const relectureOptions = ({
  availableLanguagesCouples,
  webSiteLanguage,
  languagesList,
}) => {
  const table1 = availableLanguagesCouples
    .map((lang) => ({
      value: lang.iso6391Name,
      obj: languagesList[webSiteLanguage].filter(
        (l) => l.key === lang.iso6391Name
      ),
    }))
    .map((lang) => ({
      value: lang.value,
      text: lang.obj[0].name,
      order: lang.obj[0].order,
    }))
    .filter((lang) => lang.value !== "ja" && lang.value !== "zh")
    .sort((a, b) => (a.order < b.order ? -1 : 1));
  const table2 = availableLanguagesCouples
    .map((lang) => ({
      value: lang.supportedDestinations,
      obj: languagesList[webSiteLanguage].filter(
        (l) => l.key === lang.supportedDestinations
      ),
    }))
    .map((lang) => ({
      value: lang.value,
      text: lang.obj[0].name,
      order: lang.obj[0].order,
    }))
    .filter((lang) => lang.value !== "ja" && lang.value !== "zh")
    .sort((a, b) => (a.order < b.order ? -1 : 1));
  return uniqWith([...table1, ...table2], isEqual);
};

const sourceOptions = ({
  availableLanguagesCouples,
  webSiteLanguage,
  languagesList,
}) => {
  const table = availableLanguagesCouples
    .filter(
      (lang) => lang.supportedDestinations && lang.supportedDestinations.length
    )
    .map((lang) => ({
      value: lang.iso6391Name,
      obj: languagesList[webSiteLanguage].filter(
        (l) => l.key === lang.iso6391Name
      ),
    }))
    .map((lang) => ({
      value: lang.value,
      text: lang.obj[0].name,
      order: lang.obj[0].order,
    }))
    .sort((a, b) => (a.order < b.order ? -1 : 1));
  return uniqWith(table, isEqual);
};

const destinationOptions = ({
  selectedLanguage,
  availableLanguagesCouples,
  languagesList,
  webSiteLanguage,
}) => {
  let supportedDestinations = [];
  if (selectedLanguage) {
    let table = availableLanguagesCouples
      .filter((lang) => lang.iso6391Name === selectedLanguage)
      .map((lang) => ({
        value: lang.supportedDestinations,
        obj: languagesList[webSiteLanguage].filter(
          (l) => l.key === lang.supportedDestinations
        ),
      }))
      .map((lang) => ({
        value: lang.value,
        text: lang.obj[0].name,
        order: lang.obj[0].order,
      }))
      .sort((a, b) => (a.order < b.order ? -1 : 1));
    return uniqWith(table, isEqual);
  }
  return supportedDestinations;
};

const originOptions = ({
  destinationLanguage,
  availableLanguagesCouples,
  languagesList,
  webSiteLanguage,
}) => {
  let table = [];
  if (destinationLanguage) {
    let table = availableLanguagesCouples
      .filter((lang) => lang.supportedDestinations === destinationLanguage)
      .map((lang) => ({
        value: lang.iso6391Name,
        obj: languagesList[webSiteLanguage].filter(
          (l) => l.key === lang.iso6391Name
        ),
      }))
      .map((lang) => ({
        value: lang.value,
        text: lang.obj[0].name,
        order: lang.obj[0].order,
      }))
      .sort((a, b) => (a.order < b.order ? -1 : 1));
    return uniqWith(table, isEqual);
  }
  return table;
};
const LanguagePicker = ({
  availableLanguagesCouples,
  selectedLanguage,
  destinationLanguage,
  languagesList,
  webSiteLanguage,
  fileDetectedLanguage,
  order,
}) => {
  const [displayChangeLanguage, setDisplayChangeLanguage] = useState(
    !!fileDetectedLanguage
  );

  const onChange = () => {
    setDisplayChangeLanguage(false);
    if (!order.proofreading) {
      order.destinationLanguage = null;
    } else {
      order.selectedLanguage = null;
    }
  };

  useEffect(() => {
    if (fileDetectedLanguage) {
      setDisplayChangeLanguage(true);
    } else {
      setDisplayChangeLanguage(false);
    }
  }, [fileDetectedLanguage]);

  const destinations = destinationOptions({
    selectedLanguage,
    availableLanguagesCouples,
    languagesList,
    webSiteLanguage,
  });
  const origins = relectureOptions({
    destinationLanguage,
    availableLanguagesCouples,
    languagesList,
    webSiteLanguage,
  });
  /* const origins = originOptions({
    destinationLanguage,
    availableLanguagesCouples,
    languagesList,
    webSiteLanguage,
  });*/
  // Pour la traduction : utilisé pour le select du selectedLanguage. Pour la relecture : destinationLanguage
  const detectedLanguage = fileDetectedLanguage
    .split(",")
    .filter((x, i, a) => a.indexOf(x) === i)
    .join();
  const displayDetecteLanguage =
    displayChangeLanguage && detectedLanguage === selectedLanguage;

  // -------------- 1E CASE : display from wordpress website (widget) -------------------
  return widget ? (
    <div>
      {!order.proofreading && (
        <div className="langage-wrapper">
          <span>
            <FormattedMessage id="LangagePicker.From" />
          </span>
          <Field
            component={SelectField}
            name="selectedLanguage"
            options={sourceOptions({
              availableLanguagesCouples,
              webSiteLanguage,
              languagesList,
            })}
          />
          <div className="m-t flex items-center">
            <span className="l-text">
              <FormattedMessage id="LangagePicker.To" />
            </span>
            <Field
              component={SelectField}
              name="destinationLanguage"
              options={destinations}
              showPlaceholder={true}
              disabled={!destinations.length || destinations.length <= 0}
            />
          </div>
        </div>
      )}
      {order.proofreading && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="blue"
            style={{ fontStyle: "italic", fontSize: 16, marginRight: 15 }}
          >
            <span className="l-text">
              <FormattedMessage id="LangagePicker.To" />
            </span>
          </span>
          <Field
            component={SelectField}
            name="selectedLanguage"
            options={origins}
          />
        </div>
      )}
    </div>
  ) : (
    // -------------- 2E CASE : display in the app -------------------
    <div className="section-lang">
      <div>
        {/* // ------- Si traduction :  */}
        {!order.proofreading && (
          <div className="langage-wrapper">
            <span>
              <FormattedMessage id="LangagePicker.From" />
            </span>
            <Field
              component={SelectField}
              name="selectedLanguage"
              options={sourceOptions({
                availableLanguagesCouples,
                webSiteLanguage,
                languagesList,
              })}
            />
            <div className="m-t flex items-center">
              <span className="l-text">
                <FormattedMessage id="LangagePicker.To" />
              </span>
              <Field
                component={SelectField}
                name="destinationLanguage"
                options={destinations}
                showPlaceholder={true}
                disabled={!destinations.length || destinations.length <= 0}
              />
            </div>
          </div>
          // <>
          //   <Col xs="12">
          //     <div className={style.SourceLanguageDetection}>
          //       {displayDetecteLanguage && (
          //         <FormattedMessage
          //           id="Language.Detection"
          //           values={{
          //             language: (
          //               <span className={style.SelectedLng}>
          //                 <FormattedMessage
          //                   id={`Language.${detectedLanguage}`}
          //                 />
          //               </span>
          //             ),
          //           }}
          //         />
          //       )}
          //     </div>
          //   </Col>
          //   <Col
          //     xs={{ size: 6, offset: order.proofreading ? 3 : 0 }}
          //     md={{ size: 4, offset: order.proofreading ? 4 : 1 }}
          //   >
          //     <div
          //       className={style.SourceLanguageDetection}
          //       onChange={onChange}
          //     >
          //       <Field
          //         component={SelectField}
          //         name="selectedLanguage"
          //         label="Widget.Translate.From"
          //         options={sourceOptions({
          //           availableLanguagesCouples,
          //           webSiteLanguage,
          //           languagesList,
          //         })}
          //         showErrorBeforeTouched
          //         showPlaceholder={true}
          //       />
          //     </div>
          //   </Col>
          //   <Col xs={{ size: 6 }} md={{ size: 4, offset: 1 }}>
          //     <div
          //       className={style.SourceLanguageDetection}
          //       onChange={onChange}
          //     >
          //       <Field
          //         component={SelectField}
          //         name="destinationLanguage"
          //         label="Widget.Translate.To"
          //         options={destinations}
          //         showPlaceholder={true}
          //         validate={required}
          //         disabled={!destinations.length || destinations.length <= 0}
          //       />
          //     </div>
          //   </Col>
          // </>
        )}

        {/* // ------- Si relecture :  */}
        {order.proofreading && (
          <>
            <div className={style.SourceLanguageDetection}>
              {displayChangeLanguage && (
                <FormattedMessage
                  id="Language.Detection"
                  values={{
                    language: (
                      <span className={style.SelectedLng}>
                        <FormattedMessage id={`Language.${detectedLanguage}`} />
                      </span>
                    ),
                  }}
                />
              )}
            </div>
            <div
              className={"flex " + style.SourceLanguageDetection}
              onChange={onChange}
            >
              <Field
                component={SelectField}
                name="destinationLanguage"
                label="Proofreading.Read.To"
                options={sourceOptions({
                  availableLanguagesCouples,
                  webSiteLanguage,
                  languagesList,
                })}
                showErrorBeforeTouched
                showPlaceholder={true}
              />
            </div>

            <div
              className={"flex " + style.SourceLanguageDetection}
              onChange={onChange}
            >
              {!!origins.length &&
                (origins.length > 0 ? (
                  <Field
                    component={SelectField}
                    name="selectedLanguage"
                    label="Proofreading.Read.From"
                    options={origins}
                    showErrorBeforeTouched
                    showPlaceholder={true}
                  />
                ) : (
                  <div className={style.DestinationLanguage}>
                    <FormattedMessage
                      id="Language.Destination.Fixed"
                      values={{
                        language: (
                          <FormattedMessage
                            id={`Language.${origins[0].value}`}
                          />
                        ),
                      }}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

LanguagePicker.propTypes = {
  availableLanguagesCouples: PropTypes.arrayOf(
    PropTypes.shape({
      iso6391Name: PropTypes.string.isRequired,
      supportedDestinations: PropTypes.string.isRequired,
    })
  ).isRequired,
};

LanguagePicker.defaultProps = {
  selectedLanguage: null,
  destinationLanguage: null,
};

const mapStateToProps = (state) => ({
  availableLanguagesCouples: settings(state).availableLanguagesCouples,
  languagesList: settings(state).languagesList,
  webSiteLanguage: getProfile(state) ? getProfile(state).lang : getLang(state),
});

export default connect(mapStateToProps)(LanguagePicker);
