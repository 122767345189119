import React from 'react';
import Tooltips from '../components/Tooltips';
import styles from './CanTranslate.module.scss';
import classnames from 'classnames';
import ConfirmationTrigger from './ConfirmationTrigger';
import { FormattedMessage, useIntl } from 'react-intl';

const CanTranslate = ({ languageCouple, fromCanTranslate, languagesList }) => {
  const getLanguageLabel = (key) => {
    const filter = languagesList.filter((item) => item.key === key);
    if (filter.length === 1) {
      return filter[0].name;
    }
    return key;
  };
  const intl = useIntl();
  const removeCouple = () => {
    fromCanTranslate(languageCouple);
  };
  const langs = languageCouple.couple.split('-', 2);
  let itemLanguage;
  if (languageCouple) {
    switch (languageCouple.status) {
      case 'VALIDATED':
        itemLanguage = null;
        break;
      case 'PENDING':
        itemLanguage = (
          <>
            <Tooltips
              content={intl.formatMessage({
                id: `Account.CanTranslate.toBeValidated`,
              })}
              placement="top"
              target={languageCouple.couple}
              className="warning"
            />
          </>
        );
        break;
      default:
        itemLanguage = null;
        break;
    }
  }
  return (
    <div className={styles.containerCanTranslate}>
      <div
        className={classnames(
          styles.languageCouple,
          styles[languageCouple.status]
        )}
        id={languageCouple.couple}
      >
        <span>
          {getLanguageLabel(langs[0])}
          <span className="icon-arrow-right align-middle mx-1 px-0" />
          {getLanguageLabel(langs[1])}
        </span>
        {itemLanguage}
        <ConfirmationTrigger
          title={<FormattedMessage id="Account.CanTranslate.ConfirmRemove" />}
          text={<FormattedMessage id="Account.CanTranslate.AreYouSure" />}
          yes={<FormattedMessage id="Account.CanTranslate.Yes" />}
          no={<FormattedMessage id="Account.CanTranslate.No" />}
          actionYes={removeCouple}
        />
      </div>
    </div>
  );
};

export default CanTranslate;
