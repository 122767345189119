import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import InvoiceLink from '../invoices/InvoiceLink';
import { getInvoices, getInvoicePagination } from '../invoices/selectors';
import { loadInvoices } from '../actions';
import ActionLink from '../components/ActionLink';
import { Container, Row, Col } from 'reactstrap';

class InvoicesPageTranslator extends Component {
  constructor(props) {
    super(props);

    const {
      pagination: { total, limit },
    } = props;
    this.state = {
      pageCount: Math.ceil(total / limit),
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.props.loadInvoices();
  }

  componentWillReceiveProps(nextProps) {
    const {
      pagination: { total, limit },
    } = nextProps;
    const oldPagination = this.props.pagination;

    if (!oldPagination || oldPagination.total !== total) {
      this.setState({ pageCount: Math.ceil(total / limit) });
    }
  }

  handlePageClick(data) {
    const page = data.selected;
    const pageSize = this.props.pagination.limit;

    this.props.loadInvoices({ $skip: page * pageSize });
  }

  render() {
    const { invoices } = this.props;
    const { pageCount } = this.state;

    return (
      <>
        <Row className="header-container">
          <Col className="content-container">
            <Header
              left={<Link to="settings/" className="icon-arrow-left pink" />}
              center={<Link to="/" className="header__icon icon-my-brian" />}
            />
          </Col>
        </Row>

        <Container >
          <div className="content-container body">
            <Row>
              <Col>
                
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="Card">
                  <div className="Title">
                    <FormattedMessage id="Settings.Invoices" />
                  </div>
                  <div className="section-general section-general">
                    {invoices &&
                      Object.keys(invoices).map((index) => (
                        <InvoiceLink key={index} invoice={invoices[index]} />
                      ))}
                    {(!invoices || invoices.length === 0) && (
                      <FormattedMessage id="Invoices.NoInvoice" />
                    )}

                    {pageCount > 1 && (
                      <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={<ActionLink>...</ActionLink>}
                        breakClassName={'break-me'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}
InvoicesPageTranslator.propTypes = {
  invoices: PropTypes.array.isRequired,
};

const stateToProps = (state) => ({
  invoices: getInvoices(state),
  pagination: getInvoicePagination(state),
});

const actionsToProps = (dispatch) => ({
  loadInvoices: (query = {}) => dispatch(loadInvoices(query)),
});

export default connect(stateToProps, actionsToProps)(InvoicesPageTranslator);
