import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUserCog, faHistory,faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";

import Card from "../layout/Card";
import { logout, showChat } from "../actions";
import Header from "../components/Header";

const SettingsPageTranslator = ({ doLogout, doShowChat }) => (
  <>
    <Row className="header-container">
      <Col className="content-container">
        <Header
          left={<Link to="/" className="icon-arrow-left pink" />}
          center={<Link to="/" className="header__icon icon-my-brian" />}
        />
      </Col>
    </Row>

    <Container>
      <div className="content-container body">
        <Row>
          <Col>
            <Card fill faIcon={faCog} title="Settings.General">
              <div className="section-general section-general--grey">
                <Link to="/settings/account" className="section-panel-link">
                  <div>
                    <FontAwesomeIcon
                      icon={faUserCog}
                      style={{ marginRight: "0.5em" }}
                    />
                    <FormattedMessage id="Settings.Account" />
                  </div>
                </Link>
              </div>
              <div className="section-general section-general--grey">
                <Link to="/translator/history" className="section-panel-link">
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      style={{ marginRight: "0.5em" }}
                    />
                    <FormattedMessage id="TranslatorHomePage.History" />
                  </div>
                </Link>
                {/* <Link to="#" className="section-panel-link"><FormattedMessage id="Settings.Application"/></Link> */}
              </div>
            </Card>

            <Card fill faIcon={faCog} title="Settings.General">
              <div className="section-general section-general--grey">
                {/* <Link to="#" className="section-panel-link"><FormattedMessage id="Settings.BankTransfer"/></Link> */}
                <Link to="/translator/invoices" className="section-panel-link">
                  <div>
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      style={{ marginRight: "0.5em" }}
                    />
                    <FormattedMessage id="Settings.Invoices" />
                  </div>
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  </>
);

SettingsPageTranslator.propTypes = {
  doLogout: PropTypes.func.isRequired,
  doShowChat: PropTypes.func.isRequired,
};

const dispatchToProps = (dispatch, ownProps) => ({
  doLogout: () => dispatch(logout({ browserHistory: ownProps.history })),
  doShowChat: () => dispatch(showChat("ADMIN")),
});

export default connect(null, dispatchToProps)(SettingsPageTranslator);
