import { cloneDeep, findIndex, get, set } from "lodash";
import { intlReducer } from "react-intl-redux";
import { reducer as notificationReducer } from "react-notification-system-redux";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import {
  AUTHENTICATION_LOGOUT,
  CHANGE_FILE_COMMENT,
  CHANGE_ORDER,
  CHANGE_ORDER_TEXT,
  CHANGE_ORDER_TIME,
  DEBUG,
  HIDE_CHAT,
  LOAD_LOCAL_ORDER_FULFILLED,
  NOTIFICATIONS_CREATE_RECEIVED,
  ORDERS_LOAD_FULFILLED,
  PATCH_USER_RECEIVED,
  REMOVE_FILE,
  REMOVE_LOCAL_ORDER_FULFILLED,
  SHOW_CHAT,
  UPDATE_USER_FULFILLED,
  UPDATE_USER_RECEIVED,
  UPLOAD,
  UPLOAD_COMPLETE,
  UPLOAD_ERROR,
  UPLOAD_PROGRESS,
} from "./actions";
import lang from "./common/lang";
import { locale } from "./constants";
import { feathersAuthentication, feathersServices } from "./feathers";

const defaultState = {
  app: {
    debug: false,
    newNotification: false,
    showChat: null,
  },
  intl: {
    locale,
    messages: lang[locale],
  },
};

const appStateReducer = (state = defaultState.app, action) => {
  switch (action.type) {
    case DEBUG:
      return {
        ...state,
        debug: action.payload,
      };
    case NOTIFICATIONS_CREATE_RECEIVED:
      return {
        ...state,
        newNotification: true,
      };
    case SHOW_CHAT:
      return {
        ...state,
        showChat: action.payload,
      };
    case HIDE_CHAT:
      return {
        ...state,
        showChat: false,
      };
    default:
      return state;
  }
};

const reducer = combineReducers({
  app: appStateReducer,
  notificationReducer,
  auth: feathersAuthentication.reducer,
  users: feathersServices.users.reducer,
  orders: feathersServices.orders.reducer,
  messages: feathersServices.messages.reducer,
  uploads: feathersServices.uploads.reducer,
  notifications: feathersServices.notifications.reducer,
  settings: feathersServices.settings.reducer,
  companies: feathersServices.companies.reducer,
  invoices: feathersServices.invoices.reducer,
  form: formReducer,
  intl: intlReducer,
});

/*
 * rootReducer overrides the default behaviour of some of the 'managed' reducers
 */
const rootReducer = (state = defaultState, action) => {
  let newState;
  switch (action.type) {
    case AUTHENTICATION_LOGOUT:
      newState = defaultState;
      break;
    case ORDERS_LOAD_FULFILLED:
      console.log("ici")
    case LOAD_LOCAL_ORDER_FULFILLED:
      const data = action.payload;
      !!data.wordsNumber
        ? (data.wordsNumber *= 1)
        : (data.wordsNumber = undefined);
      if (data.wordsNumber >= 0) data.words = data.wordsNumber;
      data.words *= 1;
      newState = { ...set(state, "orders.data", data) };
      break;
    case REMOVE_LOCAL_ORDER_FULFILLED:
      newState = { ...set(state, "orders.data", null) };
      break;
    case UPDATE_USER_RECEIVED:
    case UPDATE_USER_FULFILLED:
      newState = cloneDeep(set(state, "auth.user", action.payload));
      break;
    case PATCH_USER_RECEIVED:
      newState = { ...set(state, "auth.user", action.payload) };
      break;
    case UPLOAD: {
      const uploads = [...get(state, "orders.data.attachments")];
      uploads.push(action.payload);
      newState = { ...set(state, "orders.data.attachments", uploads) };
      break;
    }
    case UPLOAD_PROGRESS: {
      const { name, percent, nonce } = action.payload;
      const uploads = [...get(state, "orders.data.attachments", [])];
      const fileIndex = findIndex(uploads, { name, nonce });
      if (fileIndex > -1) {
        newState = {
          ...set(
            state,
            `orders.data.attachments[${fileIndex}].percent`,
            percent
          ),
        };
      }
      break;
    }
    case UPLOAD_ERROR: {
      const { name, error, nonce } = action.payload;
      const uploads = [...get(state, "orders.data.attachments", [])];
      const fileIndex = findIndex(uploads, { name, nonce });
      console.log(fileIndex);
      const attachment = {
        ...get(state, `orders.data.attachments[${fileIndex}]`),
        percent: undefined,
        error,
      };
      const newAttach = uploads.filter((f, i) => i !== fileIndex);
      if (fileIndex > -1) {
        newState = cloneDeep(set(state, `orders.data.attachments`, newAttach));
      }
      break;
    }
    case UPLOAD_COMPLETE: {
      const {
        name,
        words,
        nonce,
        path,
        detectedLanguage,
        lang,
        specialityId,
        redundancy,
      } = action.payload;
      const uploads = [...get(state, "orders.data.attachments", [])];
      const fileIndex = findIndex(uploads, { name, nonce });
      console.log(lang);
      if (fileIndex > -1) {
        const attachment = {
          ...get(state, `orders.data.attachments[${fileIndex}]`),
          percent: undefined,
          words,
          path,
          lang,
          redundancy,
          uniqueWords: words * (1 - redundancy / 100),
        };

        newState = cloneDeep(
          set(state, `orders.data.attachments[${fileIndex}]`, attachment)
        );

        const attachments = newState.orders?.data?.attachments?.filter(
          (a) => a.type === "client"
        );

        if (attachments?.length > 0) {
          const average =
            attachments.reduce(
              (total, next) => total + (next?.redundancy ?? 0),
              0
            ) / (attachments.length ?? 1);

          const uniqueWords = attachments.reduce(
            (total, next) => total + (next?.uniqueWords ?? 0),
            0
          );
          console.log(uniqueWords);

          newState = {
            ...set(newState, "orders.data.redundancyAverage", average),
          };
          newState = {
            ...set(newState, "orders.data.uniqueWords", uniqueWords),
          };
        }

        if (detectedLanguage) {
          newState = {
            ...set(newState, "orders.data.detectedLanguage", detectedLanguage),
          };
        }
        if (specialityId)
          newState = {
            ...set(newState, "orders.data.specialityId", specialityId),
          };
      }
      break;
    }
    case REMOVE_FILE: {
      const { file } = action.payload;
      const attachments = get(state, "orders.data.attachments", []);
      const index = attachments.map((a) => a.path).indexOf(file);
      attachments.splice(index, 1);
      newState = cloneDeep(set(state, "orders.data.attachments", attachments));
      break;
    }
    case CHANGE_ORDER_TIME:
      newState = { ...set(state, "orders.data.time", action.payload) };
      break;
    case CHANGE_ORDER_TEXT:
      newState = { ...set(state, "orders.data.text", action.payload) };
      break;
    case CHANGE_FILE_COMMENT: {
      const { name, comment } = action.payload;
      const uploads = [...get(state, "orders.data.attachments")];
      const fileIndex = findIndex(uploads, { name });
      const attachment = {
        ...get(state, `orders.data.attachments[${fileIndex}]`),
        comment,
      };
      if (fileIndex > -1) {
        newState = {
          ...set(state, `orders.data.attachments[${fileIndex}]`, attachment),
        };
      }
      break;
    }
    case CHANGE_ORDER: {
      const order = { ...get(state, "orders.data", {}), ...action.payload };
      !!order.wordsNumber
        ? (order.wordsNumber *= 1)
        : (order.wordsNumber = undefined);
      if (order.wordsNumber >= 0) order.words = order.wordsNumber;
      order.words *= 1;
      newState = { ...set(state, "orders.data", order) };
      break;
    }
    default:
      newState = state;
      break;
  }
  return reducer(newState, action);
};

export default rootReducer;
