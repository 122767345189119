import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormSpy } from 'react-final-form';
import { isEqual } from 'lodash';
import { changeOrder } from '../actions';

import OrderPropType from './../common/proptypes/OrderPropType';

const handleFormChanges = (event, order, doChangeOrder) => {
  // ignore events when a field is active as it causes a loop between setting
  // and displaying content

  // Exception is the time slider as we want pricing to update while active
  if (
    (!event.active || event.active === 'time') &&
    !isEqual(order, event.values)
  ) {
    // Need to normalise the attachments array as event.values won't contain new attachments
    const updatedOrder = { ...order, ...event.values };
    updatedOrder.attachments = order.attachments || [];
    updatedOrder.attachments.forEach((attachment, index) => {
      if (event.values.attachments[index]) {
        updatedOrder.attachments[index] = event.values.attachments[index];
      }
    });
    doChangeOrder(updatedOrder);
  }
};

const OrderFormSpy = ({ order, doChangeOrder }) => (
  <FormSpy
    subscription={{ values: true, active: true }}
    onChange={(event) => handleFormChanges(event, order, doChangeOrder)}
  />
);

OrderFormSpy.propTypes = {
  order: OrderPropType.isRequired,
  doChangeOrder: PropTypes.func.isRequired,
};

const actionsToProps = {
  doChangeOrder: changeOrder,
};

export default connect(null, actionsToProps)(OrderFormSpy);
