import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import ActionLink from './../components/ActionLink';

class AppStatusTranslator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      more: false,
    };
    this.toggleMore = this.toggleMore.bind(this);
  }

  toggleMore() {
    this.setState({ more: !this.state.more });
  }

  render() {
    const { isLoading, isError } = this.props;

    if (isLoading) {
      return (
        <div className="loading-message">
          <FormattedMessage id="Loading" />
        </div>
      );
    }
    if (isError && isError.name) {
      const { name, message } = isError;
      return (
        <Row className="content-container">
          <Col className="body">
            <div className="section-general">
              <h1>
                <FormattedMessage id={`Error.${name}`} />
              </h1>
              <p>
                <FormattedMessage id={`Error.${message}`} />
              </p>
              <ActionLink
                onClick={this.toggleMore}
                className={`pink js-more-toggle more ${
                  this.state.more ? 'more--open' : ''
                }`}
              >
                <FormattedMessage id="Order.View.MoreInfo" />
              </ActionLink>
              <div
                className={`js-more-content ${
                  this.state.more ? 'js-more-content--show' : ''
                }`}
              >
                <p>
                  <FormattedMessage id={message} />
                </p>
              </div>
            </div>

            <div className="section-general section-general--grey section-general--no-border">
              <Link to="/" className="button">
                <FormattedMessage id="Home" />
              </Link>
            </div>
          </Col>
        </Row>
      );
    }

    return null;
  }
}

AppStatusTranslator.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.shape({
    message: PropTypes.string,
    name: PropTypes.string,
  }),
};

AppStatusTranslator.defaultProps = {
  isLoading: false,
  isError: {
    message: null,
    name: null,
  },
};

export default AppStatusTranslator;
