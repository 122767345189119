import React, { Component } from "react";
import getParameterByName from "../utils/getParameterByName";

const stripe = window.Stripe(process.env.REACT_APP_STRIPE_TOKEN);

class MobilePaymentPage extends Component {
  componentDidMount() {
    const token = getParameterByName("token");
    if (token) {
      stripe.redirectToCheckout({ sessionId: token });
    }
  }

  render() {
    return (
      <div className="body">
        
        <div className="section-textarea" />
      </div>
    );
  }
}

export default MobilePaymentPage;
