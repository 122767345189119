// Switch between reporting to console or a sentry.io issue, based on if DSN is set

import * as Sentry from '@sentry/browser';

if (process.env.REACT_APP_SENTRY_CLIENT_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_CLIENT_DSN });
}

const errorReporter = process.env.REACT_APP_SENTRY_CLIENT_DSN
  ? Sentry
  : {
    withScope: (scope) => {
      console.log('FAKE SENTRY withScope', { scope });
    },
    captureException: (exception) => {
      console.log('FAKE SENTRY captureException', { exception });
    },
  };

export default errorReporter;
