import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

import ActionLink from "../components/ActionLink";
import { widget } from "../constants";
import ClickableDiv from "../layout/ClickableDiv";
import serverLink from "../utils/ServerLink";
import styles from "./Attachment.module.scss";
import Comment from "./AttachmentComment";
import AttachmentPropType from "./../common/proptypes/AttachmentPropType";
import Thumbnail from "./Thumbnail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileWord } from "@fortawesome/free-regular-svg-icons";
import { faCog, faSpinner } from "@fortawesome/free-solid-svg-icons";

/* global window */

const Attachment = ({
  attachment: { path, name, percent, error, words, comment },
  onRemove,
  index,
  readonly,
  showRemove,
  link,
  type,
}) => {
  return (
    <ClickableDiv
      onClick={link ? () => window.open(serverLink(path), "_blank") : null}
    >
      {/* {!widget && (
      <div
        className={classnames({
          [styles.Description]: true,
          [styles.Error]: error,
        })}
      >
        {!error && <Thumbnail file={name} />}
        <Comment
          error={error}
          readonly={readonly}
          comment={comment}
          index={index}
        />
      </div>
    )} */}

      <div
        className={styles.Info}
        // style={{ background: backgroundStyle(percent) }}
      >
        {/* <FontAwesomeIcon
          style={{ marginRight: 15 }}
          icon={faFileWord}
          fontSize="20px"
        /> */}
        <div style={{ marginRight: 10 }}>
          <Thumbnail file={name} />
        </div>
        <div
          className={classnames({
            [styles.FileName]: true,
            [styles.Uploading]: percent && percent !== 100,
          })}
        >
          {name}
        </div>

        {words ? (
          <div className={styles.Words}>
            <FormattedMessage
              id="Order.Words"
              defaultMessage={
                "{words, number} {words, plural, one {word} other {words}}"
              }
              values={{ words }}
            />
          </div>
        ) : null}
        {backgroundStyle(percent) && (
          <FontAwesomeIcon
            icon={faSpinner}
            title={"Loading"}
            className={"fa-spin"}
          />
        )}
        {((path && (!readonly || showRemove)) || error) && (
          <ActionLink className={styles.Remove} onClick={onRemove}>
            <span className="icon-bin" />
          </ActionLink>
        )}
      </div>
    </ClickableDiv>
  );
};

Attachment.propTypes = {
  attachment: AttachmentPropType.isRequired,
  onRemove: PropTypes.func,
  readonly: PropTypes.bool,
  link: PropTypes.bool,
};

Attachment.defaultProps = {
  readonly: false,
  link: false,
  onRemove: null,
};

const backgroundStyle = (percent) =>
  percent !== undefined
    ? `linear-gradient(90deg, rgba(255,255,255,0) 0%, #ff5f6b ${
        percent + 10
      }%,  rgba(255,255,255,0) ${percent + 10}%)`
    : null;

export default Attachment;
