import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactTooltip from "react-tooltip";

const CustomTooltip = ({ children, id }) => {
  return (
    <div style={{ marginLeft: 15 }}>
      <div className="tooltipIcon" data-tip data-for={id}>
        <FontAwesomeIcon icon={faInfoCircle} fontSize="20px" />
      </div>
      <ReactTooltip place="right" arrowColor="transparent" className="customTheme" id={id}>{children}</ReactTooltip>
    </div>
  );
};

export default CustomTooltip;
