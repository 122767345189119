import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';

const SplitPaneTranslator = ({ left, right }) => (
  <Row className="flex-md-row-reverse">
    <Col md="6" sm="12">
      {right}
    </Col>
    <Col md="6" sm="12">
      {left}
    </Col>
  </Row>
);

SplitPaneTranslator.propTypes = {
  left: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
};

export default SplitPaneTranslator;
