import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import errorReporter from '../sentry';
import style from './ErrorHandler.module.scss';
import ActionLink from './ActionLink';

class ErrorHandler extends Component {
  state = {
    error: null,
    errorReported: false,
    friendlyMessage: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    errorReporter.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      errorReporter.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div className={style.Container}>
          <div className={style.ErrorBox}>
            <div className={style.Logo}>
              <span className="icon-my-brian" />
            </div>
            <div className={style.ErrorMessage}>
              <h2><FormattedMessage id="Error.Error" /></h2>
              <p><FormattedMessage id="Error.Apology" /></p>
              <p><Link to="/" onClick={() => this.setState({ error: null })}><FormattedMessage id="Error.GoHome" /></Link></p>
              <p><ActionLink className={style.ErrorReporter} onClick={() => errorReporter.showReportDialog()}><FormattedMessage id="Error.ProvideDetail" /></ActionLink></p>
            </div>
          </div>

        </div>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorHandler;
