import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import CheckboxField from "../components/formfields/CheckboxField";
import Card from "../layout/Card";
import { getLang, getSettingsSpecialities, settings } from "../selectors";
import {
  faGraduationCap,
  faSpellCheck,
} from "@fortawesome/free-solid-svg-icons";

import { updateUser } from "./../actions";
import AccountPropType from "./../common/proptypes/AccountPropType";
import { UncontrolledSelectField } from "../components/formfields/SelectField";
import { getProfile } from "./selectors";
import NewButton from "../components/NewButton";

const ExpertiseBox = ({ name, status }) => {
  return (
    <div
      className="expertiseBox"
      style={{
        border: status === "VALIDATED" ? "1px solid green" : "1px solid orange",
      }}
    >
      {name}
    </div>
  );
};

const AccountDetailsSpecialities = ({
  profile,
  doUpdateUser,
  settingsSpecialities,
  userLang,
}) => {
  const expertiseNotSet = useMemo(() => {
    const res = [];
    settingsSpecialities.forEach((s) => {
      if (profile.expertise?.find((e) => e.key === s.key)) {
        return;
      } else {
        res.push(s.key);
      }
    });
    return res;
  }, [profile.expertise]);

  const [selectedExpertise, setSelectedExpertise] = useState();
  //expertiseNotSet[0]
  // Only destination languages (l[1]) are token.

  const getFormattedSpecialities = () => {
    const tmp = settingsSpecialities.filter(
      (s) => !profile.expertise.find((e) => e.key === s.key)
    );
    return tmp.map((spe) => ({
      value: spe.key,
      text: spe.speciality[userLang],
    }));
  };

  const addExpertise = () => {
    if (selectedExpertise) {
      const newExp = [
        ...profile.expertise,
        { key: selectedExpertise, status: "VALIDATED" },
      ];
      const user = { ...profile, expertise: newExp };
      doUpdateUser({ user });
    }
  };

  const validatedExpertise = useMemo(() => {
    return profile.expertise?.filter((e) => e.status === "VALIDATED");
  }, [profile.expertise]);

  const pendingExpertise = useMemo(() => {
    return profile.expertise?.filter((e) => e.status === "PENDING");
  }, [profile.expertise]);

  return (
    <Card fill title="Account.SubMenu.Spec" faIcon={faGraduationCap}>
      {validatedExpertise?.length > 0 && (
        <p>
          <FormattedMessage id="Speciality.Waiting.Confirm" />
        </p>
      )}
      <div className="flex wrap">
        {validatedExpertise?.map((v) => (
          <ExpertiseBox
            name={
              settingsSpecialities.find((s) => s.key === v.key)?.speciality[
                userLang
              ]
            }
            status={v.status}
          />
        ))}
      </div>
      {pendingExpertise?.length > 0 && (
        <p style={{ marginTop: 10 }}>
          <FormattedMessage id="Speciality.Waiting.Pending" />
        </p>
      )}
      <div className="flex wrap">
        {pendingExpertise?.map((v) => (
          <ExpertiseBox
            name={
              settingsSpecialities.find((s) => s.key === v.key)?.speciality[
                userLang
              ]
            }
            status={v.status}
          />
        ))}
      </div>
      <p style={{ marginTop: 10 }}>
        <FormattedMessage id="Speciality.Add" />
      </p>
      <p>
        <UncontrolledSelectField
          input={{
            onChange: (e) => setSelectedExpertise(e.target.value),
            value: selectedExpertise,
            style: { maxWidth: 250 },
          }}
          options={getFormattedSpecialities()}
          placeholder={"Forms.Choose"}
          showPlaceholder
        />
      </p>
      <button onClick={addExpertise} className="button-green ">
        <FormattedMessage id="Account.AddCoupleOfTranslations" />
      </button>
      {/* <Row>
                  <Col xs="12" className="my-3">
                    <p className="control-label">
                      <FormattedMessage id="Account.translatorProofreading.explanations" />
                    </p>
                    <p className="control-label">
                      <FormattedMessage id="Account.translatorProofreading.explanations2" />
                    </p>
                    <ul>
                      {proofreadingLanguages &&
                        proofreadingLanguages.map((language) => (
                          <li key={language.name}>{language.name}</li>
                        ))}
                    </ul>
                  </Col>
                  <Col xs="12" className="my-3">
                    <Field
                      component={CheckboxField}
                      name="proofreading"
                      type="checkbox"
                      customLabel={
                        <FormattedMessage id="Account.translatorProofreading.label2" />
                      }
                      className="align-self-center"
                    />
                  </Col>
                  <Col xs="12" className="my-3">
                    <p className="control-label">
                      <FormattedMessage id="Account.translatorProofreading.explanations3" />
                    </p>
                  </Col>
                </Row> */}
    </Card>
  );
};

AccountDetailsSpecialities.propTypes = {
  profile: AccountPropType.isRequired,
  doUpdateUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  languagesList: settings(state).languagesList,
  settingsSpecialities: getSettingsSpecialities(state),
  userLang: getProfile(state) ? getProfile(state).lang : getLang(state),
});

const mapDispatchToProps = {
  doUpdateUser: updateUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsSpecialities);
