import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get } from "lodash";
import AccountPropType from "./../../common/proptypes/AccountPropType";
import MessagePropType from "./../../common/proptypes/MessagePropType";
import IntlPropType from "./../../common/proptypes/IntlPropType";

import { getUser } from "../../account/selectors";
import {
  getMessages,
  getIsMessageSaving,
  getIsMessageLoading,
} from "./selectors";
import { feathersServices } from "../../feathers";
import ChatMessage from "./ChatMessage";

const Chat = (props) => {
  const {
    orderId,
    sendMessage,
    sender,
    messages,
    isSaving,
    isLoading,
    intl,
  } = props;
  const [messageInput, setMessageInput] = useState(null);

  const anyMessages = get(messages, "length", isSaving);

  const translatedPlaceholder = intl.formatMessage({ id: "Chat.Text" });
  const myMessage = (user) => user === sender;
  let bottomOfChat = useRef();
  useEffect(() => {
    bottomOfChat.current.scrollIntoView();
  }, [messages]);

  const onChange = (e) => {
    setMessageInput(e.target.value);
  };

  const submitOnEnter = (e) => {
    if (e.keyCode === 13) {
      if (e.ctrlKey || e.metaKey || e.shiftKey) {
        setMessageInput(`${e.target.value}\n`);
      } else {
        e.preventDefault();
        doSend();
      }
    }
  };

  const doSend = () => {
    const message = {
      order: orderId !== "ADMIN" ? orderId : null,
      text: messageInput,
      sender,
    };
    if (message.text) {
      setMessageInput("");
      sendMessage(message);
    }
  };

  return (
    <div className="chat">
      <div className="chat__flux">
        {anyMessages ? (
          messages.map((message, index) => (
            <ChatMessage
              key={index}
              text={message.text}
              myMessage={myMessage(message.sender)}
              senderName={
                message.sender ? message.senderDetails.givenName : "Admin"
              }
              senderPhoto={
                message.sender ? message.senderDetails.picture : null
              }
              time={message.createdAt}
            />
          ))
        ) : (
          <p>
            <FormattedMessage id={isLoading ? "Loading" : "Chat.NoMessages"} />
          </p>
        )}
        <span ref={(el) => (bottomOfChat.current = el)} />
      </div>
      <form
        className="chat__typing"
        onSubmit={(e) => {
          e.preventDefault();
          doSend();
        }}
      >
        <div className="chat__field">
          <TextareaAutosize
            id="chat"
            minRows={1}
            maxRows={3}
            type="text"
            onChange={onChange}
            onKeyDown={submitOnEnter}
            value={messageInput}
            placeholder={translatedPlaceholder}
          />
        </div>
      </form>
    </div>
  );
};

Chat.propTypes = {
  orderId: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(MessagePropType),
  sender: AccountPropType.isRequired,
  isSaving: PropTypes.bool,
  isLoading: PropTypes.bool,
  intl: IntlPropType.isRequired,
  sendMessage: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  messages: null,
  isSaving: false,
  isLoading: false,
};

const stateToProps = (state, ownProps) => ({
  sender: getUser(state),
  messages: getMessages(state, ownProps.orderId),
  isLoading: getIsMessageLoading(state),
  isSaving: getIsMessageSaving(state),
});

const actionsToProps = (dispatch) => ({
  // loadMessages: order => dispatch(feathersServices.messages.find({ query: { order } })),
  sendMessage: (message) => dispatch(feathersServices.messages.create(message)),
});

export default injectIntl(connect(stateToProps, actionsToProps)(Chat));
