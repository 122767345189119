import React, { useEffect, useState } from "react";
import Uploader from "../orders/Uploader";
import styles from "./AccountDetailsTmx.module.scss";
import app from "../feathers";
import Flag from "../components/Flag";
import CustomTooltip from "../components/CustomTooltip";
import { FormattedMessage } from "react-intl";

const AccountDetailsTmx = () => {
  const [myTMX, setMyTMX] = useState([]);

  const downloadAllTMX = async () => {
    for (let i = 0; i < myTMX.length; i++) {
      window.open(
        `${process.env.REACT_APP_API_URL}/tmx/${myTMX[i].srclang}_${myTMX[i].destlang}_${myTMX[i].clientid}.tmx`,
        "_blank"
      );
      await new Promise((r) => setTimeout(r, 500));
    }
  };

  const fetchMyTMX = async () => {
    try {
      const { data } = await app.service("tmx").find();
      setMyTMX(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMyTMX();
  }, []);

  console.log(myTMX);

  return (
    <div style={{ marginTop: 20 }}>
      <div className={styles.card}>
        <div className="flex items-center space-between">
          <p className={styles.title}>
            <FormattedMessage id="Profile.TMX.Import" />
          </p>
          <CustomTooltip id="TMX-import">
            <FormattedMessage id="Profile.TMX.Tooltip" />
          </CustomTooltip>
        </div>
        <Uploader
          loader
          type="reference"
          tmx={true}
          overrideUpload={fetchMyTMX}
        />
      </div>
      <div className={styles.card}>
        <div className="flex items-center space-between">
          <p className={styles.title}>
            <FormattedMessage id="Profile.TMX.List" />
          </p>
          <a
            className="pink"
            onClick={(e) => {
              e.preventDefault();
              downloadAllTMX();
            }}
          >
            <FormattedMessage id="Profile.TMX.DownloadAll" />
          </a>
        </div>
        {myTMX?.map((tmx) => (
          <div
            key={tmx.clientid + tmx.destlang + tmx.srclang}
            className="flex items-center justify-between flex-wrap"
            style={{
              padding: 20,
              backgroundColor: "white",
              borderRadius: 15,
              marginBottom: 10,
            }}
          >
            <div className="flex items-center flex-wrap">
              <Flag country={tmx.srclang} includeText />
              <div
                className="blue"
                style={{ fontSize: 30, margin: "0px 20px" }}
              >
                {">"}
              </div>
              <Flag country={tmx.destlang} includeText />
              <div style={{ marginLeft: 30 }}><FormattedMessage id="Profile.TMX.Date"/> {new Date(tmx.updatedat).toLocaleDateString("fr") }</div>
            </div>
            <a
              className="pink"
              href={`${process.env.REACT_APP_API_URL}/tmx/${tmx.srclang}_${tmx.destlang}_${tmx.clientid}.tmx`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="Profile.TMX.Download" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountDetailsTmx;
