import './style/bootstrap-custom.scss';
import './style/style.scss';
import "./layout/Card.module.scss";

import { Capacitor } from '@capacitor/core';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import moment from 'moment-timezone';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-intl-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { loadSettings, startLogin } from './actions';
import ErrorHandler from './components/ErrorHandler';
import * as constants from './constants';
import cookie from './cookie';
import App from './pages/App';
import configureStore from './store';
import { initGoogleAnalytics } from './utils/analyticsHelpers';
import { socket } from './feathers';

const IosTheme = React.lazy(() => import('./components/IosTheme.js'));

const ThemeSelector = ({ children }) => {
  const platformTheme = Capacitor.getPlatform();
  return (
    <>
      <React.Suspense fallback={<></>}>
        {platformTheme === 'ios' && <IosTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

// pages
initializeFileTypeIcons();

moment.locale(constants.locale, constants.localeFormatTweaks[constants.locale]);

const store = configureStore();

const socks = socket.connect();
socks.on('connect', function () {
  store.dispatch(loadSettings());
  if (
    (cookie.getItem('feathers-jwt') || localStorage.getItem('feathers-jwt')) &&
    !(window.location.pathname === '/mobilepayment')
  ) {
    console.log('Starting login');
    store.dispatch(startLogin({ browserHistory: [] }));
  }

  function run() {
    render(
      <Provider store={store}>
        <Router>
          <LastLocationProvider>
            <ErrorHandler>
              <ThemeSelector>
                <Route path="/" component={App} />
              </ThemeSelector>
            </ErrorHandler>
          </LastLocationProvider>
        </Router>
      </Provider>,
      document.getElementById('root')
    );
    initGoogleAnalytics('106826161', '1');
  }

  run();
});
