import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { updateOrder } from '../actions';
import StarsFieldTranslator from '../components/formfields/StarsFieldTranslator';
import { getCurrentOrder } from '../orders/selectors';
import OrderPropType from './../common/proptypes/OrderPropType';

const required = (value) =>
  value ? undefined : <FormattedMessage id="Forms.Required" />;

const doSubmit = (event, order, doUpdateOrder, history) => {
  const updatedOrder = { ...order, translatorRating: event.translatorRating };

  doUpdateOrder({ order: updatedOrder, history });
};

const RatingModal = ({ order, doUpdateOrder, backFromRatingModal }) => {
  const history = useHistory();
  return (
    <div className="m-3">
      <Form
        initialValues={{ ...order }}
        onSubmit={(event) => doSubmit(event, order, doUpdateOrder, history)}
      >
        {({ handleSubmit, submitting, error, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            {/* -------------------------- 1 ------------------------ */}
            <Row>
              <Col>
                <h4 className="text-center">
                  <FormattedMessage id="Translator.Order.Rating.Difficulty" />
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="p-2 text-center">
                <Field
                  name="translatorRating.Content"
                  component={StarsFieldTranslator}
                  validate={required}
                />
              </Col>
            </Row>

            {/* -------------------------- 2 ------------------------ */}
            <Row>
              <Col>
                <h4 className="text-center">
                  <FormattedMessage id="Translator.Order.Rating.Money" />
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="p-2 text-center">
                <Field
                  name="translatorRating.Money"
                  component={StarsFieldTranslator}
                  validate={required}
                />
              </Col>
            </Row>

            {/* -------------------------- 3 ------------------------ */}
            <Row>
              <Col>
                <h4 className="text-center">
                  <FormattedMessage id="Translator.Order.Rating.Deadline" />
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="p-2 text-center">
                <Field
                  name="translatorRating.Deadline"
                  component={StarsFieldTranslator}
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col className="p-2 text-center">
                <button
                  disabled={submitting || error || hasValidationErrors}
                  type="submit"
                  className="button button--inline button--green"
                  //onClick={() => backFromRatingModal()}
                >
                  <FormattedMessage id="TranslationPage.Submit" />
                </button>
              </Col>
            </Row>
          </form>
        )}
      </Form>
    </div>
  );
};

RatingModal.propTypes = {
  order: OrderPropType.isRequired,
  doUpdateOrder: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  order: getCurrentOrder(state),
});

const dispatchToProps = {
  doUpdateOrder: updateOrder,
};
export default connect(stateToProps, dispatchToProps)(RatingModal);
