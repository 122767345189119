import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';

// TODO: Old deprecated class : to be replaced by the following one 

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      time: null,
    };
    this.tick = this.tick.bind(this);
  }

  componentWillMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    if (this.props.time) {
      const newTime = moment(this.props.time).diff(moment());

      if (newTime < 0) {
        if (this.props.atZero) {
          this.props.atZero();
        }
      } else {
        this.setState({
          time: newTime,
        });
      }
    }
  }

  render() {
    const { time } = this.state;
    if (!time) return null;
    const totalSeconds = time / 1000;
    let seconds = parseInt(totalSeconds % 60, 10);
    let minutes = parseInt(totalSeconds / 60, 10) % 60;
    let hours = parseInt(totalSeconds / 3600, 10);

    seconds = seconds < 10 ? `0${seconds}` : seconds;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    hours = hours < 10 ? `0${hours}` : hours;
    return (
      <span>{hours !== '00' && `${hours}:`}{minutes}:{seconds}</span>
    );
  }
}

Countdown.propTypes = {
  time: PropTypes.object.isRequired,
  atZero: PropTypes.func,
};

Countdown.defaultProps = {
  atZero: () => { }
};

export default Countdown;

// New function : should replace the one above (not tested yet)
// const Countdown = ({ time, atZero }) => {
//   const [newTime, setNewTime] = useState(null);
//   const timerID = setInterval(() => tick(), 1000);

//   useEffect(() => {
//     return () => {
//       clearInterval(timerID);
//     }
//   }, [timerID]);

//   const tick = () => {
//     if (time) {
//       let tempTime = moment(time).diff(moment());

//       if (tempTime < 0) {
//         if (atZero) {
//           atZero();
//         }
//       } else {
//         setNewTime(tempTime);
//       }
//     }
//   }

//   let customTimer = null;
//   if (newTime) {
//     const totalSeconds = newTime / 1000;
//     let seconds = totalSeconds % 60;
//     let minutes = (totalSeconds / 60) % 60;
//     let hours = totalSeconds / 3600;

//     const secondsString = seconds < 10 ? `0${seconds}` : seconds;
//     const minutesString = minutes < 10 ? `0${minutes}` : minutes;
//     const hoursString = hours < 10 ? `0${hours}` : hours;
//     customTimer = <span>{hoursString !== '00' && `${hoursString}:`}{minutesString}:{secondsString}</span>
//   }

//   return (
//     customTimer
//   );
// }

// Countdown.propTypes = {
//   time: PropTypes.object.isRequired,
//   atZero: PropTypes.func,
// };

// Countdown.defaultProps = {
//   atZero: () => { }
// };
// export default Countdown;
