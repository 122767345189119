import moment from "moment-timezone";
import { timezone } from "../constants";
import { settings, getDayEnd, getDayStart, getWeekend } from "../selectors";
import { getOrders } from "./selectors";

/**
 * Gets the number of hours in the date, from the state object
 * @param state
 * @returns {*}
 */
function getHoursInDay(state) {
  const startOfDay = moment.tz(timezone).set(getDayStart(state));
  const endOfDay = moment.tz(timezone).set(getDayEnd(state));

  return moment.duration(endOfDay.diff(startOfDay)).asHours();
}

function round(duration, by, method) {
  return moment.duration(Math[method](+duration / +by) * +by);
}

/**
 * Adds time, taking in consideration for business hours
 * @param state - state, for settings
 * @param time - the start time
 * @param amount - the amount to add
 * @returns {*}
 */
export const addBusinessHours = (state, time = null, amount = null) => {
  time = time ? moment.tz(time, timezone) : moment.tz(timezone);

  // time.startOf('minute'); // we're not going to care about seconds yet
  const startOfDay = time.clone().set(getDayStart(state));
  let endOfDay = time.clone().set(getDayEnd(state));

  const skipWeekend = !getWeekend(state);

  const hoursInDay = moment.duration(endOfDay.diff(startOfDay)).asHours();

  // Move out of the weekend
  if (time.isoWeekday() >= 6 && skipWeekend) {
    time = time.add({ days: 8 - time.isoWeekday() });
  }

  // Normalise the start time to French working hours
  if (time.isBefore(startOfDay)) {
    time = startOfDay;
  }
  if (time.isSameOrAfter(endOfDay)) {
    time = startOfDay.clone().add({ days: 1 });
    if (time.isoWeekday() >= 6 && skipWeekend) {
      time = time.add({ days: 8 - time.isoWeekday() });
    }
  }
  // Now add the time to it, and check the hours again

  if (amount) {
    amount = moment.duration(amount);
    while (amount.asHours() >= hoursInDay) {
      time.add({ days: 1 });
      if (time.isoWeekday() >= 6 && skipWeekend) {
        time = time.add({ days: 8 - time.isoWeekday() });
      }
      amount.subtract({ hours: hoursInDay });
    }
    time.add(amount);
  }

  endOfDay = time.clone().set(getDayEnd(state));

  if (time.isAfter(endOfDay)) {
    time = addBusinessHours(state, time, moment.duration(time.diff(endOfDay)));
  }
  // If it's a weekend, add some days.
  if (time.isoWeekday() >= 6 && skipWeekend) {
    time = time.add({ days: 8 - time.isoWeekday() });
  }

  return time;
};

export const getEarliestTime = (state, words, time = null, proofreading) => {
  // combine the start delay and the time it takes to translate depending on proofreading or not(delay are differents)

  let duration = moment.duration({
    hours: words * +settings(state).extendBy + +settings(state).searchTime,
  });

  if (proofreading) {
    duration = moment.duration({
      hours:
        words * +settings(state).proofreadingExtendBy +
        +settings(state).proofreadingSearchTime,
    });
  }

  // round up to the nearest half hour and fit them into business time
  return addBusinessHours(
    state,
    time,
    round(duration, moment.duration(30, "minutes"), "ceil")
  );
};

export const getMaxDuration = (state, words, proofreading) => {
  let hours =
    words > +settings(state).extendAfter
      ? (words - +settings(state).extendAfter) * +settings(state).extendBy
      : 0;

  if (proofreading) {
    hours =
      words > +settings(state).extendAfter
        ? (words - +settings(state).extendAfter) *
          +settings(state).proofreadingExtendBy
        : 0;
  }
  return moment.duration({
    hours: Math.ceil(hours) + +settings(state).minLength * getHoursInDay(state),
  });
};

export const getLatestTime = (
  state,
  words,
  time = null,
  proofreading = false
) => {
  const earliestTime = getEarliestTime(state, words, time, proofreading);
  const duration = getMaxDuration(state, words, proofreading);
  return addBusinessHours(state, earliestTime, duration);
};

export const generateMarks = (
  state,
  words,
  time = null,
  proofreading = null
) => {
  let currentTime = getEarliestTime(state, words, time, proofreading);
  const latestTime = getLatestTime(state, words, time, proofreading);
  const marks = { 0: "" };
  let steps = 0;
  do {
    const nextTime = addBusinessHours(state, currentTime, {
      minutes: settings().increments,
    });
    steps += 1;
    currentTime = nextTime;
  } while (currentTime.isSameOrBefore(latestTime));
  steps -= 1;
  marks[steps] = "";

  return { marks, max: steps };
};

export const timeFromIncrements = (
  state,
  increments,
  time = null,
  proofreading = null
) => {
  let newTime = time;

  let counter = increments;
  let settingsIncrements = !proofreading
    ? settings().increments
    : settings().increments * settings().proofreadingVSTranslationDelay;
  while (counter > 0) {
    newTime = addBusinessHours(state, newTime, {
      minutes: settingsIncrements,
    });
    counter -= 1;
  }
  return newTime;
};

export const mustStartTime = (
  state,
  deadline,
  words,
  time = null,
  proofreading = null
) => {
  deadline = addBusinessHours(state, deadline);
  time = addBusinessHours(state, time);
  let endOfDay = time.clone().set(getDayEnd(state));
  const hoursInDay = getHoursInDay(state);

  const mst = moment.duration();
  while (endOfDay.isBefore(deadline)) {
    mst.add(endOfDay.diff(time));
    time = addBusinessHours(state, time.clone().set(getDayStart(state)), {
      hours: hoursInDay,
    });
    endOfDay = time.clone().set(getDayEnd(state));
  }
  mst.add(deadline.diff(time));

  return mst.subtract({
    hours: !proofreading
      ? words * +settings(state).extendBy + +settings(state).searchTime
      : words * +settings(state).proofreadingExtendBy +
        +settings(state).proofreadingSearchTime,
  });
};
