import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import styles from './ClientInvoiceDetails.module.scss';
import moment from 'moment-timezone';

const ClientInvoiceDetail = ({ invoice }) => {
  return (
    <Container className={styles.Wrapper}>
      <Row className={styles.Row}>
        <Col xs="2" className={styles.invoiceId}>
          {invoice.translatorInvoiceId || invoice.invoiceId}
        </Col>
        <Col xs="4" className={styles.Name}>
          {invoice.company || invoice.name}
        </Col>
        <Col xs="2" className="text-right font-weight-bold">
          {invoice.total} €
        </Col>
        <Col className={styles.due}>{moment(invoice.updatedAt).calendar()}</Col>
        <Col className="text-right">
          <a
            className="button button--inline"
            href={`${process.env.REACT_APP_API_URL}/files/${invoice.file}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage id="Invoice.Download" />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default ClientInvoiceDetail;
