import React from "react";
import PropTypes from "prop-types";
import { FormGroup, ControlLabel } from "react-bootstrap";
import { useIntl } from "react-intl";

import Help from "./Help";

const maybeTranslate = (value, intl) => {
  if (typeof value === "string" && value.length) {
    return intl.formatMessage({ id: value });
  }
  return value;
};

const CheckboxField = ({ input, label, customLabel, meta, tooltip, style }) => {
  const { touched, warning, error } = meta || {};
  const intl = useIntl();

  let state = null;
  if (touched) state = error ? "error" : warning ? "warning" : null;
  let translatedLabel = customLabel;
  if (!translatedLabel && label) {
    translatedLabel = intl.formatMessage({ id: label });
  }
  return (
    <FormGroup controlId={input.name} validationState={state} style={style}>
      {tooltip && <Help input={input.name} text={tooltip} />}

      <label className="control control--checkbox">
        {translatedLabel}
        <input type="checkbox" checked={input.value} {...input} />
        <div className="control__indicator" />
      </label>
      {state && (
        <ControlLabel
          style={{ display: state ? "block" : "none" }}
          className={`section-textarea__message ${state}`}
        >
          {maybeTranslate(error, intl) || maybeTranslate(warning, intl)}
        </ControlLabel>
      )}
    </FormGroup>
  );
};

CheckboxField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  customLabel: PropTypes.object,
  meta: PropTypes.object,
  tooltip: PropTypes.object,
};

CheckboxField.defaultProps = {
  label: "",
  customLabel: undefined,
  meta: undefined,
  tooltip: undefined,
};

export default CheckboxField;
