import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { get } from 'lodash';
import lang from '../common/lang';
import feathers from '../feathers';

export const MESSAGE = 'messages-channel';
export const ORDER_UPDATE = 'order-update-channel';
export const OFFER = 'offer-channel';

export const deregisterFromPushNotifications = async (userId) => {
  console.log("PPPPPPPPPPPPPPPPPPPATTCH")
  feathers.service('users').patch(userId, { pushTokens: [] });
};

export const registerForPushNotifications = async (userId, locale) => {
  if (!Capacitor.isNativePlatform) return null;
  try {
    const existingStatus = await PushNotifications.requestPermission();
    let finalStatus = existingStatus;
    console.log('permissions finalStatus', finalStatus);

    if (Capacitor.getPlatform() === 'android') {
      await PushNotifications.createChannel({
        id: MESSAGE,
        description: 'MesagesChannel',
        importance: 1,
        visibility: 1,
        name: get(lang, `${locale}."Notification.Channel.MESSAGE"`, 'Messages'),
      });
      await PushNotifications.createChannel({
        id: ORDER_UPDATE,
        description: 'OrderUpdateChannel',
        importance: 1,
        visibility: 1,
        name: get(
          lang,
          `${locale}."Notification.Channel.ORDER_UPDATE"`,
          'Orders'
        ),
      });
      await PushNotifications.createChannel({
        id: OFFER,
        description: 'OfferChannel',
        importance: 1,
        visibility: 1,
        name: get(lang, `${locale}."Notification.Channel.OFFER"`, 'Offers'),
      });
    }

    // only ask if permissions have not already been determined, because
    // iOS won't necessarily prompt the user a second time.
    if (!existingStatus.granted) {
      // Android remote notification permissions are granted during the app
      // install, so this will only ask on iOS
      const status = PushNotifications.requestPermission();
      finalStatus = status;
    }
    // Stop here if the user did not grant permissions
    if (!finalStatus.granted) {
      console.log('NOT GRANTED');
      return deregisterFromPushNotifications(userId);
    }

    // Get the token that uniquely identifies this device
    PushNotifications.register();
    PushNotifications.addListener('registration', (token) => {
      console.log('Push registration success, token: ' + token.value);
      feathers
        .service('users')
        .patch(userId, { $addToSet: { pushTokens: token.value } });
    });
  } catch (e) {
    console.error(e);
  }
  return null;
};
