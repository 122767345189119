import React from "react";
import PropTypes from "prop-types";
import "./StarsFieldTranslator.scss";
import Help from "./Help";
import { FormGroup, ControlLabel } from "react-bootstrap";

const StarsFieldTranslator = ({ input, tooltip, meta }) => {
  const { touched, warning, error } = meta;
  let currentState = null;
  if (touched) currentState = error ? "error" : warning ? "warning" : null;
  return (
    <FormGroup controlId={input.name} validationState={currentState}>
      {tooltip && <Help input={input.name} text={tooltip} />}
      <div className={`rating rating--${input.value}`}>
        <div className="rating__star" onClick={() => input.onChange(1)} />
        <div className="rating__star" onClick={() => input.onChange(2)} />
        <div className="rating__star" onClick={() => input.onChange(3)} />
        <div className="rating__star" onClick={() => input.onChange(4)} />
        <div className="rating__star" onClick={() => input.onChange(5)} />
      </div>
      <ControlLabel
        style={{ display: currentState ? "block" : "none" }}
        className={currentState}
      >
        {error || warning}
      </ControlLabel>
    </FormGroup>
  );
};

StarsFieldTranslator.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.object,
  tooltip: PropTypes.object,
  meta: PropTypes.object,
  rows: PropTypes.number,
};

export default StarsFieldTranslator;
