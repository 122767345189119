import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { getProfile } from "../../account/selectors";
import { createOrder } from "../../actions";
import { useModal } from "../../context/ModalContext";
import storage from "../../cookie";
import Card from "../../layout/Card";
import FavoriteNoResponse from "../../notifications/modal-notif-content/FavoriteNoResponse";
import ModalNotif from "../../notifications/ModalNotif";
import OrderLink from "../../orders/OrderLink";
import {
  getCompletedOrders,
  getInProgressOrders,
  getQuotedOrders,
  getUnconfirmedOrders,
} from "../../orders/selectors";
import Button from "../Button";

const HomePageClient = ({
  inProgressOrders,
  completeOrders,
  unconfirmedOrders,
  quotedOrders,
  user,
  doCreateOrder,
}) => {
  const showSuccess = /email/.test(window.location.search);
  const history = useHistory();
  const redirect = storage.getItem("redirect-after");
  const order = JSON.parse(localStorage.getItem("mybrian-order")) || null;


  useEffect(() => {
    if (redirect) {
      storage.removeItem("redirect-after");
      console.log("Redirect-after - redirecting: " + redirect);
      history.push(redirect);
    } else {
      if (order) {
        delete order._id;
        localStorage.removeItem("mybrian-order");
        doCreateOrder({ order, browserHistory: history, confirm: true });
      }
    }
  }, [history, doCreateOrder, redirect, order]);
  // FIXME: TWITTER LOGIN NOT RETURN name and email, server set whitespace for this fields, redirect to profile page and no welcome email on register
  if (
    user &&
    (!user.email.trim() || !user.givenName.trim() || !user.familyName.trim())
  ) {
    history.push("/settings/account");
  }
  return (
    <div className="body">
      {/* ----------------- Hello ---------------- */}
      {!!user && (
        <h1 className="helloName mb-4">
          <FormattedMessage
            id="CustomerHomepage.PageTitle"
            values={{ givenName: user.givenName }}
          />
        </h1>
      )}
      <div>
        {/* ----------------- Email verified ---------------- */}
        <div
          className={`section-success-message ${
            showSuccess ? "section-success-message--show" : ""
          }`}
        >
          <FormattedMessage id="Account.EmailVerified" />
        </div>

        <Card fill className="BigMargin">
          <Row>
            <Col md="6" className="p-1">
              <Button
                buttonType="CTA"
                onClick={() =>
                  doCreateOrder({
                    browserHistory: history,
                    proofreading: false,
                  })
                }
              >
                <FormattedMessage id="CustomerHomepage.Create" />
              </Button>
            </Col>
            <Col md="6" className="p-1">
              <Button
                buttonType="CTA"
                onClick={() =>
                  doCreateOrder({ browserHistory: history, proofreading: true })
                }
              >
                <FormattedMessage id="CustomerHomepage.CreateProofreading" />
              </Button>
            </Col>
          </Row>
        </Card>

        {!user && (
          <button
            type="button"
            className="button primary"
            onClick={() => history.push("/login")}
          >
            <FormattedMessage id="Login" />
          </button>
        )}
        {!!quotedOrders.length && (
          <Card title="CustomerHomepage.QuotedTranslations">
            {quotedOrders.map((order) => (
              <OrderLink key={order._id} margin order={order} />
            ))}
          </Card>
        )}
        {!!unconfirmedOrders.length && (
          <Card title="CustomerHomepage.UnconfirmedTranslations">
            {unconfirmedOrders.map((order) => (
              <OrderLink key={order._id} margin order={order} />
            ))}
          </Card>
        )}

        {!!inProgressOrders.length && (
          <Card title="CustomerHomepage.TranslationsInProgress">
            {inProgressOrders.map((order) => (
              <OrderLink key={order._id} margin order={order} />
            ))}
          </Card>
        )}

        {!!completeOrders.length && (
          <Card title="CustomerHomepage.CompletedTranslations">
            <>
              {completeOrders.map((order) => (
                <OrderLink key={order._id} margin order={order} />
              ))}
            </>
          </Card>
        )}
      </div>
    </div>
  );
};

HomePageClient.propTypes = {
  inProgressOrders: PropTypes.array,
  completeOrders: PropTypes.array,
  quotedOrders: PropTypes.array,
  user: PropTypes.object,
  doCreateOrder: PropTypes.func.isRequired,
};

HomePageClient.defaultProps = {
  inProgressOrders: [],
  completeOrders: [],
  quotedOrders: [],
  user: undefined,
};

const stateToProps = (state) => ({
  inProgressOrders: getInProgressOrders(state),
  completeOrders: getCompletedOrders(state),
  unconfirmedOrders: getUnconfirmedOrders(state),
  quotedOrders: getQuotedOrders(state),
  user: getProfile(state),
});

const actionsToProps = { doCreateOrder: createOrder };

export default connect(stateToProps, actionsToProps)(HomePageClient);
