import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import ProfileView from "../account/ProfileView";
import { getProfile } from "../account/selectors";
import {
  changeOrder,
  removeFile,
  showChat,
  upload,
  uploadComplete,
  uploadError,
  uploadProgress,
  updateUser,
} from "../actions";
import Attachment from "../attachments/Attachment";
import AttachmentPairs from "../attachments/AttachmentPairs";
import OrderPropType from "../common/proptypes/OrderPropType";
import DeadlineAndPrice from "../components/DeadlineAndPrice";
import UploadField from "../components/formfields/UploadField";
import Header from "../components/Header";
import Languages from "../components/Languages";
import StarRating from "../components/StarRating";
import picture from "../img/default.png";
import { Field, Form, FormSpy } from "react-final-form";
import Card from "../layout/Card";
import { FormGroup, ControlLabel } from "react-bootstrap";
import SplitPane from "../layout/SplitPane";
import OrderRejectionHandler from "../orders/OrderRejectionHandler";
import OrderStatus from "../orders/OrderStatus";
import cookie from "../cookie";
import request from "superagent";
import { isEqual } from "lodash";
import {
  getIndexForAttachment,
  getClientIntermediateUploads,
  getClientUploads,
  getTranslatorUploads,
  getReferenceUploads,
  getTranslatorIntermediateUploads,
  getUploadsForFile,
  isComplete as isOrderComplete,
  isDefinitlyCompleted,
  isSuspended,
  isUnassigned,
  isUnpaid,
} from "../orders/orderUtils";
import { getOrderById } from "../orders/selectors";
import { getSettingsSpecialities } from "../selectors";
import FeedbackModal from "./FeedbackModal";
import styles from "./ViewOrderPage.module.scss";
import Flag from "../components/Flag";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const ViewOrderPage = ({
  match,
  order,
  doShowChat,
  doChangeOrder,
  history,
  settingsSpecialities,
  profile,
  doRemoveFile,
  notifyUpload,
  notifyUploadProgress,
  notifyUploadComplete,
  notifyUploadError,
  doUpdateUser,
}) => {
  useEffect(() => {
    if (order && order.status === "Order.Status.Quoted") {
      if (!(!order || match.params.id !== order?._id))
        history.push(`/confirm/${order._id}`);
    }
  }, [order]);

  const prevOrder = usePrevious(order);
  useEffect(() => {
    if (!prevOrder) {
      doChangeOrder(order);
    }
  }, [order]);

  const [modalIsOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const noTranslatorProfile = {
    givenName: intl.formatMessage({ id: "Order.Status.Unassigned" }),
    picture,
  };

  const handleFormChanges = (event, order, doChangeOrder) => {
    if (!isEqual(order, event.values)) {
      doChangeOrder(event.values);
    }
  };
  const onSubmit = (payload, dispatch) => {};

  // const onMyFavChange = (e) => {
  //   const myFavoriteTranslators = profile.myFavoriteTranslators || [];
  //   const idx = profile.myFavoriteTranslators.findIndex(
  //     (mft) => mft.userId === order.translator
  //   );

  //   if (idx === -1) {
  //     myFavoriteTranslators.push({
  //       givenName: order.translatorDetails.givenName,
  //       userId: order.translator,
  //       proofreading: order.proofreading,
  //       languageCouple: {
  //         selectedLanguage: order.selectedLanguage,
  //         destinationLanguage: order.destinationLanguage,
  //       },
  //     });
  //   } else {
  //     myFavoriteTranslators.splice(idx, 1);
  //   }
  //   doUpdateUser({ user: { ...profile, myFavoriteTranslators } });
  // };

  const translator = order?.translator;

  console.log(profile);

  const favorite = !!profile?.myFavoriteTranslators?.[
    order?.specialityId
  ]?.find((e) => e === translator);

  const patchFavorite = async (specialityId, translatorId) => {
    const newFavorite = { ...profile.myFavoriteTranslators };
    console.log(specialityId, translatorId, newFavorite);

    if (newFavorite[specialityId]?.find((e) => e === translatorId)) {
      newFavorite[specialityId] = newFavorite[specialityId].filter(
        (e) => e != translatorId
      );
    } else {
      const exists = newFavorite[specialityId];
      if (!exists) newFavorite[specialityId] = [];
      newFavorite[specialityId].push(translatorId);
    }

    const user = { ...profile, myFavoriteTranslators: newFavorite };
    doUpdateUser({ user });
  };

  const uploadFiles = (acceptedFiles) => {
    const type = "client-intermediate";
    acceptedFiles.forEach((file) => {
      const nonce = [...Array(10)]
        .map(() => (~~(Math.random() * 36)).toString(36))
        .join("");
      const { name } = file;

      notifyUpload({
        name,
        percent: 0,
        error: null,
        words: null,
        path: null,
        uploading: true,
        nonce,
        authorId: profile._id,
        type: type,
        translationFor: acceptedFiles.path,
      });

      request
        .post(`${process.env.REACT_APP_API_URL}/uploads`)
        .set("Authorization", cookie.getItem("feathers-jwt"))
        .query({
          $client: {
            orderId: order._id,
            type: type,
            translationFor: acceptedFiles.path,
          },
        })
        .attach("uri", file, name)
        .on("progress", (event) => {
          notifyUploadProgress({ name, percent: event.percent, nonce });
        })
        .then(
          ({ body }) => {
            notifyUploadComplete({
              name,
              words: body.words,
              path: body.id,
              nonce,
            });
          },
          (error) =>
            notifyUploadError({
              name,
              error: error.response.body.message,
              nonce,
            })
        );
    });
  };

  let showCheckBoxFavorite = true;
  /*if (
    profile.myFavoriteTranslators &&
    profile.myFavoriteTranslators.find(
      (i) =>
        i.userId === order.translator &&
        i.languageCouple.selectedLanguage === order.selectedLanguage &&
        i.languageCouple.destinationLanguage === order.destinationLanguage
    )
  ) {
    showCheckBoxFavorite = false;
  }*/
  if (order) {
    const isComplete = isOrderComplete(order);
    const orderIsDefinitlyCompleted = isDefinitlyCompleted(order);
    const orderIsUnassigned = isUnassigned(order);
    const orderIsUnpaid = isUnpaid(order);
    const isAssigned = !isUnassigned(order);
    const showSuccess = !isAssigned;
    const clientUploads = getClientUploads(order);
    const translatorUploads = getTranslatorUploads(order);
    const referenceUploads = getReferenceUploads(order);

    const translatorIntermediateUploads =
      getTranslatorIntermediateUploads(order);
    const clientIntermediateUploads = getClientIntermediateUploads(order);

    if (isSuspended(order)) {
      return <OrderRejectionHandler order={order} />;
    }

    return (
      <Form initialValues={order} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Row className="header-container">
              <Col className="content-container">
                <Header />
              </Col>
            </Row>

            <Container>
              <div className="content-container body">
                <div>
                  <div className="Title">
                    <FormattedMessage id="Order.Project.Details" />
                  </div>
                  {/* ----------------------------- SUCCESS MESSAGE ------------------------------- */}
                  <div
                    className={`section-success-message ${
                      showSuccess ? "section-success-message--show" : ""
                    }`}
                  >
                    <p>
                      <FormattedMessage id="Order.PaymentSuccessful" />
                    </p>

                    <p>
                      <FormattedMessage id="Order.OrderPlaced" />
                    </p>
                  </div>

                  {/* --------------------------- STATUS --------------------------------- */}

                  <Card fill title="Order.Status">
                    <OrderStatus order={order} />
                  </Card>

                  {/* ----------------------------- GENERAL INFO ------------------------------- */}
                  <Card
                    fill
                    title={
                      order.proofreading
                        ? "TranslationPage.GeneralInfoProofreading"
                        : "TranslationPage.GeneralInfoTranslation"
                    }
                  >
                    <>
                      <DeadlineAndPrice
                        deadline={order.deadline}
                        price={order.price}
                        priceWithTax={order.priceWithTax}
                        discountedPrice={order.discountedPrice}
                        discountedPriceWithTax={order.discountedPriceWithTax}
                        discountReason={order.discountReason}
                        completedAt={order.completedAt}
                        order={order}
                      />
                      <br />
                      <Languages order={order} />
                    </>
                  </Card>

                  <Card title="Order.Confirm.Specificity" fill>
                    {order.specificity === "" || !order.specificity ? (
                      <FormattedMessage id="NotSpecified" />
                    ) : (
                      <p className="section-text no-border">
                        {
                          settingsSpecialities.find(
                            (spe) => spe?.key === order?.specialityId
                          )?.speciality?.[profile.lang]
                        }
                        {/* <span>
                          <FormattedMessage
                            id={`Order.Specificity.${order.specificity}`}
                          />
                        </span>
                        {order.specificity === "specialist" &&
                          !!order.specialityId && (
                            <span>
                              {" "}
                              &nbsp;:{" "}
                              {
                                settingsSpecialities.find(
                                  (spe) => spe.key === order.specialityId
                                ).speciality[profile.lang]
                              }
                            </span>
                          )} */}
                      </p>
                    )}
                  </Card>
                  <Card title="Order.Confirm.AdditionalInfo" fill>
                    <div className="section-text notranslate no-border">
                      {order.additionalInfo === "" || !order.additionalInfo ? (
                        <FormattedMessage id="NotSpecified" />
                      ) : (
                        order.additionalInfo
                      )}
                    </div>
                  </Card>

                  {!!referenceUploads.filter(
                    (a) => a.name?.split(".")?.at?.(-1) !== "tmx"
                  )?.length && (
                    <Card title="Order.View.Reference">
                      <div style={{ padding: 20 }}>
                        {referenceUploads
                          .filter((a) => a.name?.split?.(".")?.at?.(-1) !== "tmx")
                          ?.map((u) => (
                            <Attachment
                              key={u.name}
                              attachment={u}
                              readonly
                              link
                              showRemove={false && !orderIsDefinitlyCompleted}
                            />
                          ))}
                      </div>
                    </Card>
                  )}
                  {/* ---------------------------- TMX --------------------------------*/}
                  {!order?.proofreading ? (
                    <Card fill title="Translator.TMX.Title">
                      <Row>
                        <Col xs="6" style={{ paddingLeft: "26px" }}>
                          <div>
                            <p>
                              <FormattedMessage id="Translator.TMX.Client" />
                            </p>
                            {referenceUploads.filter(
                              (a) => a.name.split(".").at(-1) === "tmx"
                            )?.length > 0 ? (
                              referenceUploads
                                .filter(
                                  (a) => a.name.split(".").at(-1) === "tmx"
                                )
                                ?.map((sourceAttachment) => (
                                  <div
                                    key={sourceAttachment.path}
                                    style={{ marginBottom: 10 }}
                                  >
                                    <Attachment
                                      key={sourceAttachment.path}
                                      attachment={sourceAttachment}
                                      index={getIndexForAttachment(
                                        order,
                                        sourceAttachment
                                      )}
                                      link
                                      readonly={true}
                                      showRemove={false}
                                    />
                                  </div>
                                ))
                            ) : (
                              <p className="section-text no-border alert text-danger">
                                <FormattedMessage id="TranslationPage.NoFile" />
                              </p>
                            )}
                          </div>
                        </Col>
                        <Col xs="6" style={{ paddingLeft: "26px" }}>
                          <div>
                            <p>
                              <FormattedMessage id="Translator.TMX.PostTrad" />
                            </p>
                            {translatorUploads.filter(
                              (a) => a.name.split(".").at(-1) === "tmx"
                            )?.length > 0 ? (
                              translatorUploads
                                .filter(
                                  (a) => a.name.split(".").at(-1) === "tmx"
                                )
                                ?.map((sourceAttachment) => (
                                  <div
                                    key={sourceAttachment.path}
                                    style={{ marginBottom: 10 }}
                                  >
                                    <Attachment
                                      key={sourceAttachment.path}
                                      attachment={sourceAttachment}
                                      index={getIndexForAttachment(
                                        order,
                                        sourceAttachment
                                      )}
                                      link
                                      readonly={true}
                                      showRemove={false}
                                    />
                                  </div>
                                ))
                            ) : (
                              <p className="section-text no-border alert text-danger">
                                <FormattedMessage id="TranslationPage.NoFile" />
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  ) : null}

                  {/* ---------------------------- Fichiers  intermédiaires --------------------------------*/}
                  <Card fill title="TranslationPage.IntermediateFiles">
                    <Row>
                      <Col xs="6">
                        <div>
                          <p>
                            <FormattedMessage id="TranslationPage.FilesSentByYou" />
                          </p>
                          {!clientIntermediateUploads ||
                            (clientIntermediateUploads.length === 0 && (
                              <p className="section-text no-border text-center alert text-danger">
                                <FormattedMessage id="TranslationPage.NoFile" />
                              </p>
                            ))}
                          {clientIntermediateUploads &&
                            clientIntermediateUploads.length > 0 &&
                            clientIntermediateUploads.map(
                              (sourceAttachment) => (
                                <>
                                  <Attachment
                                    key={sourceAttachment.path}
                                    attachment={sourceAttachment}
                                    index={getIndexForAttachment(
                                      order,
                                      sourceAttachment
                                    )}
                                    readonly={true}
                                    showRemove={
                                      true && !orderIsDefinitlyCompleted
                                    }
                                    onRemove={() =>
                                      doRemoveFile({
                                        orderId: order._id,
                                        file: sourceAttachment.path,
                                      })
                                    }
                                  />
                                </>
                              )
                            )}
                        </div>
                      </Col>
                      <Col xs="6">
                        <div>
                          <p>
                            <FormattedMessage id="TranslationPage.FilesSentByTranslator" />
                          </p>

                          {!translatorIntermediateUploads ||
                            (translatorIntermediateUploads.length === 0 && (
                              <p className="section-text no-border alert text-danger">
                                <FormattedMessage id="TranslationPage.NoFile" />
                              </p>
                            ))}
                          {translatorIntermediateUploads &&
                            translatorIntermediateUploads.length > 0 &&
                            translatorIntermediateUploads.map(
                              (sourceAttachment) => (
                                <Attachment
                                  key={sourceAttachment.path}
                                  attachment={sourceAttachment}
                                  index={getIndexForAttachment(
                                    order,
                                    sourceAttachment
                                  )}
                                  link
                                  showRemove={
                                    false && !orderIsDefinitlyCompleted
                                  }
                                  readonly={true}
                                  onRemove={() =>
                                    doRemoveFile({
                                      orderId: order._id,
                                      file: sourceAttachment.path,
                                    })
                                  }
                                />
                              )
                            )}
                        </div>
                      </Col>

                      <Col>
                        <UploadField
                          className={styles.Upload}
                          handleDrop={uploadFiles}
                          uploadMessage={"File.Drop"}
                          disabled={orderIsDefinitlyCompleted}
                        />
                      </Col>
                    </Row>
                  </Card>

                  {/* ----------------------------- TRANSLATION ------------------------------- */}
                  {order.text && (
                    <Card
                      fill
                      title={
                        order.proofreading
                          ? "Order.View.Text.Proofreading"
                          : "Order.View.Text.Translate"
                      }
                    >
                      {!(order.attachments && order.attachments.length) && (
                        <SplitPane
                          left={<Card fill>{order.text}</Card>}
                          right={
                            isComplete && <Card fill>{order.translation}</Card>
                          }
                        />
                      )}
                    </Card>
                  )}
                  {!!order.attachments?.length && (
                    <Card fill title="Order.View.Text.Translate">
                      <div className="flex">
                        <div style={{ width: "50%" }}>
                          <div className="flex items-center mb-20">
                            <span style={{ marginRight: 10 }}>
                              <FormattedMessage id="Files.Final.Origin" />
                            </span>
                            <Flag
                              country={order.selectedLanguage}
                              includeText={false}
                            />
                          </div>

                          {clientUploads.map((c, _i) => (
                            <div
                              key={`a-${_i}-${new Date().toISOString()}`}
                              style={{ marginBottom: 10 }}
                            >
                              <Attachment
                                attachment={c}
                                index={getIndexForAttachment(order, c)}
                                link
                                readonly
                              />
                            </div>
                          ))}
                        </div>
                        <div style={{ width: "50%" }}>
                          <div className="flex items-center mb-20">
                            <span style={{ marginRight: 10 }}>
                              <FormattedMessage id="Files.Final.Delivered" />
                            </span>
                            <Flag
                              country={order.destinationLanguage}
                              includeText={false}
                            />
                          </div>
                          {translatorUploads
                            .filter((a) => a.name.split(".").at(-1) !== "tmx")
                            .map((c, _i) => (
                              <div
                                key={`b-${_i}-${new Date().toISOString()}`}
                                style={{ marginBottom: 10 }}
                              >
                                <Attachment
                                  attachment={c}
                                  index={getIndexForAttachment(order, c)}
                                  link
                                  readonly
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </Card>
                  )}
                  {/* ----------------------------- CHAT ------------------------------- */}

                  <Card title="translator" fill>
                    <SplitPane
                      left={
                        <ProfileView
                          isTranslator={true}
                          editable={false}
                          profile={
                            order.translatorDetails || noTranslatorProfile
                          }
                        />
                      }
                      right={
                        <div>
                          <>
                            {translator && (
                              <div className="feedback-modal__details">
                                <div className="feedback-modal__details-inner">
                                  {showCheckBoxFavorite &&
                                    order.status ===
                                      "Order.Status.Complete" && (
                                      <>
                                        <FormGroup>
                                          <label className="control control--checkbox">
                                            <FormattedMessage
                                              id="Feedback.AddToFavoriteTranslators"
                                              values={{
                                                givenName:
                                                  order.translatorDetails &&
                                                  order.translatorDetails
                                                    .givenName,
                                              }}
                                            />
                                            <input
                                              type="checkbox"
                                              onChange={() =>
                                                patchFavorite(
                                                  order.specialityId,
                                                  translator
                                                )
                                              }
                                              checked={favorite}
                                            />
                                            <div className="control__indicator" />
                                          </label>
                                        </FormGroup>
                                        <p className="smallRemark">
                                          <FormattedMessage id="Account.FavoriteTranslators.explanations" />
                                        </p>
                                      </>
                                    )}
                                </div>
                              </div>
                            )}
                            {isComplete &&
                              (order.clientStars ? (
                                <div className={styles.RatingContainer}>
                                  <h4>
                                    <FormattedMessage id="Order.YourRating" />
                                  </h4>
                                  <StarRating stars={order.clientStars} />
                                </div>
                              ) : (
                                <Button
                                  onClick={openModal}
                                  className="button my-2"
                                >
                                  <FormattedMessage id="Order.WriteReview" />{" "}
                                  {order.translatorDetails.givenName}
                                </Button>
                              ))}
                            <>
                              {!orderIsUnassigned && !orderIsUnpaid ? (
                                <Button
                                  onClick={() => doShowChat(order._id)}
                                  disabled={orderIsDefinitlyCompleted}
                                  className="button my-2"
                                >
                                  {isAssigned ? (
                                    <FormattedMessage id="Order.Chat.WithTranslator" />
                                  ) : (
                                    <FormattedMessage id="Order.Chat.LeaveMessage" />
                                  )}
                                </Button>
                              ) : (
                                ""
                              )}
                            </>
                          </>
                        </div>
                      }
                    />
                  </Card>
                </div>

                {/* ----------------------------- MODAL ------------------------------- */}
                {isComplete && (
                  <Modal isOpen={modalIsOpen} toggle={closeModal}>
                    <ModalHeader toggle={closeModal}></ModalHeader>
                    <ModalBody>
                      <FeedbackModal
                        order={order}
                        onClose={() => closeModal()}
                      />
                    </ModalBody>
                  </Modal>
                )}
              </div>
            </Container>
            <FormSpy
              subscription={{ values: true }}
              onChange={(event) =>
                handleFormChanges(event, order, doChangeOrder)
              }
            />
          </form>
        )}
      </Form>
    );
  }
  return (
    <div className="loading-message">
      <FormattedMessage id="Loading" />
    </div>
  );
};

ViewOrderPage.propTypes = {
  order: OrderPropType,
  doShowChat: PropTypes.func.isRequired,
  doRemoveFile: PropTypes.func.isRequired,
  doChangeOrder: PropTypes.func.isRequired,
};

ViewOrderPage.defaultProps = {
  order: null,
};

const stateToProps = (state, ownProps) => ({
  order: getOrderById(state, ownProps.match.params.id),
  profile: getProfile(state),
  settingsSpecialities: getSettingsSpecialities(state),
});

const actionsToProps = {
  doShowChat: showChat,
  doRemoveFile: removeFile,
  doChangeOrder: changeOrder,
  notifyUpload: upload,
  notifyUploadProgress: uploadProgress,
  notifyUploadError: uploadError,
  notifyUploadComplete: uploadComplete,
  doUpdateUser: updateUser,
};
export default connect(stateToProps, actionsToProps)(ViewOrderPage);
