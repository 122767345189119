import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row, Modal, ModalBody, ModalHeader } from "reactstrap";

import { getProfile } from "../account/selectors";
import {
  acceptThenStartOrder,
  clearReviewOrder,
  rejectOrderAndRedirect,
  reviewOrder,
} from "../actions";
import Attachment from "../attachments-translator/Attachment";
import Countdown from "../components-translator/Countdown";
import SectionOptions from "../components-translator/SectionOptions";
import ActionLink from "../components/ActionLink";
import AppStatusTranslator from "../components/AppStatusTranslator";
import Header from "../components/Header";
import Languages from "../components/Languages";
import SplitPane from "../layout/SplitPane";
import { timezone } from "../constants";
import RejectionModal from "../orders-translator/RejectionModal";
import {
  getIndexForAttachment,
  getClientUploads,
  getTranslatorUploads,
  getReferenceUploads,
  orderStatus,
} from "../orders/orderUtils";
import { getCurrentOrder, getError, getIsLoading } from "../orders/selectors";
import { getSettingsSpecialities } from "../selectors";

const ReviewPageTranslator = ({
  order,
  getOrder,
  match,
  doClearReviewOrder,
  form,
  reject,
  acceptThenStart,
  locale,
  isError,
  isLoading,
  settingsSpecialities,
}) => {
  const [more, setMore] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getOrder(match.params.id);
    return () => doClearReviewOrder();
  }, [doClearReviewOrder, getOrder, match]);

  const history = useHistory();

  const closeModal = () => {
    setShowModal(false);
  };

  const rejectOrder = () => {
    const reasonsObject = form.RejectionFormWizard.values.reason || {};
    const reasonsArray = [];

    // build reasons array
    for (const [key, value] of Object.entries(reasonsObject)) {
      if (value !== false) {
        if (key === "otherDetail") {
          reasonsArray.push(value);
        } else {
          reasonsArray.push(key);
        }
      }
    }
    setShowModal(false);
    reject(order, reasonsArray, history);
  };

  if (order) {
    const uploads = getClientUploads(order);
    const tUploads = getTranslatorUploads(order);
    const uploadsForReference = getReferenceUploads(order);

    if (order.status !== "Order.Status.Unassigned")
      history.push(`/translate/${order._id}`);

    const status = orderStatus(order, "translator").human || "";
    return (
      <>
        <Row className="header-container">
          <Col className="content-container">
            <Header
              left={<span />}
              center={<FormattedMessage id="ReviewPage.Title" />}
              right={<span />}
              chat
            />
          </Col>
        </Row>

        <Container>
          <div className="content-container body">
            <Row>
              <Col>
                <div className="section-countdown">
                  <FormattedMessage id="ReviewPage.Countdown.Start" />
                  {order.reviewTime && (
                    <Countdown
                      time={moment.tz(order.reviewTime, timezone)}
                      atZero={() => history.push("/")}
                    />
                  )}
                  <FormattedMessage id="ReviewPage.Countdown.End" />
                  <br />
                  <FormattedMessage id="ReviewPage.MustChoose" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <div className="Card" notranslate>
                  <div className="section-edit">
                    <div className="section-edit__title">
                      <FormattedMessage
                        id={
                          uploads.length
                            ? order.proofreading
                              ? "Order.View.File.Proofreading"
                              : "Order.View.Files"
                            : order.proofreading
                            ? "Order.View.Text.Proofreading"
                            : "Order.View.Text.Translate"
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      {!!uploads.length &&
                        uploads.map((u) => (
                          <Attachment
                            key={u.name}
                            attachment={u}
                            readonly
                            link
                            showRemove={false}
                          />
                        ))}
                    </div>
                    <div>
                      <>
                        {tUploads.map((c) => (
                          <Attachment
                            attachment={c}
                            index={getIndexForAttachment(order, c)}
                            link
                            readonly
                          />
                        ))}
                        <div
                          style={{
                            padding: 20,
                            borderRadius: 15,
                            backgroundColor: "white",
                            height: "fit-content",
                          }}
                        >
                          <div
                            style={{ width: 250 }}
                            className="blue flex space-between"
                          >
                            <span>
                              <FormattedMessage id="Order.Words.Unique" /> :
                            </span>
                            <span style={{ fontWeight: "bold" }}>
                              {order.words - order.redundantWords}{" "}
                              <FormattedMessage id="Order.Word" />
                            </span>
                          </div>
                          <div
                            style={{ width: 250 }}
                            className="blue flex space-between"
                          >
                            <span>
                              <FormattedMessage id="Order.Words.Repeated" /> :
                            </span>
                            <span style={{ fontWeight: "bold" }}>
                              {order.redundantWords}{" "}
                              <FormattedMessage id="Order.Word" />
                            </span>
                          </div>
                          <div
                            style={{ width: 250, marginTop: 20 }}
                            className="blue flex space-between"
                          >
                            <span>
                              <FormattedMessage id="Order.Words.Total" /> :
                            </span>
                            <span style={{ fontWeight: "bold" }}>
                              {order.words} <FormattedMessage id="Order.Word" />
                            </span>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>

                  {!!order.text && (
                    <div className="section-text">
                      <div className="section-text__summary notranslate">
                        {order.text}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <SectionOptions
                  deadline={order.deadline}
                  price={order.pay}
                  locale={locale}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <div className="section-completed-translation">
                    <Languages order={order} />
                    <div className="section-completed-translation__details">
                      <ActionLink
                        onClick={() => setMore(!more)}
                        className={`pink js-more-toggle more ${
                          more ? "more--open" : ""
                        }`}
                      >
                        <FormattedMessage id="Order.View.MoreInfo" />
                      </ActionLink>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <div
                  className={`js-more-content ${
                    more ? "js-more-content--show" : ""
                  }`}
                >
                  <div className="section-edit">
                    <FormattedMessage id="Order.Confirm.Specificity" />
                  </div>
                  <div className="section-text">
                    <p>
                      {
                        settingsSpecialities.find(
                          (spe) => spe.key === order?.specialityId
                        )?.speciality[locale]
                      }
                    </p>
                  </div>
                  {order?.keywords.length > 0 && (
                    <div style={{ margin: "40px 0px 20px 0px" }}>
                      {
                        <div className="flex w-full flex-wrap">
                          {order?.keywords.map((k) => (
                            <div
                              style={{
                                padding: "10px 30px",
                                border: "1px solid grey",
                                margin: "0px 10px 10px 0px",
                              }}
                              className="flex items-center justify-center"
                            >
                              {k}
                            </div>
                          ))}
                        </div>
                      }
                    </div>
                  )}

                  <div className="section-edit">
                    <FormattedMessage id="Order.Confirm.AdditionalInfo" />
                  </div>
                  <div className="section-text">
                    {order.additionalInfo === "" || !order.additionalInfo ? (
                      <FormattedMessage id="NotSpecified" />
                    ) : (
                      order.additionalInfo
                    )}
                  </div>
                  {!!uploadsForReference.length && (
                    <>
                      <div className="Card">
                        <div className="section-edit">
                          <div className="section-edit__title">
                            <FormattedMessage id="Order.View.Reference" />
                          </div>
                        </div>
                        {uploadsForReference.map((u) => (
                          <Attachment
                            key={u.name}
                            attachment={u}
                            readonly
                            link
                            showRemove={false}
                          />
                        ))}
                      </div>
                    </>
                  )}

                  <div className="section-edit">
                    <FormattedMessage id="Order.Status" />
                  </div>
                  <div className="section-text">{status}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="section-general section-general--horizontal">
                  <button
                    onClick={() => setShowModal(true)}
                    className="button button--inline"
                  >
                    <FormattedMessage id="Translator.Order.Reject" />
                  </button>
                  <button
                    onClick={() => acceptThenStart(order, history)}
                    className="button button--inline button--green"
                  >
                    <FormattedMessage id="Translator.Order.Accept" />
                  </button>
                </div>
              </Col>
            </Row>
          </div>

          <Modal isOpen={showModal} toggle={closeModal}>
            <ModalHeader toggle={closeModal}>
              <FormattedMessage id="Translator.Reject.ReasonTitle" />
            </ModalHeader>
            <ModalBody>
              <RejectionModal
                handleReject={rejectOrder}
                showNotGeneral={order.specificity === "general"}
                backFromRejectionModal={closeModal}
              />
            </ModalBody>
          </Modal>
        </Container>
      </>
    );
  }
  return (
    <>
      <Row className="header-container">
        <Col className="content-container">
          <Header />
        </Col>
      </Row>

      <Container>
        <AppStatusTranslator
          isError={isError}
          isLoading={isLoading}
          history={history}
        />
      </Container>
    </>
  );
};

ReviewPageTranslator.propTypes = {
  order: PropTypes.object,
  form: PropTypes.object,
  locale: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.object,
  getOrder: PropTypes.func.isRequired,
  acceptThenStart: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

ReviewPageTranslator.defaultProps = {
  order: null,
  form: null,
  isLoading: false,
  isError: null,
};

const stateToProps = (state) => ({
  order: getCurrentOrder(state),
  form: state.form,
  locale: getProfile(state).lang,
  isLoading: getIsLoading(state),
  isError: getError(state),
  settingsSpecialities: getSettingsSpecialities(state),
});

const actionsToProps = (dispatch) => ({
  getOrder: (orderId) => dispatch(reviewOrder(orderId)),
  reject: (order, reason, history) =>
    dispatch(
      rejectOrderAndRedirect({
        order,
        reason,
        browserHistory: history,
      })
    ),
  acceptThenStart: (order, history) =>
    dispatch(acceptThenStartOrder({ order, browserHistory: history })),
  doClearReviewOrder: () => dispatch(clearReviewOrder()),
});

export default connect(stateToProps, actionsToProps)(ReviewPageTranslator);
