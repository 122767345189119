import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "reactstrap";

import { widget } from "../constants";
import styles from "./SplitPane.module.scss";

const SplitPane = ({ left, right, leftTitle, rightTitle, centerline }) =>
  !widget ? (
    <Row
      className={classNames({
        [styles.Container]: true,
        [styles.Centerline]: centerline,
      })}
    >
      <Col className={styles.Left}>
        <>
          {(leftTitle || rightTitle) && (
            <div className={styles.Header}>
              {leftTitle && <FormattedMessage id={leftTitle} />}
            </div>
          )}
          <div className={styles.LeftContent}>{left}</div>
        </>
      </Col>
      {right && (
        <Col className={styles.Right}>
          <>
            {(leftTitle || rightTitle) && (
              <div className={styles.Header}>
                {rightTitle && <FormattedMessage id={rightTitle} />}
              </div>
            )}
            <div className={styles.RightContent}>{right}</div>
          </>
        </Col>
      )}
    </Row>
  ) : (
    <div className="flex-center" style={{ justifyContent: "center" }}>
      <div className={styles.DateContent}>{left}</div>
      <div className={styles.For}>
        <FormattedMessage id="Widget.For" />
      </div>
      <div className={styles.PriceContent}>{right}</div>
    </div>
  );

SplitPane.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  centerline: PropTypes.bool,
};

SplitPane.defaultProps = {
  left: null,
  right: null,
  leftTitle: null,
  rightTitle: null,
  centerline: false,
};

export default SplitPane;
