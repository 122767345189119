import accounting from 'accounting';
import React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { timezone, locale } from '../constants';

const SectionOptions = (props) => {
  // vars
  const deadline = moment.tz(props.deadline, timezone);
  const {
    price,
    priceWithTax,
    discountReason,
    discountedPrice,
    discountedPriceWithTax,
  } = props;
  const both = price && priceWithTax;
  let title;

  if (both) {
    title = 'Order.Confirm.Price';
  } else if (priceWithTax) {
    title = 'Order.Confirm.Price.WithTax';
  } else {
    title = 'Order.Confirm.Price.ExcludingTax';
  }
  title = <FormattedMessage id={title} />;

  const priceToShow = discountReason ? discountedPrice : price;
  const priceWithTaxToShow = discountReason
    ? discountedPriceWithTax
    : priceWithTax;
  // return
  return (
    <div className="section-options">
      <div className="section-options__price-block">
        <div className="section-options__title">{title}</div>
        <div className="section-options__price">
          <div className="section-options__prices">
            <div className="section-options__prices-inner">
              {!both && price ? (
                <div className="">
                  {discountReason && '*'}
                  {accounting.formatMoney(priceToShow, '', 2, '.', ',')}
                </div>
              ) : (
                ''
              )}
              {!both && priceWithTax ? (
                <div className="">
                  {discountReason && '*'}
                  {accounting.formatMoney(priceWithTaxToShow, '', 2, '.', ',')}
                </div>
              ) : (
                ''
              )}
              {both ? (
                <div>
                  <div className="section-options__price-ht">
                    {discountReason && '*'}
                    {accounting.formatMoney(priceToShow, '', 2, '.', ',')}
                  </div>
                  <div className="section-options__price-ttc">
                    {discountReason && '*'}
                    {accounting.formatMoney(
                      priceWithTaxToShow,
                      '',
                      2,
                      '.',
                      ','
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="section-options__timing-block">
        <div className="section-options__title">
          <FormattedMessage id="Order.Confirm.Deadline" />
        </div>
        <div className="section-options__timing">
          <div className="section-options__datetime">
            <div className="section-options__datetime-inner">
              <div className="section-options__date">
                {deadline.calendar(null, {
                  sameDay: locale === 'fr' ? "[Aujourd'hui]" : '[Today]',
                  nextDay: locale === 'fr' ? '[Demain]' : '[Tomorrow]',
                  lastDay: locale === 'fr' ? '[Hier]' : '[Yesterday]',
                  nextWeek: 'dddd',
                  sameElse: 'DD/MM/YYYY',
                })}
              </div>
              <div className="section-options__time">
                {deadline.format('LT')}
              </div>
            </div>
          </div>
        </div>
      </div>

      {discountReason && (
        <div className="section-options__discountReason">
          *<FormattedMessage id={discountReason} />
        </div>
      )}
    </div>
  );
};

export default SectionOptions;
