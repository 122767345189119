import { get, filter, orderBy } from "lodash";

export const getNotifications = (state) =>
  orderBy(
    get(state, "notifications.store.records", []),
    ["createdAt"],
    ["desc"]
  );
export const getModalNotifications = (state) =>
  filter(
    getNotifications(state),
    (n) => n.subject === "Notification.FavTransNotAvailable" && !n.clicked
  );

export const getUnreadNotificationCount = (state) =>
  filter(getNotifications(state), (n) => !n.seen).length;
