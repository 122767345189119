import PropTypes from "prop-types";
import React, { useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { faCreditCard, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";

import ConditionalField from "../components/formfields/ConditionalField";
import SelectField from "../components/formfields/SelectField";
import TextareaFieldTranslator from "../components/formfields/TextareaFieldTranslator";
import TextField from "../components/formfields/TextField";
import { taxStatuses } from "../constants";
import Card from "../layout/Card";
import composeValidators from "../utils/composeValidators";
import isValidIBANNumber from "../utils/isValidIBANNumber";
import { updateUser } from "./../actions";
import AccountPropType from "./../common/proptypes/AccountPropType";

const required = (value) => (value ? undefined : "Forms.Required");

const AccountDetailsTranslatorBillingInfo = ({ profile, doUpdateUser }) => {
  const browserHistory = useHistory();
  const intl = useIntl();
  const [formIsUpdated, setFormIsUpdated] = useState(false);

  const validateEmail = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "Forms.InvalidEmail"
      : undefined;
  const validateIban = (iban) =>
    iban && !isValidIBANNumber(iban) ? "Forms.InvalidIban" : undefined;
  const taxStatusOptions = () => {
    return Object.keys(taxStatuses).map((status) => ({
      value: status,
      text: intl.formatMessage({ id: `Translator.TaxStatus.${status}` }),
    }));
  };

  const validTaxStatusSelection = (value) =>
    value && !taxStatusOptions().find((o) => o.value === value)
      ? "Forms.InvalidOption"
      : undefined;
  return (
    <>
      <Prompt
        when={formIsUpdated}
        message={intl.formatMessage({ id: "General.YouHaveUnsavedChanges" })}
      />
      <Form
        onSubmit={(user) => doUpdateUser({ user, browserHistory })}
        initialValues={profile}
      >
        {({ handleSubmit, values }) => (
          <div>
            <FormSpy
              subscription={{ dirty: true }}
              onChange={(props) => setFormIsUpdated(props.dirty)}
            />
            <form onSubmit={handleSubmit}>
              <Card
                fill
                title="Account.translatorBankDetails.title"
                faIcon={faCreditCard}
              >
                <Row>
                  <Col xs="12" md="6">
                    <Field
                      component={TextField}
                      name="translatorBankDetails.accountName"
                      label="Account.translatorBankDetails.accountName"
                      validate={required}
                      showErrorBeforeTouched
                      initialValue={
                        profile?.translatorBankDetails?.accountName
                          ? profile.translatorBankDetails.accountName
                          : profile.givenName + " " + profile.familyName
                      }
                    />
                  </Col>
                  <Col xs="12" md="6">
                    <Field
                      component={TextField}
                      name="translatorBankDetails.accountEmail"
                      label="Account.translatorBankDetails.accountEmail"
                      validate={composeValidators(required, validateEmail)}
                      showErrorBeforeTouched
                      initialValue={
                        profile?.translatorBankDetails?.accountEmail
                          ? profile.translatorBankDetails.accountEmail
                          : profile.email
                      }
                    />
                  </Col>
                  <Col xs="12" md="12">
                    <Field
                      component={TextField}
                      name="translatorBankDetails.iban"
                      label="Account.translatorBankDetails.iban"
                      validate={validateIban}
                      showErrorBeforeTouched
                      initialValue={
                        profile?.translatorBankDetails?.iban
                          ? profile.translatorBankDetails.iban
                          : undefined
                      }
                    />
                  </Col>
                </Row>
              </Card>

              <Card fill faIcon={faFileInvoiceDollar} title="Account.Billing">
                <Field
                  component={SelectField}
                  name="taxStatus"
                  label="Translator.TaxStatus"
                  options={taxStatusOptions()}
                  validate={composeValidators(
                    required,
                    validTaxStatusSelection
                  )}
                  showErrorBeforeTouched
                />
                <ConditionalField
                  when="taxStatus"
                  not="FrenchMicroEntrepreneur"
                >
                  <div>
                    <Field
                      component={TextField}
                      name="company"
                      label="Account.CompanyName"
                    />

                    <Field
                      component={TextField}
                      name="companyNumber"
                      label="Account.CompanyNumber"
                    />
                  </div>
                </ConditionalField>

                <div className="section-textarea">
                  <Field
                    component={TextareaFieldTranslator}
                    name="invoiceHeaderDetail"
                    label="User.InvoiceHeaderDetails"
                  />
                </div>

                <div className="section-textarea">
                  <Field
                    component={TextareaFieldTranslator}
                    name="invoiceFooterDetail"
                    label="User.InvoiceFooterDetail"
                  />
                </div>
              </Card>
              <Row>
                <Col align="center">
                  <Button type="submit" className="button button--inline">
                    <FormattedMessage id="Forms.Submit" />
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </Form>
    </>
  );
};

AccountDetailsTranslatorBillingInfo.propTypes = {
  profile: AccountPropType.isRequired,
  initializeBankDetails: PropTypes.func.isRequired,
  doUpdateUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doUpdateUser: updateUser,
};

export default connect(
  null,
  mapDispatchToProps
)(AccountDetailsTranslatorBillingInfo);
