import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import { getProfile, isTranslator } from "../account/selectors";
import { markNotificationsRead } from "../actions";
import ActionLink from "../components/ActionLink";
import Header from "../components/Header";
import NotificationItem from "../notifications/NotificationItem";
import { getNotifications } from "../notifications/selectors";

import Card from "../layout/Card";

const profileHTML = (profile) =>
  profile ? (
    ""
  ) : (
    <div>
      <Link to="/login">
        <FormattedMessage id="Login" />
      </Link>
    </div>
  );

const notificationsHTML = (notifications, isTranslator) => {
  let html = [];
  const hasNotifications = notifications && notifications.length;
  if (hasNotifications) {
    html = notifications.map((notification) => (
      <NotificationItem
        key={notification._id}
        notification={notification}
        isTranslator={isTranslator}
      />
    ));
  }
  return html;
};

const NotificationsPageTranslator = ({
  profile,
  notifications,
  history,
  doMarkNotificationsRead,
  isTranslator,
}) => {
  useEffect(() => {
    doMarkNotificationsRead();
    return () => null;
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row className="header-container">
        <Col className="content-container">
          <Header
            left={
              <ActionLink
                onClick={() => history.goBack()}
                className="icon-arrow-left pink"
              />
            }
            center={<Link to="/" className="header__icon icon-my-brian" />}
            right={null}
          />
        </Col>
      </Row>

      <Container>
        <div className="content-container body">
          <Card>
            {profileHTML(profile)}
            {notificationsHTML(notifications, isTranslator)}
          </Card>
        </div>
      </Container>
    </>
  );
};

NotificationsPageTranslator.propTypes = {
  profile: PropTypes.object,
  notifications: PropTypes.array,
};

const stateToProps = (state) => ({
  isTranslator: isTranslator(state),
  profile: getProfile(state),
  notifications: getNotifications(state),
});
const actionsToProps = {
  doMarkNotificationsRead: markNotificationsRead,
};
export default connect(
  stateToProps,
  actionsToProps
)(NotificationsPageTranslator);
