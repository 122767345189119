import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import setFieldData from "final-form-set-field-data";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Col, Row, Tooltip, Button } from "reactstrap";

import { removeFile, saveOrderProgress, stepUpdateOrder } from "../actions";
import Attachment from "../attachments/Attachment";
import CheckboxField from "../components/formfields/CheckboxField";
import LanguagePicker from "../components/formfields/LanguagePicker";
import TextField from "../components/formfields/TextField";
import Loading from "../components/Loading";
import PdfButton from "../components/PdfButton";
import nToBr from "../utils/nToBr";
import OrderPropType from "./../common/proptypes/OrderPropType";
import OrderFormSpy from "./OrderFormSpy";
import {
  countWordsInOrder,
  getClientUploads,
  getIndexForAttachment,
} from "./orderUtils";
import PricingSection from "./PricingSection";
import { getCurrentOrder } from "./selectors";
import Uploader from "./Uploader";
import styles from "./WidgetOrderForm.module.scss";
import Text, { Title } from "../components/Text";
import SelectField from "../components/formfields/SelectField";
import NewButton from "../components/NewButton";
import { ArcherContainer, ArcherElement } from "react-archer";
import { getLang, getSettingsSpecialities } from "../selectors";
import { getProfile } from "../account/selectors";
import { feathersServices } from "../feathers";

const WidgetOrderForm = ({
  doRemoveFile,
  order,
  history,
  doStepUpdateOrder,
  attachments,
  settingsSpecialities,
  userLang,
}) => {
  const { _id, wordsNumber } = order;
  order.words = wordsNumber || countWordsInOrder(order);

  const errors = attachments.find((a) => a.error);
  let errorMessage = null;
  if (!!errors && errors.words === null)
    errorMessage = <FormattedHTMLMessage id="File.Drop.Error.NoWord" />;
  const fileLanguage = attachments.map((u) => u.lang).toString();

  const [badFileExtension, setBadFileExtension] = useState("");
  const [proofReading, setProofReading] = useState("false");

  if (!order.selectedLanguage && !!fileLanguage) {
    order.selectedLanguage = fileLanguage;
  }

  const [pdfIsSent, setpdfIsSent] = useState(false);
  const pdfHasBeenSent = (response) => {
    setpdfIsSent(response);
  };

  const getFormattedSpecialities = () => {
    return settingsSpecialities.map((spe) => ({
      value: spe.key,
      text: spe.speciality[userLang],
    }));
  };

  useEffect(() => {
    const saveLocalStorage = async () => {
      let specialities = await feathersServices.specialities.find();
      let lngCouples = await feathersServices.languagecouples.find();
      lngCouples = await lngCouples.payload.promise;
      specialities = await specialities.payload.promise;

      localStorage.setItem("specialities", JSON.stringify(specialities || {}));
      localStorage.setItem("lngCouples", JSON.stringify(lngCouples || {}));
    };
    saveLocalStorage()
  }, []);

  if (!order) return <Loading />;
  return (
    <div className={styles.WidgetContainer}>
      <Form
        initialValues={{ ...order, proofreading: false }}
        onSubmit={(value) => {
          delete value.proofreading;

          doStepUpdateOrder({
            order: value,
            browserHistory: history,
          });
        }}
        mutators={{ setFieldData }}
      >
        {({ handleSubmit, submitting, form: { mutators, change }, modified, dirtyFields, values }) => {
          const fieldsToChangePrice = ["specialityId", "selectedLanguage", "destinationLanguage", "proofreading"]
          fieldsToChangePrice.forEach((key) => dirtyFields[key] === true && document.activeElement.blur())
          if (values.proofreading) values.destinationLanguage = undefined;
          const isBtnDisabled =
            !order.words > 0 ||
            (values.proofreading
              ? !values.selectedLanguage
              : !values.destinationLanguage) ||
            errors;
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.WidgetSection}>
                <Title>
                  <div
                    className="relative"
                    style={{
                      display: "flex",
                      marginBottom: 10,
                      alignItems: "center",
                    }}
                  >
                    {/* <FormattedMessage id="File.Drop" /> */}
                    <div className={styles.stepCircle}>
                      <ArcherElement
                        id="first-step"
                        relations={[
                          {
                            targetId: "last-step",
                            targetAnchor: "top",
                            sourceAnchor: "bottom",
                            style: {
                              strokeDasharray: "3",
                              strokeColor: "#FE5F6B",
                            },
                          },
                        ]}
                      >
                        <span>1</span>
                      </ArcherElement>
                    </div>
                    <FormattedMessage id="Widget.DropFile.Title" />
                    {/* <CustomTooltip id="widget-upload">
                      <FormattedMessage id="Order.Files.Upload.Tooltip">
                        {nToBr}
                      </FormattedMessage>
                    </CustomTooltip> */}
                  </div>
                </Title>

                <div className="text-center">
                  <Uploader
                    type="client"
                    disabled={!!wordsNumber}
                    setBadFileExtension={setBadFileExtension}
                    attachments={attachments}
                    wordsNumber={wordsNumber}
                    getIndexForAttachment={getIndexForAttachment}
                    doRemoveFile={doRemoveFile}
                    order={order}
                  />
                </div>

                <div className={styles.Or + " flex-center"}>
                  <div className={styles.divider}></div>
                  <FormattedMessage id="Or" />
                  <div className={styles.divider}></div>
                </div>
                <div className="flex-center">
                  <span className="blue" style={{ marginRight: 10 }}>
                    <span className="bold">
                      <FormattedMessage id="Widget.Enter" />
                    </span>{" "}
                    <FormattedMessage id="Widget.Words.Numbers" />
                  </span>
                  <Field
                    name="wordsNumber"
                    component={TextField}
                    type="number"
                    bsClass="input-number"
                    parse={(value) => value || 0}
                    disabled={attachments.length > 0}
                    className="no-margin"
                  />
                </div>
                {/* {errorMessage && (
                  <div className={styles.WidgetErrorUploader}>
                    {errorMessage}
                  </div>
                )} */}
              </div>
              <div className={styles.WidgetSection}>
                <div className="relative" style={{ marginBottom: 10 }}>
                  <div className={styles.stepCircle}>
                    <span>2</span>
                  </div>
                  <Title>
                    <FormattedMessage id="Widget.Precision" />
                  </Title>
                </div>
                {/* <Field
                  component={CheckboxField}
                  name="proofreading"
                  type="checkbox"
                  customLabel={
                    <FormattedMessage id="Widget.CreateProofreading" />
                  }
                /> */}
                <div style={{ marginBottom: 10 }}>
                  <div>
                    <input
                      style={{ marginRight: 10 }}
                      id="a"
                      type="radio"
                      name="proof"
                      value="false"
                      checked={proofReading === "false"}
                      onChange={(e) => {
                        setProofReading(e.target.value);
                      }}
                    />
                    <label
                      className="blue"
                      style={{ fontWeight: "bold", fontSize: 17 }}
                      for="a"
                    >
                      <FormattedMessage id="Widget.Translate" />
                    </label>
                  </div>
                  <div>
                    <input
                      style={{ marginRight: 10 }}
                      id="b"
                      type="radio"
                      name="proof"
                      value="true"
                      checked={proofReading === "true"}
                      onChange={(e) => {
                        setProofReading(e.target.value);
                      }}
                    />
                    <label className="blue" style={{ fontSize: 17 }} for="b">
                      <FormattedHTMLMessage id="Widget.Proofreading" />
                    </label>
                  </div>
                </div>

                <div style={{ marginBottom: 10 }}>
                  <LanguagePicker
                    selectedLanguage={values.selectedLanguage}
                    fileDetectedLanguage={fileLanguage}
                    order={{
                      ...values,
                      proofreading: proofReading === "true",
                    }}
                  />
                </div>
                <div className="flex align-center">
                  <span
                    className="blue"
                    style={{
                      fontStyle: "italic",
                      fontSize: 16,
                      marginRight: 10,
                    }}
                  >
                    <FormattedMessage id="Order.Speciality" />
                  </span>
                  <Field
                    component={SelectField}
                    name="specialityId"
                    //label="Speciality.Choice"
                    options={getFormattedSpecialities()}
                    //validate={showPicker && required}
                  />{" "}
                </div>
              </div>
              <div className={styles.WidgetSection}>
                <div style={{ marginBottom: 10 }} className="relative">
                  <div className={styles.stepCircle}>
                    <ArcherElement id="last-step">
                      <span>3</span>
                    </ArcherElement>
                  </div>
                  <Title>
                    <FormattedMessage id="Widget.PriceAndDelay" />
                  </Title>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <PricingSection mutators={mutators} orderWidget={values} change={change}/>
                </div>
                {order?.redundancyAverage > 0 && (
                  <div className="flex-center w-full">
                    <div className={styles.successContainer}>
                      <p className={styles.successTitle}>
                        <FormattedMessage id="Widget.Red.Title" />
                      </p>
                      <span>
                        <FormattedMessage id="Widget.Red.Body" />
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div
                className="text-center"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <NewButton type="submit" disabled={!!isBtnDisabled}>
                  <FormattedMessage
                    FormattedMessage
                    id="Widget.Button.Order"
                    defaultMessage="Next"
                  />
                </NewButton>

                <PdfButton
                  order={JSON.parse(JSON.stringify(order))}
                  pdfHasBeenSent={pdfHasBeenSent}
                  disabled={!!isBtnDisabled}
                  messageId="Widget.Button.Quote"
                />
                {pdfIsSent && (
                  <Row>
                    <Col>
                      <p className="pink">
                        <FormattedMessage
                          FormattedMessage
                          id="SendQuote.emailSent"
                        />
                      </p>
                    </Col>
                  </Row>
                )}
              </div>
              <div className="text-center">
                <div className={styles.contact}>
                  <FormattedMessage id="Widget.Help" />
                </div>
              </div>
              <OrderFormSpy order={order} />
            </form>
          );
        }}
      </Form>
    </div>
  );
};

WidgetOrderForm.propTypes = {
  order: OrderPropType,
  doRemoveFile: PropTypes.func.isRequired,
  doStepUpdateOrder: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

WidgetOrderForm.defaultProps = {
  order: null,
};

const stateToProps = (state) => ({
  order: getCurrentOrder(state),
  attachments: getClientUploads(getCurrentOrder(state)),
  settingsSpecialities: getSettingsSpecialities(state),
  userLang: getProfile(state) ? getProfile(state).lang : getLang(state),
});

const dispatchToProps = {
  doRemoveFile: removeFile,
  doSaveOrderProgress: saveOrderProgress,
  doStepUpdateOrder: stepUpdateOrder,
};

export default connect(stateToProps, dispatchToProps)(WidgetOrderForm);
