import "./selectField.scss";

import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";

import FieldHolder from "./FieldHolder";

export const UncontrolledSelectField = (props) => {
  const { input, placeholder, showPlaceholder, options, disabled } = props;
  const intl = useIntl();

  const translatedPlaceHolder = intl.formatMessage({
    id: placeholder || "Forms.Choose",
  });

  if (showPlaceholder && options[0]?.text !== translatedPlaceHolder) {
    options.unshift({ value: "", text: translatedPlaceHolder, disabled: true });
  }

  return (
    <select className="basic-select" disabled={disabled} {...input}>
      {options.map(({ value, text }, i) => (
        <option key={`${value}-${text}`} value={value}>
          {text}
        </option>
      ))}
    </select>
  );
};

const SelectField = (props) => {
  const { input, placeholder, showPlaceholder, options, disabled } = props;
  const intl = useIntl();

  const translatedPlaceHolder = intl.formatMessage({
    id: placeholder || "Forms.Choose",
  });

  if (showPlaceholder && options[0]?.text !== translatedPlaceHolder) {
    options.unshift({ value: "", text: translatedPlaceHolder, disabled: true });
  }
  return (
    <FieldHolder {...props}>
      <select className="basic-select" disabled={disabled} {...input}>
        {options.map(({ value, text }, i) => (
          <option key={`${value}-${text}`} value={value}>
            {text}
          </option>
        ))}
      </select>
    </FieldHolder>
  );
};

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  showPlaceholder: PropTypes.bool,
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  placeholder: "",
  showPlaceholder: true,
  options: [],
  disabled: false,
};

export default SelectField;
