import React, { useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";

import { getDiscount, getDiscountReason } from "../account/selectors";
import DeadlineAndPrice from "../components/DeadlineAndPrice";
import TextField from "../components/formfields/TextField";
import { widget } from "../constants";
import rabbitActivePictureBlue from "../img/lapin-bleu.gif";
import rabbitActivePicturePink from "../img/lapin.gif";
import rabbitPictureBlue from "../img/lapin.png";
import rabbitPicturePink from "../img/lapin1rose.png";
import turtleActivePictureBlue from "../img/tortue-bleu.gif";
import turtleActivePicturePink from "../img/tortue.gif";
import turtlePictureBlue from "../img/tortue.png";
import turtlePicturePink from "../img/tortue1rose.png";
import { calculatedValues } from "./orderUtils";
import { getCurrentOrder } from "./selectors";
import TimePickerField from "./TimePickerField";

const maxOrMin = (value, max) => {
  if (value === 0) {
    return (
      <span className="section-slider__warning fastest">
        <FormattedMessage id="Order.Time.Fastest" />
      </span>
    );
  }
  if (value === max) {
    return (
      <span className="section-slider__warning slowest">
        <FormattedMessage id="Order.Time.Slowest" />
      </span>
    );
  }
  return undefined;
};

const PricingSection = ({
  marks,
  max,
  deadline,
  price,
  discountedPrice,
  discountReason,
  mutators,
  order,
  orderWidget,
  change
}) => {
  let rabbitActivePicture = rabbitActivePictureBlue;
  let rabbitPicture = rabbitPictureBlue;
  let turtleActivePicture = turtleActivePictureBlue;
  let turtlePicture = turtlePictureBlue;
  discountReason = order?.averageRedundancy > 0 ? "Redundant" : undefined;

  const [sliderActive, setSliderActive] = useState(false);
  if (orderWidget) {
	order = { ...orderWidget };
	change("price", price)
  }
  return (
    <>
      <Row>
        <Col xs={{ size: 12, order: 1 }}>
          <div
            className={`section-slider ${
              sliderActive && "section-slider--active"
            }`}
          >
            <Row>
              <div
                style={{ width: "100%", padding: 10 }}
                className="section-slider__slider flex widget"
              >
                <img
                  src={sliderActive ? rabbitActivePicture : rabbitPicture}
                  className="section-slider__left"
                  alt=""
                  style={{ marginRight: 20 }}
                />
                <Field
                  width="100%"
                  name="time"
                  component={TimePickerField}
                  marks={marks}
                  max={max}
                  onBeforeChange={() => setSliderActive(true)}
                  onAfterChange={() => setSliderActive(false)}
                />
                <img
                  src={sliderActive ? turtleActivePicture : turtlePicture}
                  className="section-slider__right"
                  alt=""
                  style={{ marginLeft: 20 }}
                />
              </div>
            </Row>
          </div>
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) => {
              mutators.setFieldData("time", {
                warning: maxOrMin(values.time, max),
              });
            }}
          />
        </Col>
        <Col xs={{ size: 12, order: 2 }}>
          <div style={{ marginTop: 15 }}>
            <DeadlineAndPrice
              deadline={deadline}
              price={price}
              showTax={false}
              discountedPrice={discountedPrice}
              discountReason={discountReason}
              order={order}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

const stateToProps = (state) => {
  const discount = getDiscount(state);
  const discountReason = getDiscountReason(state);

  const order = getCurrentOrder(state);
  return {
    ...calculatedValues(state, order, discount, discountReason),
  };
};
export default connect(stateToProps)(PricingSection);
