import React, { useState } from 'react';
import './Tooltips.scss';
import { Tooltip } from 'reactstrap';

/**
 * 
 * @param {*} 
 * target : the id of the target element 
 *  content : string or object inside tooltip (already translated)
 * placement : placement of the tooltip :
 * placement: 
    'auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end','right','right-start', 'right-end',
    'bottom','bottom-start', 'bottom-end', 'left','left-start', 'left-end' 
    fade: PropTypes.bool (apply a fade effect if true)
    type : 'info', 'warning', 'success', 'danger'

    Example :
    <span className="Icon Assistance text-danger d-inline" id="info"></span>
      <Tooltips
        content={intl.formatMessage({
          id: `Account.NoLanguageSelected`,
        })}
        placement="top"
        target="info"
        className="danger"
      />
 */
const tooltipClasses = ['none', 'info', 'warning', 'success', 'danger'];

const Tooltips = ({ target, content, placement, fade, className }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  if (!placement) placement = 'top';
  if (!className || !tooltipClasses.includes(className)) className = 'none';

  if (typeof fade !== 'boolean') fade = true;

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Tooltip
      placement={placement}
      isOpen={tooltipOpen}
      target={target}
      toggle={toggle}
      autohide={false}
      className={className}
    >
      {content}
    </Tooltip>
  );
};
export default Tooltips;
