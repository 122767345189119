import { without } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const languageBrowser = window.navigator.language.includes('fr') ? 'fr' : 'en';
const widgetLanguage = new URLSearchParams(window.location.search).get('lang');
export const locale = widgetLanguage ? widgetLanguage : languageBrowser;
export const localeFormatTweaks = {
  en: {
    calendar: {
      sameElse: 'dddd Do [at] LT',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      lastDay: '[Yesterday]',
      lastWeek: 'DD/MM/YYYY',
      nextWeek: 'dddd',
      sameElse2: 'DD/MM/YYYY', //TODO: for translator part
    },
  },
  fr: {
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
      '_'
    ),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split(
      '_'
    ),
    monthsParseExact: true,
    weekdays: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    calendar: {
      sameDay: '[Aujourd’hui]',
      nextDay: '[Demain]',
      nextWeek: 'dddd []',
      lastDay: '[Hier]',
      lastWeek: 'dddd [dernier]',
      sameElse: 'L',
    },
    relativeTime: {
      future: 'dans %s',
      past: 'il y a %s',
      s: 'quelques secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans',
    },
  },
};
export const timezone = 'Europe/Paris';

export const specificities = ['general', 'specialist'];

export const titles = ['Female-Married', 'Female-Unmarried', 'Male'];

export const billTos = ['Me', 'Company'];

export const tax = 0.2;

export const linkToTerms = (
  <a
    href="https://www.mybrian.fr/conditions-generales-dutilisation/"
    target="_blank"
    rel="noopener noreferrer"
    className="pink"
  >
    <FormattedMessage id="Account.AgreeLink" />
  </a>
);

export const eu = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB', // For now....
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

export const canBeExcludedFromTax = without(eu, 'FR');

export const widget = window.location.href.includes('widget');

// ======================================== Translators constants ==================================

// See https://technet.microsoft.com/en-us/library/ee309278(office.12).aspx
export const allowedUploadTypes = {
  'application/msword': 'msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'msword',
  'application/vnd.ms-excel': 'msexcel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'msexcel',
  'application/vnd.ms-powerpoint': 'mspowerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'mspowerpoint',
  'text/plain': 'text',
  'text/richtext': 'text',
};

export const taxStatuses = {
  FrenchMicroEntrepreneur: {
    tax: false,
    exemptionText: 'TVA non applicable, art. 293 B du CGI',
  },
  FrenchBusiness: {
    tax: true,
  },
  EUBusiness: {
    tax: false,
  },
  NonEUIndividualOrCompany: {
    tax: false,
  },
};
