import useCookie from "@devhammed/use-cookie";
import React, { useEffect } from "react";
import { ArcherContainer } from "react-archer";
import { connect } from "react-redux";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import { loadLocalOrder, loadOrder, updateOrder } from "../actions";
import Header from "../components/Header";
import ProgressBar from "../components/ProgressBar";
import { widget } from "../constants";
import ContextForm from "../orders/ContextForm";
import OrderForm from "../orders/OrderForm";
import { getCurrentOrder } from "../orders/selectors";
import WidgetOrderForm from "../orders/WidgetOrderForm";
import { getInProgressForm } from "../selectors";
import OrderPropType from "./../common/proptypes/OrderPropType";

const EditOrderPage = ({ order, getOrder, update, match }) => {
  const { id } = useParams();
  const history = useHistory();
  useEffect(() => {
    getOrder(id);
  }, [getOrder, id]);

  useEffect(() => {
    if (!(!order || match.params.id !== order?._id))
      if (order && order.status !== "Order.Status.Unpaid") {
        if (order.status === "Order.Status.Quoted") {
          history.push(`/confirm/${order._id}`);
        } else {
          history.push(`/view/${order._id}`);
        }
      }
  }, [order, history]);

  const [path, , clearPath] = useCookie("quotedOrderFile");
  const [words, , clearWords] = useCookie("quotedOrderWords");
  const [detectedLanguage, , clearLang] = useCookie("quotedOrderLang");
  const [time, , clearTime] = useCookie("quotedOrderTime");

  useEffect(() => {
    if (path) {
      if (
        // ignore the example quote file in the actual app
        path !==
        "87096a61765db56baf052e3c7204632fc0a41e797dc16db17dc7c56ee27e6294/monfichierdetraduction.docx"
      ) {
        const name = path.split("/").pop();
        const nonce = [...Array(10)]
          .map(() => (~~(Math.random() * 36)).toString(36))
          .join("");

        update({
          order: {
            ...order,
            time,
            words,
            detectedLanguage,
            attachments: [
              {
                name,
                words,
                path,
                nonce,
                type: "client",
                detectedLanguage,
              },
            ],
          },
        });
      }

      clearTime();
      clearLang();
      clearWords();
      clearPath();
    }
  }, [
    path,
    words,
    detectedLanguage,
    time,
    update,
    clearTime,
    clearLang,
    clearWords,
    clearPath,
    order,
  ]);
  if (!order) return null;
  let styleContainer = widget
    ? {
        marginTop: "0px",
        alignItems: "center",
      }
    : {
        marginTop: "75px",
        paddingBottom: "50px",
      };
  return (
    <>
      {!widget && (
        <Row className="header-container">
          <Col className="content-container">
            <Header />
          </Col>
        </Row>
      )}
      <Container style={styleContainer}>
        <div className="content-container body">
          <Row>
            <Col style={{ padding: 0 }}>
              {!widget && <ProgressBar />}
              <Switch>
                <Route
                  exact
                  path={["/order/:id"]}
                  render={(props) => <OrderForm {...props} />}
                />
                <Route
                  exact
                  path={["/order/:id", "/proofreading/:id"]}
                  render={(props) => <OrderForm {...props} />}
                />
                <Route
                  exact
                  path={["/order/:id/context", "/proofreading/:id/context"]}
                  render={(props) => <ContextForm {...props} />}
                />
                <Route
                  exact
                  path={["/order/new/widget", "/proofreading/new/widget"]}
                  render={(props) => (
                    <ArcherContainer endMarker={false}>
                      <WidgetOrderForm {...props} />{" "}
                    </ArcherContainer>
                  )}
                />
              </Switch>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

EditOrderPage.propTypes = {
  order: OrderPropType,
};

EditOrderPage.defaultProps = {
  order: null,
};

const stateToProps = (state) => ({
  order: getCurrentOrder(state),
  updatedOrder: getInProgressForm(state),
});

const dispatchToProps = (dispatch) => ({
  getOrder: (id) => {
    if (id === "new") {
      dispatch(loadLocalOrder());
    } else {
      dispatch(loadOrder({ id }));
    }
  },
  update: (order) => dispatch(updateOrder(order)),
});

export default connect(stateToProps, dispatchToProps)(EditOrderPage);
