import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { showChat, markNotificationClicked } from '../actions';
import { timezone } from '../constants';

const icons = new Map([
  ['Notification.SentMessage', 0xe914],
  ['Notification.Accepted', 0xe92b],
  ['default', 0xe92b],
  // ['Notification.tranlsationComplete', 0xe92d],
  // ['Notification.reviewTranslation', 0xe912],
]);
const getIcon = (action) => {
  const code = icons.get(action) || icons.get('default');
  return String.fromCharCode(code);
};

const NotificationItem = (props) => {
  const {
    notification: {
      _id,
      subject,
      subjectData,
      createdAt,
      senderDetails,
      raw,
      orderId,
      clicked,
    },
    doShowChat,
    doMarkClicked,
  } = props;

  const timeAgo = moment
    .min(moment(), moment.tz(createdAt, timezone))
    .fromNow();
  const icon = getIcon(subject);
  const readClass = clicked ? '' : 'notification--unread';
  const classes = `notification ${readClass}`;

  const handleClick = (e) => {
    const { history } = props;
    e.preventDefault();
    doMarkClicked(_id);

    switch (subject) {
      //CLIENT
      case 'Notification.QuotedOrderReady':
        history.push(`/confirm/${orderId}`);
        break;
      //TRANSLATOR

      case 'Notification.NewLanguage.VALIDATED':
      case 'Notification.NewLanguage.REMOVED':
      case 'Notification.NewLanguage.PENDING':
        history.push(`/settings/account/language`);
        break;

      case 'Notification.NewOffer':
        history.push(`/review/${orderId}`);
        break;

      //BOTH
      case 'Notification.SentMessage':
        doShowChat(orderId || 'ADMIN');
        break;

      default:
        if (orderId) {
          if (props.isTranslator) {
            history.push(`/translate/${orderId}`);
          } else {
            history.push(`/view/${orderId}`);
          }
        }
    }
  };
  return (
    <div onClick={handleClick} className={classes}>
      <Col sm="2" xs="4">
        {senderDetails && senderDetails.picture ? (
          <img
            src={senderDetails.picture}
            alt="notification"
            className="notification__image"
          />
        ) : (
          <div className="notification__image icon-brian" />
        )}
      </Col>
      <Col sm="10" xs="10">
        <div className="notification__from">
          {subjectData && `${subjectData.name} `}
          <FormattedMessage id={subject} />
        </div>
        <div className="notification__text">
          {raw && <div dangerouslySetInnerHTML={{ __html: raw }} />}
        </div>
        <div className="notification__icon" data-icon={icon} />
        <div className="notification__date">{timeAgo}</div>
      </Col>
    </div>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  doShowChat: PropTypes.func.isRequired,
  doMarkClicked: PropTypes.func.isRequired,
};

const actionsToProps = {
  doShowChat: showChat,
  doMarkClicked: markNotificationClicked,
};
export default withRouter(connect(null, actionsToProps)(NotificationItem));
