import React from "react";

const ModalNotif = ({ children, closeModal }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backgroundColor: "#00000044",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 3,
        padding: 10,
      }}
      onClick={closeModal}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: 30,
          borderRadius: 15,
          maxWidth: 500,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalNotif;
