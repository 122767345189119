import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../components/Header';
import HomePageClient from '../components/homePageComponents/HomePageClient';
import HomePageTranslator from '../components/homePageComponents/HomePageTranslator';
import { isTranslator } from '../account/selectors';
import './HomePage.module.scss';
import { Container, Row, Col } from 'reactstrap';

const HomePage = ({ isTranslator }) => {
  return (
    <>
      <Row className="header-container">
        <Col className="content-container">
          <Header
            left={
              isTranslator && (
                <Link
                  to="/translator/settings"
                  className="header__icon icon-gear-star grey"
                />
              )
            }
            center={
              isTranslator && (
                <Link to="/" className="header__icon icon-my-brian" />
              )
            }
          />
        </Col>
      </Row>

      <Container >
        <div className="content-container body">
          <Row>
            <Col>
              {isTranslator && <HomePageTranslator />}
              {!isTranslator && <HomePageClient />}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

const stateToProps = (state) => ({
  isTranslator: isTranslator(state),
});

export default connect(stateToProps)(HomePage);
