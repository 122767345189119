import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getLang } from './../selectors';
import { Form, Field } from 'react-final-form';
import TextField from './formfields/TextField';
import styles from '././PdfButton.module.scss';
import { connect } from 'react-redux';
import composeValidators from '../utils/composeValidators';
import SimpleCaptcha from './SimpleCaptcha';
import { updateOrder } from '../actions';


const PdfButton = ({
  order,
  lang,
  doUpdateOrder,
  pdfHasBeenSent,
  disabled,
  messageId,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [captchaIsValidated, setCaptchaIsValidated] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const required = (value) => (value ? undefined : 'Forms.Required');

  const isEmail = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Forms.InvalidEmail'
      : undefined;

  const sendPdf = (value) => {
    const quote = {
      ...order,
      quoteEmail: value.quoteEmail,
      quoteLang: lang,
      statusQuote: 'Sending',
    };
    doUpdateOrder({ order: quote });
    closeModal();
    pdfHasBeenSent(true);
  };
  const isCaptchaValidated = (response) => {
    setCaptchaIsValidated(response);
  };
  return (
    <>
      <Button
        onClick={openModal}
        disabled={disabled}
        className={disabled ? styles.disabled : styles.button}
      >
        <FormattedMessage id={messageId} />
      </Button>

      <Modal
        isOpen={modalIsOpen}
        toggle={closeModal}
        className={`${styles.modalOnModal}`}
      >
        <ModalHeader toggle={closeModal}>
          <FormattedMessage id="SendQuote.yourEmail" />
        </ModalHeader>
        <ModalBody className="p-5">
          <Form onSubmit={(value) => sendPdf(value)}>
            {({ handleSubmit, submitting, valid }) => (
              <form onSubmit={handleSubmit}>
                <Row className="my-2">
                  <Col xs={12}>
                    <Field
                      component={TextField}
                      name="quoteEmail"
                      label="Account.Email"
                      validate={composeValidators(required, isEmail)}
                    />
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col xs={12}>
                    <SimpleCaptcha isCaptchaValidated={isCaptchaValidated} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mx-2 my-2">
                    <Button
                      type="submit"
                      disabled={submitting || !valid || !captchaIsValidated}
                      className="button"
                    >
                      <FormattedMessage id="SendQuote.Submit" />
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

PdfButton.propTypes = {
  order: PropTypes.object,
  lang: PropTypes.string,
  disabled: PropTypes.bool,
};

PdfButton.defaultProps = {
  order: undefined,
  lang: undefined,
  disabled: true,
};

const stateToProps = (state) => ({
  lang: getLang(state),
});
const dispatchToProps = {
  doUpdateOrder: updateOrder,
};

export default connect(stateToProps, dispatchToProps)(PdfButton);
