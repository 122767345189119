import React from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { timezone } from '../constants';
import Thumbnail from '../attachments/Thumbnail';
import { getClientUploads } from '../orders/orderUtils';
import { FormattedMessage } from 'react-intl';

const TranslateLink = ({ order }) => {
  const deadline = moment.tz(order.deadline, timezone);
  const link = `/translate/${order._id}`;
  const uploads = getClientUploads(order);
  return (
    <Link to={link}>
      <div className="section-translation section-translation--translator">
        <div className="section-translation__text">
          {uploads.map((u) => (
            <div key={u.path}>
              <Thumbnail file={u.path} />
              <span>{u.name}</span>
            </div>
          ))}
          {order.text}
        </div>
        <div className="section-translation__type">
          <span>
            <FormattedMessage
              id={
                order.proofreading
                  ? 'OrderLink.Proofreading'
                  : 'OrderLink.Translation'
              }
            />
          </span>
        </div>
        <div className="section-translation__client">
          <span>{order.userDetails.givenName}</span>
        </div>
        <div className="section-translation__price">
          <div className="section-translation__price-inner">
            {accounting.formatMoney(order.pay, '', 2, '.', ',')}
          </div>
        </div>
        <div className="section-translation__timing">
          <div className="section-translation__timing-inner">
            {deadline.calendar()}
          </div>
          <div className="section-translation__arrow" />
        </div>
      </div>
    </Link>
  );
};

TranslateLink.propTypes = {
  order: PropTypes.object.isRequired,
};

export default TranslateLink;
