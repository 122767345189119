import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// ----------- REMOTE DEVTOOL FOR MOBILE -----------//
//import { composeWithDevTools } from 'remote-redux-devtools';
import reducers from './reducers';
import middleware, { sagaMiddleware } from './middleware';
import sagas from './sagas';
import { subscribe } from './feathers';
import { formActionSaga } from './utils/ReduxFormSaga';

export default function configureStore(initialState) {
  const composeEnhancers = composeWithDevTools;
  // --------- REMOTE DEVTOOL FOR MOBILE -------- //
  /*  const composeEnhancers = composeWithDevTools({
    realtime: true,
    hostname: '192.168.0.17',
    port: 3005,
    autoReconnect: true,
    connectTimeout: 20000,
  }); */
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(sagas);
  sagaMiddleware.run(formActionSaga);
  subscribe(store);

  return store;
}
