import { Capacitor } from "@capacitor/core";
import feathers from "@feathersjs/client";
import Realtime from "feathers-offline-realtime";
import reduxifyServices, { getServicesStatus } from "feathers-redux";
import reduxifyAuthentication from "feathers-reduxify-authentication";
import { find } from "lodash";
import io from "socket.io-client";

import { getUser, isTranslator } from "./account/selectors";
import cookie from "./cookie";

export const socket = io(
  Capacitor.isNativePlatform
    ? process.env.REACT_APP_API_URL_MOBILE
    : process.env.REACT_APP_API_URL
);

// Configure feathers-client
const app = feathers()
  .configure(feathers.socketio(socket, { timeout: 200000 }))
  .configure(
    feathers.authentication(
      Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android"
        ? { storage: window.localStorage }
        : {
            storage: cookie,
          }
    )
  );

export default app;

// Customise processing in feathers/index.js for your app

// See feathers-reduxify-services::default
export const mapServicePathsToNames = {
  users: "users",
  orders: "orders",
  messages: "messages",
  uploads: "uploads",
  notifications: "notifications",
  settings: "settings",
  companies: "companies",
  invoices: "invoices", // added from translator
  specialities: "specialities",
  languagecouples: "languagecouples"
};

// See feathers-reduxify-services::getServicesStatus. Order highest priority msg first.
export const prioritizedListServices = [
  "auth",
  "users",
  "orders",
  "messages",
  "uploads",
  "notifications",
  "settings",
  "companies",
  "invoices",
];
// Reduxify feathers-authentication
export const feathersAuthentication = reduxifyAuthentication(app);

// Reduxify feathers services
export const feathersServices = reduxifyServices(app, mapServicePathsToNames);

// Convenience method to get status of feathers services, incl feathers-authentication
export const getFeathersStatus = (
  servicesRootState,
  names = prioritizedListServices
) => getServicesStatus(servicesRootState, names);

const realtimeSubscriptions = [];
export const subscribe = (store) => {
  let publications = null;
  if (isTranslator()) {
    publications = {
      orders: (order) => {
        const translator = getUser(store.getState());
        if (order.translator === translator) return true;
        if (order.reviewer === translator) return true;
        if (order.status === "Order.Status.Unassigned") {
          return (
            find(order.offeredTo, (offer) => offer.translator === translator) &&
            !find(order.rejected, (offer) => offer.translator === translator)
          );
        }
        return false;
      },
    };
  } else {
    publications = {
      "unassigned-orders": (order) =>
        order.status === "Order.Status.Unassigned",
    };
  }

  Object.keys(mapServicePathsToNames).forEach((s) => {
    const service = app.service(s);
    const SERVICE_NAME = `SERVICES_${mapServicePathsToNames[s].toUpperCase()}_`;

    service.on("created", (data) => {
      store.dispatch({
        type: `${SERVICE_NAME}CREATE_RECEIVED`,
        payload: data,
      });
    });

    service.on("updated", (data) => {
      console.log("update");
      store.dispatch({
        type: `${SERVICE_NAME}UPDATE_RECEIVED`,
        payload: data,
      });
    });

    service.on("patched", (data) => {
      console.log("patched");
      store.dispatch({
        type: `${SERVICE_NAME}PATCH_RECEIVED`,
        payload: data,
      });
    });

    service.on("removed", (data) => {
      store.dispatch({
        type: `${SERVICE_NAME}REMOVE_RECEIVED`,
        payload: data,
      });
    });

    if (!["uploads", "settings"].includes(s)) {
      const subscription = new Realtime(service, {
        publication: publications[s] ? publications[s] : null,
        subscriber: (records, last) => {
          store.dispatch(
            feathersServices[mapServicePathsToNames[s]].store({
              connected: subscription.connected,
              last,
              records,
            })
          );
        },
      });

      realtimeSubscriptions.push(subscription);
    }
  });
};

export const connect = () => {
  realtimeSubscriptions.forEach((sub) => sub.connect());
};

export const disconnect = () => {
  realtimeSubscriptions.forEach((sub) => sub.disconnect());
};
