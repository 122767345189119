import PropTypes from "prop-types";
import React from "react";
import { ButtonGroup } from "react-bootstrap";

import FieldHolder from "./FieldHolder";

const ButtonGroupField = (props) => {
  const { input, options } = props;
  return (
    <FieldHolder {...props}>
      <ButtonGroup>
        {options.map(({ value, text }) => (
          <ButtonGroup key={value}>
            <button
              className={`button ${input.value === value && "active"}`}
              onClick={(e) => {
                e.preventDefault();
                input.onChange(value);
              }}
            >
              {text}
            </button>
          </ButtonGroup>
        ))}
      </ButtonGroup>
    </FieldHolder>
  );
};

ButtonGroupField.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  justified: PropTypes.bool,
};

ButtonGroupField.defaultProps = {
  justified: false,
};

export default ButtonGroupField;
