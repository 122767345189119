import accounting from "accounting";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import momentPropTypes from "react-moment-proptypes";

import { widget } from "../constants";
import SplitPane from "../layout/SplitPane";
import DateTime from "./DateTime";
import styles from "./DeadlineAndPrice.module.scss";

const DeadlineAndPrice = ({
  price,
  priceWithTax,
  discountReason,
  discountedPrice,
  discountedPriceWithTax,
  deadline,
  completedAt,
  locale,
  order,
}) => {
  const both = price && priceWithTax;
  let title;

  if (both) {
    title = "Order.Confirm.Price";
  } else if (priceWithTax) {
    title = "Order.Confirm.Price.WithTax";
  } else {
    title = "Order.Confirm.Price.ExcludingTax";
  }
  const priceToShow = discountReason ? discountedPrice : price;
  const priceWithTaxToShow = discountReason
    ? discountedPriceWithTax
    : priceWithTax;

  const taxExcluded = priceToShow === priceWithTaxToShow;
  let displayDeadLinePriceOnWidget =
    widget &&
    (order.proofreading
      ? !!order.selectedLanguage
      : !!order.destinationLanguage) &&
    (order.attachments.length || order.words > 0);
  const isFinished = !["Order.Status.Unpaid","Order.Status.Manual","Order.Status.Quoted"].includes(order.status)
  //["Order.Status.Cancelled","Order.Status.Complete","Order.Status.InProgress","Order.Status.Manual","Order.Status.Quoted","Order.Status.Suspended","Order.Status.Unassigned","Order.Status.Unpaid"]

  if (!widget) displayDeadLinePriceOnWidget = true;

  return (
    <>
      <SplitPane
        centerline
        leftTitle={title}
        rightTitle="Order.Confirm.Deadline"
        className="flex-center"
        left={
          <div className={styles.DeadlineContainer}>
            {displayDeadLinePriceOnWidget ? (
              <div className={styles.Prices}>
                {!widget && order.averageRedundancy > 0 && !isFinished && (
                  <strike style={{}}>
                    {accounting.formatMoney(price, "", 2, ".", ",")}€
                  </strike>
                )}
                {priceToShow && (
                  <div className={styles.Price}>
                    {discountReason && "*"}
                    {accounting.formatMoney(priceToShow, "", 2, ".", ",")}€
                    {both && <span> HT</span>}
                  </div>
                )}
                {priceWithTax && !taxExcluded && (
                  <div className={styles.PriceWithTax}>
                    {discountReason && "*"}
                    {accounting.formatMoney(
                      priceWithTaxToShow,
                      "",
                      2,
                      ".",
                      ","
                    )}
                    {both && <span> TTC</span>}
                  </div>
                )}
                {taxExcluded && (
                  <div className={styles.PriceWithTax}>
                    <span className={styles.VATExempt}>
                      <FormattedMessage id="Billing.VATExempt" />
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.PriceContainer}>
                <div className={styles.PriceInit}>
                  <FormattedMessage id="Order.Confirm.Deadline" />
                </div>
                <div className="text-center"> - -</div>
              </div>
            )}
          </div>
        }
        right={
          <div className={styles.PriceContainer}>
            {displayDeadLinePriceOnWidget ? (
              <>
                <DateTime
                  dateTime={deadline}
                  title={completedAt ? "Order.Confirm.Deadline" : null}
                  locale={locale}
                />
                {!!completedAt && (
                  <DateTime
                    dateTime={completedAt}
                    title="Invoice.Completed"
                    locale={locale}
                  />
                )}
              </>
            ) : (
              <>
                <div className={styles.PriceInit}>
                  <FormattedMessage id="Order.Confirm.Price.ExcludingTax" />
                </div>
                <div className="text-center"> - -</div>
              </>
            )}
          </div>
        }
      />

      {discountReason && discountReason !== "Redundant" ? (
        <div className="section-options__discountReason">
          *
          <FormattedMessage id={discountReason} />
        </div>
      ) : null}
    </>
  );
};

DeadlineAndPrice.propTypes = {
  price: PropTypes.number,
  priceWithTax: PropTypes.number,
  discountReason: PropTypes.string,
  discountedPrice: PropTypes.number,
  discountedPriceWithTax: PropTypes.number,
  deadline: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    momentPropTypes.momentObj,
  ]).isRequired,
  completedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  locale: PropTypes.string,
};

DeadlineAndPrice.defaultProps = {
  price: null,
  priceWithTax: null,
  discountReason: null,
  discountedPrice: null,
  discountedPriceWithTax: null,
  completedAt: null,
  locale: "fr",
};

export default DeadlineAndPrice;
