import React, { useState } from "react";
import { feathersServices } from "../../feathers";
import style from "./FavoriteNoResponse.module.scss";
import { FormattedMessage, useIntl } from "react-intl";

const FavoriteNoResponse = ({ closeModal, doShowChat, favNotif }) => {
  const [selectOther, setSelectOther] = useState(false);
  const [selectWaitFavorite, setSelectWaitFavorite] = useState(false);

  const askOther = async () => {
    await feathersServices.orders.patch(favNotif.orderId, {
      onlyFavTranslator: false,
    });
    await feathersServices.notifications.patch(favNotif._id, {
      clicked: new Date(),
      seen: new Date(),
    });
    setSelectOther(true);
  };

  const waitForFavorites = async () => {
    await feathersServices.notifications.patch(favNotif._id, {
      clicked: new Date(),
      seen: new Date(),
    });
    setSelectWaitFavorite(true);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {!selectOther && !selectWaitFavorite && (
        <>
          <p className={style.title}>
            <FormattedMessage id="Modal.Notif.Title" />
          </p>
          <div className="flex" style={{ marginBottom: 10 }}>
            <div style={{ width: "50%", marginRight: 5 }}>
              <p className={style.boxTitle}>
                <FormattedMessage id="Modal.Notif.Wait" />
              </p>
              <div
                className={style.messageContainer}
                onClick={waitForFavorites}
              >
                <span>
                  <FormattedMessage id="Modal.Notif.Ask" />
                </span>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <p className={style.boxTitle}>
                <FormattedMessage id="Modal.Notif.Extend" />
              </p>
              <div className={style.messageContainer} onClick={askOther}>
                <span>
                  <FormattedMessage id="Modal.Notif.Other" />
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      {selectWaitFavorite && (
        <div>
          <div
            className="flex-center"
            style={{ width: "100%", marginBottom: 20 }}
          >
            <img src="/check.png" width="75px" />
          </div>
          <p className={style.title}>
            <FormattedMessage id="Modal.Notif.Wait.Confirm" />
          </p>
        </div>
      )}
      {selectOther && (
        <div>
          <div
            className="flex-center"
            style={{ width: "100%", marginBottom: 20 }}
          >
            <img src="/check.png" width="75px" />
          </div>
          <p className={style.title}>
            <FormattedMessage id="Modal.Notif.Extend.Confirm" />
          </p>
        </div>
      )}
      <span className={style.help} onClick={doShowChat}>
        <FormattedMessage id="Modal.Notif.Question" />{" "}
        {!selectOther && !selectWaitFavorite && (
          <FormattedMessage id="Modal.Notif.Question.Before" />
        )}
        ?
      </span>
    </div>
  );
};

export default FavoriteNoResponse;
