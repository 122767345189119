/**
 * Created by Al on 30/03/2017.
 */
import { Capacitor } from "@capacitor/core";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { parse } from "query-string";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { getProfile, getUser } from "../account/selectors";
import { startLogin } from "../actions";
import storage from "../cookie";
import { socket } from "../feathers";

const API_LOGIN = "/oauth/auth0";
// const API_LOGIN_MOBILE = '/auth/auth0-app';
const API_LOGIN_MOBILE = API_LOGIN;
export const REDIRECT_KEY = "mybrian-redirect";

const Login = (props) => {
  useEffect(() => {
    authOrLog();
    // eslint-disable-next-line
  }, []);

  const authOrLog = async () => {
    const query = parse(props.location.search);
    console.log(query);
    if (!query.token) {
      await storage.removeItem("feathers-jwt");
      redirectToAuth();
    } else {
      login();
    }
  };

  const login = () => {
    const query = parse(props.location.search);

    storage.setItem("feathers-jwt", query.token);
    if (localStorage[REDIRECT_KEY]) {
      const redirectTo = localStorage.getItem(REDIRECT_KEY);
      localStorage.removeItem(REDIRECT_KEY);
      window.location.href = redirectTo;
    } else {
      window.location.href = `${window.location.protocol}//${window.location.host}`;
    }
  };

  const redirectToAuth = async () => {
    const query = parse(props.location.search);
    const windowIfDefined = typeof window === "undefined" ? null : window;
    if (windowIfDefined) {
      let redirectTo = `${windowIfDefined.location.protocol}//${windowIfDefined.location.host}`;

      if (query.redirect) {
        redirectTo += query.redirect;
      }

      localStorage.setItem(REDIRECT_KEY, redirectTo);
      console.log("Device :", Capacitor.getPlatform());
      if (
        Capacitor.getPlatform() === "ios" ||
        Capacitor.getPlatform() === "android"
      ) {
        console.log("HERE");
        try {
          const browser = InAppBrowser.create(
            `${process.env.REACT_APP_API_URL_MOBILE}${API_LOGIN_MOBILE}`,
            "_blank",
            "location=no"
          );
          browser.show();
          // Try to get token in url after auth0 login
          browser.on("loadstart").subscribe(async (ev) => {
            const query = {};
            const pairs = ev.url.substr(ev.url.indexOf("?") + 1).split("&");
            for (let i = 0; i < pairs.length; i += 1) {
              const pair = pairs[i].split("=");
              query[decodeURIComponent(pair[0])] = decodeURIComponent(
                pair[1] || ""
              );
            }

            let { token } = query;

            if (token) {
              // Google accounts on Android inexplicably have "#" at the end of the URL
              if (token.includes("#")) {
                token = token.substring(0, token.indexOf("#"));
              }
              await localStorage.setItem("feathers-jwt", token);
              socket.connect();
              browser.close();
              props.dispatch(startLogin({ browserHistory: props.history }));
            }
          });
          // If user close browser
          browser.on("exit").subscribe(
            () => {
              props.history.goBack();
            },
            (err) => {
              console.error("Browser exit error", err);
            }
          );
        } catch (e) {
          console.log("Browser opening error", e);
        }
      } else {
        windowIfDefined.location.href = `${process.env.REACT_APP_API_URL}${API_LOGIN}`;
      }
    }
  };

  const { profile } = props;
  console.log(props, profile);
  if (profile) {
    if (localStorage[REDIRECT_KEY]) {
      const redirectTo = localStorage.getItem(REDIRECT_KEY);
      localStorage.removeItem(REDIRECT_KEY);
      console.log("Redirecting to: " + redirectTo);
      window.location.href = redirectTo;
    } else {
      window.location.href = `${window.location.protocol}//${window.location.host}`;
    }
  }

  return (
    <div>
      <FormattedMessage id="Loading" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  profile: getProfile(state),
});

export default connect(mapStateToProps)(Login);
