import { get, filter } from 'lodash';

export const getMessages = (state, orderId) => {
  // if we are looking for messages to admin, there is actually no order set
  const order = orderId === 'ADMIN' ? null : orderId;
  return filter(get(state, 'messages.store.records', []), { order });
};

export const getIsMessageSaving = (state) => state.messages.isSaving;
export const getIsMessageLoading = (state) => state.messages.isLoading;

export const getChat = (state) => state.app.showChat;
