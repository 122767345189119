import React from 'react';
import accounting from 'accounting';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { timezone } from '../constants';
import Thumbnail from '../attachments/Thumbnail';
import OrderStatus from './OrderStatus';
import OrderPropType from './../common/proptypes/OrderPropType';
import { getClientUploads } from './orderUtils';
import styles from './OrderLink.module.scss';
import { FormattedMessage } from 'react-intl';

const OrderLink = (props) => {
  const { order, margin } = props;
  const uploads = getClientUploads(order);
  const deadline = moment.tz(order.deadline, timezone);

  let link;

  switch (order.status) {
    case 'Order.Status.Unpaid':
      link = `/order/${order._id}`;
      break;
    case 'Order.Status.Quoted':
      link = `/confirm/${order._id}`;
      break;
    default:
      link = `/view/${order._id}`;
  }

  const price = order.discountReason ? order.discountedPrice : order.price;
  return (
    <div className={margin ? 'section-translation__wrapper' : ''}>
      <Link to={link}>
        <div className="section-translation section-translation--client">
          <div className={styles.OrderInfo}>
            {uploads.map((u) => (
              <div key={u.path} className={styles.UploadInfo}>
                <Thumbnail file={u.path} />
                {u.name}
              </div>
            ))}
            {!uploads.length && (
              <div className={styles.UploadInfo}>
                <Thumbnail file="text.txt" />
                {order.text && order.text.substr(0, 50)}
              </div>
            )}
          </div>
          <div className="section-translation__type">
            <div className="">
              <FormattedMessage
                id={
                  order.proofreading
                    ? 'OrderLink.Proofreading'
                    : 'OrderLink.Translation'
                }
              />
            </div>
          </div>

          {price !== undefined && (
            <div className="section-translation__price">
              <div className="section-translation__price-inner">
                {accounting.formatMoney(price, '', 2, '.', ',')}
              </div>
            </div>
          )}

          <div className="section-translation__timing">
            <div className="section-translation__timing-inner">
              {deadline.calendar()}
            </div>
            <div className="section-translation__arrow" />
          </div>
        </div>
      </Link>
      <OrderStatus order={order} />
    </div>
  );
};

OrderLink.propTypes = {
  order: OrderPropType.isRequired,
  margin: PropTypes.bool,
};

OrderLink.defaultProps = {
  margin: false,
};

export default OrderLink;
