import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import styles from './TimePickerField.module.scss';

const TimePickerField = ({
  input,
  marks,
  max,
  meta: {
    data: { error, warning },
  },
  onBeforeChange,
  onAfterChange,
}) => {
  const state = error ? "error" : warning ? "warning" : null;
  return (
    <FormGroup controlId={input.name} validationState={state}>
      <div className="field">
        <Slider
          marks={marks}
          max={max}
          onBeforeChange={onBeforeChange}
          onAfterChange={onAfterChange}
          trackStyle={{ backgroundColor: '#F6446B' }}
          railStyle={{ backgroundColor: '#1A496B' }}
          {...input}
        />

        {warning}
      </div>
      <div className={styles.Tips}>
        <span className={styles.Fast}>
          <FormattedMessage id="Widget.TimePicker.Fast" />
        </span>
        <span className={styles.Help}>
          <FormattedMessage id="Widget.TimePicker.DragSlider" />
        </span>
        <span className={styles.Cheap}>
          <FormattedMessage id="Widget.TimePicker.Affordable" />
        </span>
      </div>

    </FormGroup>
  );
};

TimePickerField.propTypes = {
  input: PropTypes.object.isRequired,
  marks: PropTypes.object,
  max: PropTypes.number,
  meta: PropTypes.object,
  tooltip: PropTypes.object,
};

export default TimePickerField;
