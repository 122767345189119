import {
  faCloudUploadAlt,
  faPlusCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";

import { isTranslator } from "../../account/selectors";
import { widget } from "../../constants";
import fileImg from "../../img/upload-cloud.png";
import styles from "./UploadField.module.scss";

const UploadField = ({
  accept,
  className,
  handleDrop,
  uploading,
  progress,
  disabled,
  uploadMessage,
  showAsButton,
  loader,
}) => {
  const intl = useIntl();
  accept =
    accept ||
    ".csv , .docx , .idml , .jpeg , .png , .pdf , .srt , .markdown/md , .pages , .json , .po , .pot , .potx , .pptx , .tmx , .xliff/xlf , .xlsx , .txt";
  // "application/pdf, image/png, image/jpeg, image/gif, application/vnd, .oasis.opendocument.graphics, application/vnd.oasis.opendocument.text, text/plain, text/html, application/atom+xml, application/rss+xml, application/xml,text/xml, application/vnd.ms-excel, text/markdown, application/epub+zip,     application/vnd.oasis.opendocument.text,     application/vnd.oasis.opendocument.text-template, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel,     application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.binary.macroenabled.12, application/vnd.ms-excel.sheet.macroenabled.12, application/vnd.openxmlformats-officedocument.spreadsheetml.template, text/csv, application/vnd.oasis.opendocument.spreadsheet-template, application/vnd.oasis.opendocument.spreadsheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.presentationml.template, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.presentation-template, application/rtf, .srt,application/vnd.adobe.indesign-idml-package,.idml, .xlf,application/xliff+xml";
  return (
    <Dropzone accept={accept} onDrop={handleDrop} disabled={disabled}>
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
        <div
          className={`${className} ${isDragAccept ? styles.Accept : ""} ${
            isDragReject ? styles.Reject : ""
          } ${disabled ? styles.Disabled : ""}`}
          {...getRootProps()}
        >
          {uploading ? (
            <div className={styles.UploadingContainer}>
              <div className={styles.ProgressHolder}>
                <span
                  className={styles.ProgressBar}
                  style={{ width: `${progress}%` }}
                />
              </div>
              <div className={styles.Uploading}>
                <FormattedMessage id="Order.Uploading" />
              </div>
            </div>
          ) : (
            <div
              className={
                styles.WidgetUploader + ` ${widget ? "" : "justify-start"}`
              }
            >
              <input {...getInputProps()} accept={accept} />
              <div
                className={`${showAsButton ? "uploadPlus" : "uploader"} ${
                  widget ? "flex-center" : ""
                }`}
              >
                {!showAsButton && (
                  <span className="blue">
                    <span className="bold">
                      <FormattedMessage id="DropZone.Drop" />
                    </span>{" "}
                    <FormattedMessage id="DropZone.Files" />
                  </span>
                )}
                {!loader ? (
                  <FontAwesomeIcon
                    className="blue"
                    icon={showAsButton ? faPlusCircle : faCloudUploadAlt}
                    fontSize="20px"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    title={"Loading"}
                    className={"fa-spin"}
                  />
                )}
                {/* <img
                  src={fileImg}
                  alt={intl.formatMessage({ id: "File" })}
                  height={30}
                /> */}
              </div>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
};

UploadField.propTypes = {
  accept: PropTypes.string,
  handleDrop: PropTypes.func,
  uploading: PropTypes.bool,
  progress: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  uploadMessage: PropTypes.string,
  isTranslator: PropTypes.bool,
};

UploadField.defaultProps = {
  accept: "",
  handleDrop: () => {},
  uploading: false,
  progress: 0,
  disabled: false,
  uploadMessage: "File.Drop",
  className: "",
  children: null,
  isTranslator: isTranslator(),
};
export default UploadField;
