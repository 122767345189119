import React from "react";
import styles from "./Text.module.scss";

const Text = ({ children, fontSize, fontWeight, id }) => {
  return (
    <span id={id} className={styles.text} style={{ fontSize, fontWeight }}>
      {children}
    </span>
  );
};

export const Title = ({ children }) => {
  return <span className={styles.title}>{children}</span>;
};

export default Text;
