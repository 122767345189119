import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { get } from "lodash";
import PropTypes from "prop-types";
import { hideChat, notifyViewingChat } from "../../actions";
import OrderPropType from "./../../common/proptypes/OrderPropType";

import Chat from "./Chat";
import OrderSummary from "../../orders/OrderSummary";
import { getOrderById } from "../../orders/selectors";
import ActionLink from "../ActionLink";

import style from "./ChatWindow.module.scss";
import { Link } from "react-router-dom";

const ChatWindow = ({ orderId, close, order, doNotifyViewingChat, admin }) => {
  const person = get(order, "translatorDetails.givenName", false);

  useEffect(() => {
    doNotifyViewingChat({
      recipient: null, // used by admins only
      order: admin ? "admin" : orderId
    });
    return () => {
      doNotifyViewingChat(null);
    };
  }, [orderId, doNotifyViewingChat, admin]);

  if (orderId) {
    return (
      <div className="chat__container">
        <div className={style.Header}>
          <div>
            <ActionLink onClick={() => close()} className="icon-cross pink" />
          </div>
          <div>
            <span className="dark">
              {admin && <FormattedMessage id="Chat.Admin" />}
              {person && (
                <FormattedMessage
                  id="Chat"
                  values={{
                    person
                  }}
                />
              )}
              {!(person || admin) && (
                <FormattedMessage id="Chat.LeaveMessage" />
              )}
            </span>
          </div>
          {admin && (
            <div>
              <Link to="/settings/contact">
                <span className={style.Phone}></span>
              </Link>
            </div>
          )}
        </div>
        <OrderSummary order={order} />
        <Chat orderId={orderId} />
      </div>
    );
  }
  return null;
};

ChatWindow.propTypes = {
  admin: PropTypes.bool,
  close: PropTypes.func.isRequired,
  doNotifyViewingChat: PropTypes.func.isRequired,
  order: OrderPropType,
  orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

ChatWindow.defaultProps = {
  admin: false,
  order: null,
  orderId: null
};
const stateToProps = (state, ownProps) => ({
  order: getOrderById(state, ownProps.orderId)
});

const dispatchToProps = dispatch => ({
  close: () => dispatch(hideChat()),
  doNotifyViewingChat: id => dispatch(notifyViewingChat(id))
});

export default connect(stateToProps, dispatchToProps)(ChatWindow);
