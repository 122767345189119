import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getInProgressOrdersForTranslator,
  getOfferedOrders,
} from "../../orders/selectors";
import { getProfile } from "../../account/selectors";
import ReviewLink from "../ReviewLink";
import TranslateLink from "../TranslateLink";
import Button from "../Button";
import Card from "../../layout/Card";
import { Row, Col } from "reactstrap";

const offeredOrdersHTML = (offeredOrders, profile) => {
  let html = null;

  if (!offeredOrders.length) {
    if (profile.available) {
      html = (
        <div className="section-general section-general--grey">
          <FormattedMessage id="TranslatorHomePage.NoOfferedProjects" />
        </div>
      );
    } else {
      html = (
        <div className="section-general section-general--grey">
          <FormattedMessage id="TranslatorHomePage.NotAvailable" />
        </div>
      );
    }
  } else {
    html = offeredOrders.map((order) => (
      <ReviewLink key={order._id} order={order} />
    ));
  }

  return html;
};

const acceptedOrdersHTML = (acceptedOrders) => {
  let html = null;

  if (!acceptedOrders.length) {
    html = (
      <div className="section-general section-general--grey">
        <FormattedMessage id="TranslatorHomePage.NoProjects" />
      </div>
    );
  } else {
    html = acceptedOrders.map((order) => (
      <TranslateLink key={order._id} order={order} />
    ));
  }

  return html;
};

const HomePageTranslator = ({ acceptedOrders, offeredOrders, profile }) => {
  const history = useHistory();
  if (profile && !profile.taxStatus) {
    history.push("/settings/account/info-billing");
  }
  const showSuccess = false; // /success/.test(window.location.search);
  const linkToLogin = (
    <Link to="/login">
      <FormattedMessage id="Login" />
    </Link>
  );
  return (
    <div className="body">
      {!!profile && (
        <h1 className="helloName mb-4">
          <FormattedMessage
            id="CustomerHomepage.PageTitle"
            values={{ givenName: profile.givenName }}
          />
        </h1>
      )}
      {profile ? (
        <div>
          {/* ----------------------------- MANUEL ------------------------------- */}
          <Card fill className="BigMargin">
            <Row>
              <Col xs="6">
                <NavLink
                  to={{ pathname: "https://www.mybrian.fr/guide-traducteur/" }}
                  target="_blank"
                >
                  <Button buttonType="CTA" onClick={()=>(null)}>
                    <FormattedMessage id="TranslatorHomePage.TransGuide" />
                  </Button>
                </NavLink>
              </Col>
              <Col xs="6">
                <NavLink
                  to={{
                    pathname:
                      "https://www.mybrian.fr/instructions-mise-en-page/",
                  }}
                  target="_blank"
                >
                  <Button buttonType="CTA" onClick={()=>(null)}>
                    <FormattedMessage id="TranslatorHomePage.LayoutGuide" />
                  </Button>
                </NavLink>
              </Col>
            </Row>
          </Card>

          {/* ----------------------------- SUCCESS MESSAGE ------------------------------- */}
          <div
            className={`section-success-message ${
              showSuccess ? "section-success-message--show" : ""
            }`}
          >
            <FormattedMessage id="Translator.Order.Success" />
          </div>

          {/* ----------------------------- PROJECTS ON OFFER ------------------------------- */}

          <div className="Card">
            <div className="Title">
              <FormattedMessage id="TranslatorHomePage.OfferedProjectsTitle" />
            </div>

            <div className="section-translation__wrapper">
              {offeredOrdersHTML(offeredOrders, profile)}
            </div>
          </div>

          {/* ----------------------------- ACCEPTED ORDERS ------------------------------- */}

          <div className="Card">
            <div className="Title">
              <FormattedMessage id="TranslatorHomePage.ProjectsTitle" />
            </div>
            <div className="section-translation__wrapper">
              {acceptedOrdersHTML(acceptedOrders)}
            </div>
          </div>

          {/* ----------------------------- COMPLETED TRANSLATIONS ------------------------------- */}

          <div className="section-general section-general--grey section-general--no-border">
            <Link to="/translator/history" className="button">
              <FormattedMessage id="TranslatorHomePage.History" />
            </Link>
          </div>
        </div> // Otherwise show a login prompt
      ) : (
        <div className="body">
          <div className="Card">
            <div className="Title">
              <FormattedMessage id="Admin.Login" values={{ linkToLogin }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

HomePageTranslator.propTypes = {
  acceptedOrders: PropTypes.array.isRequired,
  offeredOrders: PropTypes.array.isRequired,
  profile: PropTypes.object,
};

HomePageTranslator.defaultProps = {
  profile: undefined,
};

const stateToProps = (state) => ({
  acceptedOrders: getInProgressOrdersForTranslator(state),
  offeredOrders: getOfferedOrders(state),
  profile: getProfile(state),
});

export default connect(stateToProps)(HomePageTranslator);
