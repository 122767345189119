import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Row, Col } from 'reactstrap';
import TextareaField from '../components/formfields/TextareaField';

const RejectionModal = ({
  handleReject,
  pristine,
  submitting,
  showNotGeneral,
  backFromRejectionModal,
}) => {
  return (
    <>
      <Row className="mx-1">
        <Col>
          <label htmlFor="reason-1">
            <Field
              name="reason.TooBusy"
              id="reason-1"
              component="input"
              type="checkbox"
              value="busy"
            />
            <FormattedMessage id="Translator.Reject.Reason.TooBusy" />
          </label>
        </Col>
      </Row>
      <Row className="mx-1">
        <Col>
          <label htmlFor="reason-2">
            <Field
              name="reason.NotEnough"
              id="reason-2"
              component="input"
              type="checkbox"
              value="money"
            />
            <FormattedMessage id="Translator.Reject.Reason.NotEnough" />
          </label>
        </Col>
      </Row>
      <Row className="mx-1">
        <Col>
          <label htmlFor="reason-3">
            <Field
              name="reason.Deadline"
              id="reason-3"
              component="input"
              type="checkbox"
              value="deadline"
            />
            <FormattedMessage id="Translator.Reject.Reason.Deadline" />
          </label>
        </Col>
      </Row>
      <Row className="mx-1">
        <Col>
          <label htmlFor="reason-4">
            <Field
              name="reason.IncorrectLanguage"
              id="reason-4"
              component="input"
              type="checkbox"
              value="language"
            />
            <FormattedMessage id="Translator.Reject.Reason.IncorrectLanguage" />
          </label>
        </Col>
      </Row>
      {showNotGeneral && (
        <Row className="mx-1">
          <Col>
            <label htmlFor="reason-5">
              <Field
                name="reason.NotGeneralist"
                id="reason-5"
                component="input"
                type="checkbox"
                value="not-generalist"
              />
              <FormattedMessage id="Translator.Reject.Reason.NotGeneralist" />
            </label>
          </Col>
        </Row>
      )}
      <Row className="mx-1">
        <Col>
          <label htmlFor="reason-6">
            <Field
              name="reason.NotMyExpertise"
              id="reason-6"
              component="input"
              type="checkbox"
              value="bad-fit"
            />
            <FormattedMessage id="Translator.Reject.Reason.NotMyExpertise" />
          </label>
        </Col>
      </Row>
      <Row className="mx-1">
        <Col>
          <label htmlFor="reason-7">
            <Field
              name="reason.Software"
              id="reason-7"
              component="input"
              type="checkbox"
              value="software"
            />
            <FormattedMessage id="Translator.Reject.Reason.Software" />
          </label>
        </Col>
      </Row>
      <Row className="mx-1">
        <Col>
          <label htmlFor="reason-8">
            <Field
              name="reason.Other"
              id="reason-8"
              component="input"
              type="checkbox"
              value="other"
            />
            <span className="d-inline-block mb-1">
              <FormattedMessage id="Translator.Reject.Reason.Other" />
            </span>

            <Field
              component={TextareaField}
              rows={2}
              name="reason.otherDetail"
              placeholder=""
              id=""
            />
          </label>
        </Col>
      </Row>
      <Row className="mx-1 my-2">
        <Col md={{ size: 5, offset: 1 }} xs="6" className="text-center">
          <button
            className="button"
            onClick={handleReject}
            disabled={pristine || submitting}
          >
            <FormattedMessage id="Forms.Submit" />
          </button>
        </Col>
        <Col md={{ size: 5, offset: 0 }} xs="6" className="text-center">
          <button
            className="button btn-secondary"
            onClick={() => backFromRejectionModal()}
          >
            <FormattedMessage value="NotNow" id="Cancel" />
          </button>
        </Col>
      </Row>
    </>
  );
};

// RejectionModal = reduxForm({
//   form: "RejectionFormWizard",
//   destroyOnUnmount: true,
//   forceUnregisterOnUnmount: true,
// })(RejectionModal);
// export default connect()(RejectionModal);

export default reduxForm({
  form: 'RejectionFormWizard',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(RejectionModal);
