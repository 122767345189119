/**
 * Created by Al on 30/03/2017.
 *
 * These sagas describe actions that take more than just one step to perform a function
 *
 * for example, after a login, user details must be fetched
 * or after submitting a form, details must be extracted and sent via the api followed by a redirect
 */
import 'moment/locale/fr';

import { Capacitor,  } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

import { InAppBrowser } from '@ionic-native/in-app-browser';
import { find, get } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { updateIntl } from 'react-intl-redux';
import Notifications from 'react-notification-system-redux';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { getProfile, isLoggingIn, isTranslator } from './account/selectors';
import {
  deregisterFromPushNotifications,
  registerForPushNotifications,
} from './notifications/PushNotifications';
import {
  ACCEPT_ORDER,
  ACCEPT_START_ORDER,
  AGREE_TO_TERMS,
  AUTH_FULFILLED,
  AUTH_REJECTED,
  CONFIRM_ORDER,
  CREATE_ORDER,
  CREATE_ORDER_FULFILLED,
  fulfillLoadOrder,
  GET_INVOICE,
  GET_USER,
  HIDE_CHAT,
  LOAD_INVOICES,
  LOAD_LOCAL_ORDER,
  LOAD_LOCAL_ORDER_FULFILLED,
  LOAD_ORDER,
  LOAD_SETTINGS,
  LOGOUT,
  MARK_NOTIFICATION_CLICKED,
  MARK_NOTIFICATIONS_READ,
  NOTIFICATIONS_CREATE_RECEIVED,
  NOTIFY_VIEWING_CHAT,
  PATCH_ORDER_FAILED,
  PATCH_ORDER_FULFILLED,
  PATCH_ORDERS_FULFILLED,
  RATE_ORDER,
  REJECT_ORDER,
  REJECT_ORDER_REDIRECT,
  REMOVE_FILE,
  REMOVE_LOCAL_ORDER_FULFILLED,
  REMOVE_ORDER,
  REMOVE_ORDER_FULFILLED,
  REQUEST_MANUAL_SEARCH,
  REQUEST_REFUND,
  REVIEW_ORDER,
  SAVE_ORDER_PROGRESS,
  SET_TRANSLATOR_AVAILABLE,
  SETTINGS_LOADED,
  START_LOGIN,
  STEP_UPDATE_ORDER,
  UPDATE_INVOICE,
  UPDATE_LOCALE,
  UPDATE_ORDER,
  UPDATE_ORDER_FAILED,
  UPDATE_ORDER_FULFILLED,
  UPDATE_USER,
  UPDATE_USER_FULFILLED,
  UPDATE_USER_LANGUAGES,
  USERS_PATCH_FULFILLED,
} from './actions';
import lang from './common/lang';
import { getChat } from './components/chat/selectors';
import * as constants from './constants';
import cookie from './cookie';
import feathers, {
  connect,
  disconnect,
  feathersAuthentication,
  feathersServices,
} from './feathers';
import alert from './img/alert.mp3';
import NotificationItem from './notifications/NotificationItem';
import { countWordsInOrder } from './orders/orderUtils';
import { getPrice } from './orders/pricing';
import { getCurrentOrder, getOrderById } from './orders/selectors';
import {
  generateMarks,
  getEarliestTime,
  mustStartTime,
  timeFromIncrements,
} from './orders/times';
import { getRedirect, getSettingsLoaded, settings } from './selectors';
import paramsForServer from './utils/params-for-server';

const stripe = window.Stripe(process.env.REACT_APP_STRIPE_TOKEN);

function* loadSettings() {
  yield put(
    feathersServices.settings.find({
      query: {
        key: [
          'dayStart',
          'dayEnd',
          'minLength',
          'increments',
          'searchTime',
          'extendAfter',
          'extendBy',
          'bigJob',
          'basePrice',
          'priceBreaks',
          'priceBreaksAskProofreading',
          'minFastPrice',
          'minSlowPrice',
          'supportedLanguages',
          'reviewTimeouts',
          'timeoutExtensions',
          'languagesList',
          'availableLanguagesCouples',
          'specialities',
          'proofreadingExtendBy',
          'proofreadingSearchTime',
          'proofreadingBasePrice',
          'proofreadingMinFastPrice',
          'proofreadingMinSlowPrice',
          'proofreadingVSTranslationDelay',
        ],
      },
    })
  );
}

function* login(data) {
  yield put(feathersAuthentication.authenticate());
  yield race({
    auth: take(AUTH_FULFILLED),
    failed: take(AUTH_REJECTED),
  });

  const profile = yield select(getProfile);
  console.log(profile)
  if (!profile.lang) {
    // There has been an authentication issue. This only happens if the jwt was invalid
    yield call(logout, data);
  } else if (Capacitor.isNativePlatform && profile.role === 'admin') {
    // logout admin and open mobile browser with admin interface
    yield call(logout, data);
    Browser.open({ url: process.env.REACT_APP_ADMIN_URL });
  } else {
    if (Capacitor.isNativePlatform) {
      yield call(registerForPushNotifications, profile._id, profile.lang);
    }
    yield call(updateLocale, { payload: profile.lang });
    yield connect();
  }
}

function* updateUser(data) {
  const { user, browserHistory } = data.payload;
  const loggingIn = yield select(isLoggingIn);
  if (loggingIn) yield take(AUTH_FULFILLED); // Wait until logged in

  let profile = yield select(getProfile);
  const previousLang = profile.lang;
  yield put(feathersServices.users.update(user._id, user));
  yield take(UPDATE_USER_FULFILLED);
  profile = yield select(getProfile);

  if (previousLang !== profile.lang) {
    yield call(updateLocale, { payload: profile.lang });
  }

  const redirect = yield select(getRedirect);
  if (!!browserHistory) {
    if (redirect) {
      browserHistory.push(redirect);
    } else if (get(browserHistory, 'location.search', false)) {
      const search = new URLSearchParams(browserHistory.location.search);
      browserHistory.push(search.get('return'));
    } else {
      browserHistory.push('/');
    }
  }
}

function* getOrder(data) {
  const loggingIn = yield select(isLoggingIn);
  if (loggingIn) yield take(AUTH_FULFILLED); // Wait until logged in

  const order = yield select(getCurrentOrder);
  const { id, email } = data.payload;
  if (!order || order._id !== id) {
    const cached = yield select(getOrderById, id);
    if (cached) {
      yield put(fulfillLoadOrder(cached));
    } else {
      yield put(feathersServices.orders.get(id, paramsForServer({ email })));
    }
  }
}

function* getUser(data) {
  const loggingIn = yield select(isLoggingIn);
  if (loggingIn) yield take(AUTH_FULFILLED); // Wait until logged in
  const profile = yield select(getProfile);
  if (profile) {
    yield put(feathersServices.users.get(data.payload));
  }
}

function* logout(data) {
  if (Capacitor.isNativePlatform()) {
    const profile = yield select(getProfile);
    yield call(deregisterFromPushNotifications, profile._id);
    take(USERS_PATCH_FULFILLED);
  }
  const { browserHistory } = data.payload;
  yield feathersAuthentication.logout();

  yield disconnect();
  cookie.clear();
  localStorage.removeItem('feathers-jwt');
  if (Capacitor.isNativePlatform()) {
    console.log("native platform")
    inAppLogout(browserHistory);

  } else {
    console.log("Pas native")
    window.location.replace(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&returnTo=${process.env.REACT_APP_TRANSLATOR_URL}`
    );
  }
}

const inAppLogout = (browserHistory) => {
  try {
    const browser = InAppBrowser.create(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
      '_blank',
      'location=no'
    );
    browser.show();
    // Get logout url from Auth0
    browser.on('loadstart').subscribe(async (ev) => {
      if (ev.url === `${process.env.REACT_APP_URL}/`) {
        browser.close();
        browserHistory.push('/login');
      }
    });
    // If user close browser
    browser.on('exit').subscribe(
      () => {
        browserHistory.push('/login');
      },
      (err) => {
        console.error('Logout Browser exit error', err);
      }
    );
  } catch (e) {
    console.error('Logout Browser opening error', e);
  }
};

function* createOrder(data) {
  const { browserHistory, order, confirm, proofreading } = data.payload;
  const loggingIn = yield select(isLoggingIn);
  if (loggingIn) yield take(AUTH_FULFILLED); // Wait until logged in

  const profile = yield select(getProfile);
  const order1 = { ...order, proofreading };
  console.log('gg order1', order1);
  let result = {};
  if (profile) {
    yield put(feathersServices.orders.create(order1 || {}));
    result = yield take(CREATE_ORDER_FULFILLED);
  } else {
    result.payload = { _id: 'new' };
    order1._id = 'new';
  }
  let path = '/';
  if (confirm) {
    path += `confirm`;
  } else if (proofreading) {
    path += `proofreading`;
  } else {
    path += `order`;
  }
  // localStorage.setItem("mybrian-order", JSON.stringify(order1));
  browserHistory.push(`${path}/${result.payload._id}`);
}

function* updateLocale(data) {
  const locale = data.payload || 'fr';
  const format = constants.localeFormatTweaks[locale] || undefined;
  moment.locale(locale, format);
  yield put(updateIntl({ locale, messages: lang[locale] }));
}

function* stepUpdateOrder(data) {
  try {
    const { order, browserHistory, wordsNumber } = data.payload;
    if (wordsNumber >= 0) order.words = wordsNumber;
    order.words *= 1;
    order.wordsNumber *= 1;
    if (order._id && order._id !== 'new') {
      yield put(feathersServices.orders.patch(order._id, order));
      const { error } = yield race({
        result: take(PATCH_ORDER_FULFILLED),
        error: take(PATCH_ORDER_FAILED),
      });
      if (!error) {
        if (browserHistory) {
          browserHistory.push(`/order/${order._id}/context`);
        }
      }
    } else {
      yield call(localUpdate, data);
      if (browserHistory && window.self !== window.top) {
        if (order.wordsNumber) {
          browserHistory.push(`/order/new`);
          window.location.pathname = '/order/new/widget';
          window.parent.location = browserHistory.location.pathname;
        } else {
          browserHistory.push(`/order/${order._id}/context`);
          window.location.pathname = '/order/new/widget';
          window.parent.location = browserHistory.location.pathname;
        }
      } else if (browserHistory) {
        if (order.attachments.length > 0 || order.text.length > 0)
          browserHistory.push(`/order/${order._id}/context`);
        else browserHistory.push(`/order/${order._id}`);
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* localUpdate(data) {
  try {
    const state = yield select();
    const { order, wordsNumber } = data.payload;
    const proofreading = order.proofreading;
    const words =
      order.wordsNumber >= 0 ? order.wordsNumber : countWordsInOrder(order);
    const deadline = timeFromIncrements(
      state,
      order.time,
      getEarliestTime(state, words, null,proofreading),
      order.proofreading
    );
    const mst = mustStartTime(state, deadline, words, null,proofreading);
    const { max } = generateMarks(state, order.words, null, proofreading);

    order.words = words;

   if (!order.price)
    order.price = getPrice(
      state,
      mst,
      words,
      order.basePrice,
      order.time,
      max,
      order.proofreading,
        order.averageRedundancy,
        order.redundantWords
    );
    if (Array.isArray(order.price))
    order.priceWithTax = order.price[1] + order.price[1] * constants.tax;
    else
      order.priceWithTax = order.price + order.price * constants.tax;


    if (order.selectedLanguage && !order.destinationLanguage) {
      const { supportedLanguages } = settings(state);

      const fromMatch = find(supportedLanguages, {
        iso6391Name: order.selectedLanguage,
      });
      if (fromMatch.supportedDestinations.length === 1) {
        const onlySupportedLanguage = find(supportedLanguages, {
          iso6391Name: fromMatch.supportedDestinations[0],
        });

        if (onlySupportedLanguage && !onlySupportedLanguage.dialects) {
          order.destinationLanguage = onlySupportedLanguage.iso6391Name;
        }
      }
    }
    order.deadline = deadline.toJSON();
    order.status = 'Order.Status.Unpaid';

    const updatedOrder = { ...order };
    updatedOrder._id = updatedOrder._anonId || null;

    const savedOrder = updatedOrder._id
      ? yield feathers
          .service('anonymous-orders')
          .update(updatedOrder._id, updatedOrder)
      : yield feathers.service('anonymous-orders').create(updatedOrder);
    savedOrder._anonId = savedOrder._id;
    savedOrder._id = 'new';

    localStorage.setItem('mybrian-order', JSON.stringify(savedOrder));
    if (!order.quoteEmail) {
      yield put({ type: PATCH_ORDER_FULFILLED, payload: savedOrder });
    }
  } catch (e) {
    console.log({ e });
  }
}

function* updateOrder(data) {
  const translator = yield select(isTranslator);
  const { order, browserHistory, history } = data.payload;
  console.log('gg updaor', order);
  if (order.wordsNumber >= 0) {
    order.words = order.wordsNumber;
  }
  if (!translator) {
    if (!order._id || order._id === 'new') {
      yield call(localUpdate, data);
    } else {
      yield put(feathersServices.orders.patch(order._id, order));
      yield take(PATCH_ORDER_FULFILLED);
    }
    if (browserHistory) {
      browserHistory.push(`/confirm/${order._id}`);
    }
  } else {
    // Only send the relevant changes
    const update = {
      translatorRating: order.translatorRating,
      translation: order.translation,
      isLayoutRespected: order.isLayoutRespected,
    };

    if (order.translatorRating) {
      update.completedAt = new Date();
    }

    yield put(feathersServices.orders.patch(order._id, update));
    yield take(PATCH_ORDERS_FULFILLED);
    if (order.translatorRating) {
      yield call(history.push, '/?success');
    }
  }
}

function* removeFile(data) {
  const { orderId, file } = data.payload;
  if (orderId === 'new') {
    const order = { ...newLocalOrder };
    yield call(localUpdate, { payload: { order } });
  } else {
    yield put(
      feathersServices.uploads.remove(file, paramsForServer({ orderId }))
    );
  }
}

function* confirmOrder(data) {
  const { order, browserHistory, email } = data.payload;

  const loggingIn = yield select(isLoggingIn);
  if (loggingIn) yield take(AUTH_FULFILLED); // Wait until logged in

  const profile = yield select(getProfile);
  if (profile) {
    yield put(
      feathersServices.orders.update(
        order._id,
        order,
        paramsForServer({ action: CONFIRM_ORDER, email })
      )
    );
    const { error, result } = yield race({
      result: take(UPDATE_ORDER_FULFILLED),
      error: take(UPDATE_ORDER_FAILED),
    });
    if (error) {
      yield put(feathersServices.orders.get(order._id));
      browserHistory.push(`/confirm/${order._id}?error`);
    } else if (result.payload.payBy === 'card') {
      if (Capacitor.isNativePlatform()) {
        inAppPayment(browserHistory, order, result);
      } else {
        stripe.redirectToCheckout({ sessionId: result.payload.stripe.token });
      }
    } else {
      browserHistory.push(`/view/${order._id}`);
    }
  }
}

// Payment in inAppBrowser (mobile)
const inAppPayment = async (browserHistory, order, result) => {
  const success_url = `${process.env.REACT_APP_URL}/view/${order._id}`;
  const cancel_url = `${process.env.REACT_APP_URL}/confirm/${order._id}?error`;
  try {
    const browser = InAppBrowser.create(
      `${process.env.REACT_APP_URL}/mobilepayment?token=${result.payload.stripe.token}`,
      '_blank',
      'location=no'
    );
    let success = false;
    browser.show();
    // Get return url from Stripe
    browser.on('loadstart').subscribe((ev) => {
      if (ev.url === success_url) {
        success = true;
        browser.close();
      } else if (ev.url === cancel_url) {
        success = false;
        browser.close();
      }
    });
    // Redirection after browser close
    browser.on('exit').subscribe(
      () => {
        if (success) {
          browserHistory.push(`/view/${order._id}`);
        } else {
          browserHistory.push(`/confirm/${order._id}?error`);
        }
      },
      (err) => {
        console.error('Payment Browser exit error', err);
      }
    );
  } catch (e) {
    console.error('Payment Browser opening error', e);
  }
};

// Default settings for new local orders
const newLocalOrder = {
  _id: 'new',
  time: 40,
  status: 'Order.Status.Unpaid',
  specificity: 'general',
  attachments: [],
};

function* loadLocalOrder() {
  const settingsLoaded = yield select(getSettingsLoaded);
  if (!settingsLoaded) {
    yield take(SETTINGS_LOADED);
  }
  const order = JSON.parse(yield localStorage.getItem('mybrian-order')) || {
    ...newLocalOrder,
  };
  yield put({ type: LOAD_LOCAL_ORDER_FULFILLED, payload: order });
}

function* showNotification(data) {
  const notification = data.payload;

  const chat = yield select(getChat);
  if (
    window.location.pathname === '/notifications' ||
    chat === notification.orderId
  ) {
    // don't show notification popups if we're on the notification page,
    // or the notification is about messages we're receiving
    return;
  }
  const translator = yield select(isTranslator);
  yield put(
    Notifications.info({
      onAdd: () => {
        const audio = new Audio(alert);
        audio.play();
      },
      autoDismiss: 10,
      children: (
        <NotificationItem
          notification={notification}
          isTranslator={translator}
        />
      ),
    })
  );
}

function* requestRefund(data) {
  const id = data.payload;

  yield put(
    feathersServices.orders.patch(id, {
      suspendedAction: 'Order.Request.Refund',
    })
  );
}

function* requestManualSearch(data) {
  const id = data.payload;

  yield put(
    feathersServices.orders.patch(id, {
      suspendedAction: 'Order.Request.ManualSearch',
    })
  );
}

function* rateOrder(data) {
  const order = data.payload;
  yield put(feathersServices.orders.patch(order._id, order));
}

const markNotificationsRead = function* () {
  const profile = yield select(getProfile);
  if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
  yield put(
    feathersServices.notifications.patch(
      null,
      { seen: new Date() },
      { query: { seen: { $exists: false } } }
    )
  );
};

const markNotificationClicked = function* (data) {
  const loggingIn = yield select(isLoggingIn);
  if (loggingIn) yield take(AUTH_FULFILLED); // Wait until logged in

  const profile = yield select(getProfile);
  if (profile) {
    const _id = data.payload;
    yield put(
      feathersServices.notifications.patch(_id, {
        seen: new Date(),
        clicked: new Date(),
      })
    );
  }
};

const notifyViewingChat = function* (data) {
  const currentlyChatting = data.payload;
  const profile = yield select(getProfile);
  if (profile) {
    yield put(feathersServices.users.patch(profile._id, { currentlyChatting }));
  }
};

const agreeToTerms = function* () {
  const profile = yield select(getProfile);
  if (profile) {
    yield put(
      feathersServices.users.patch(profile._id, { agreedToTerms: true })
    );
  }
};

const removeOrder = function* (data) {
  const { payload } = data;
  if (payload === 'new') {
    localStorage.removeItem('mybrian-order');
    yield put({ type: REMOVE_LOCAL_ORDER_FULFILLED });
  } else {
    yield put(feathersServices.orders.remove(payload));
    yield take(REMOVE_ORDER_FULFILLED);
    yield put({ type: REMOVE_LOCAL_ORDER_FULFILLED });
  }
};

function* saveProgress(data) {
  const order = data.payload;
  if (order._id) {
    if (order._id !== 'new') {
      yield put(feathersServices.orders.patch(order._id, order));
    } else {
      yield call(localUpdate, { payload: { order } });
    }
  }
}

// Translators actions.

const reviewOrder = function* (data) {
  const orderId = data.payload;
  yield put(
    feathersServices.orders.update(
      orderId,
      {},
      paramsForServer({ action: REVIEW_ORDER })
    )
  );
  yield take(UPDATE_ORDER_FULFILLED);
};

function* rejectOrder(data) {
  const { order, reason } = data.payload;
  yield put(
    feathersServices.orders.update(
      order._id,
      {},
      paramsForServer({ action: REJECT_ORDER, payload: { reason } })
    )
  );
  yield take(UPDATE_ORDER_FULFILLED);
}

function* rejectOrderAndRedirect(data) {
  const { order, reason, browserHistory } = data.payload;
  yield put(
    feathersServices.orders.update(
      order._id,
      {},
      paramsForServer({ action: REJECT_ORDER, payload: { reason } })
    )
  );
  yield take(UPDATE_ORDER_FULFILLED);
  yield call(browserHistory.push, '/');
}

function* updateAvailable(data) {
  const { available, browserHistory } = data.payload;
  const user = yield select(getProfile);

  yield put(feathersServices.users.patch(user, { available }));
  yield take(USERS_PATCH_FULFILLED);
  if (window.location.pathname.startsWith('/review')) {
    yield call(browserHistory.push, '/');
  }
}

function* acceptAndStartOrder(data) {
  const { order, browserHistory } = data.payload;
  yield put(
    feathersServices.orders.update(
      order._id,
      {},
      paramsForServer({ action: ACCEPT_ORDER })
    )
  );
  yield take(UPDATE_ORDER_FULFILLED);

  yield call(browserHistory.push, `/translate/${order._id}`);
  yield put(feathersServices.messages.find());
}

function* updateUserLanguages(data) {
  const { canTranslate } = data.payload;
  const profile = yield select(getProfile);
  profile.canTranslate = canTranslate;
  yield put(feathersServices.users.update(profile._id, profile));
  yield take(UPDATE_USER_LANGUAGES);
}

function* loadInvoices(data) {
  const query = data.payload;
  const profile = yield select(getProfile);
  if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
  yield put(
    feathersServices.invoices.find({
      query: {
        ...query,
        $sort: { createdAt: -1 },
      },
    })
  );
}

function* getInvoice(data) {
  const profile = yield select(getProfile);
  if (!profile) yield take(AUTH_FULFILLED); // Wait until logged in
  yield put(feathersServices.invoices.get(data.payload));
}

function* updateInvoice(data) {
  const invoice = data.payload;
  yield put(feathersServices.invoices.patch(invoice._id, invoice));
}

export default function* rootSaga() {
  yield all([
    takeLatest(START_LOGIN, login),
    takeEvery(LOGOUT, logout),
    takeEvery(AUTH_REJECTED, logout),
    takeEvery(CREATE_ORDER, createOrder),
    takeEvery(UPDATE_ORDER, updateOrder),
    takeEvery(CONFIRM_ORDER, confirmOrder),
    takeEvery(LOAD_LOCAL_ORDER, loadLocalOrder),
    takeLatest(LOAD_ORDER, getOrder),
    takeLatest(GET_USER, getUser),
    takeEvery(NOTIFICATIONS_CREATE_RECEIVED, showNotification),
    takeLatest(STEP_UPDATE_ORDER, stepUpdateOrder),
    takeEvery(UPDATE_USER, updateUser),
    takeLatest(LOAD_SETTINGS, loadSettings),
    takeEvery(REQUEST_REFUND, requestRefund),
    takeEvery(REQUEST_MANUAL_SEARCH, requestManualSearch),
    takeEvery(RATE_ORDER, rateOrder),
    takeEvery(MARK_NOTIFICATIONS_READ, markNotificationsRead),
    takeEvery(MARK_NOTIFICATION_CLICKED, markNotificationClicked),
    takeLatest(NOTIFY_VIEWING_CHAT, notifyViewingChat),
    takeLatest(HIDE_CHAT, notifyViewingChat),
    takeLatest(AGREE_TO_TERMS, agreeToTerms),
    takeLatest(REMOVE_ORDER, removeOrder),
    takeEvery(REMOVE_FILE, removeFile),
    takeLatest(SAVE_ORDER_PROGRESS, saveProgress),
    takeLatest(UPDATE_LOCALE, updateLocale),

    // Translators
    takeEvery(REVIEW_ORDER, reviewOrder),
    takeEvery(SET_TRANSLATOR_AVAILABLE, updateAvailable),
    takeEvery(REJECT_ORDER, rejectOrder),
    takeEvery(REJECT_ORDER_REDIRECT, rejectOrderAndRedirect),
    takeEvery(ACCEPT_START_ORDER, acceptAndStartOrder),
    takeLatest(UPDATE_USER_LANGUAGES, updateUserLanguages),
    takeLatest(LOAD_INVOICES, loadInvoices),
    takeLatest(GET_INVOICE, getInvoice),
    takeLatest(UPDATE_INVOICE, updateInvoice),
  ]);
}
