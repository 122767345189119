import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import ClientInvoiceDetail from '../components/ClientInvoiceDetail';

import Card from '../layout/Card';
import { getClientInvoices } from '../selectors';
import { faFileInvoiceDollar,faAddressCard } from "@fortawesome/free-solid-svg-icons";

const AccountDetailsClientInvoices = ({ invoices }) => {
  return (
    <>
      <Card fill faIcon={faFileInvoiceDollar} title="Account.InvoicesClient">
        <Row>
          <Col>
            {(!invoices || invoices.length === 0) && (
              <p>
                <FormattedMessage id="Invoice.NoInvoice" />
              </p>
            )}

            {invoices && invoices.length > 0 && (
              <>
                {invoices &&
                  invoices.map((invoice) => (
                    <ClientInvoiceDetail invoice={invoice} />
                  ))}
              </>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

AccountDetailsClientInvoices.propTypes = {
  invoices: PropTypes.array,
};
const stateToProps = (state) => ({
  invoices: getClientInvoices(state),
});

export default connect(stateToProps)(AccountDetailsClientInvoices);
