import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getLang, settings } from '../selectors';
import { getProfile } from './../account/selectors';

import Flag from './Flag';

const Languages = ({ order, languagesList, profile, intlLang }) => {
  const from = order.selectedLanguage;
  const to = order.destinationLanguage;
  const getCountryName = (iso) => {
    let websiteLang = intlLang;
    if (profile) {
      websiteLang = profile.lang;
    }
    return languagesList[websiteLang].find((lang) => lang.key === iso);
  };
  const origin = getCountryName(from);
  return (
    <div className="section-options">
      {!order.proofreading ? (
        <>
          <div className="section-options__from-block">
            <div className="section-options__title" >
              <FormattedMessage id="Language.Source" />
            </div>
            <div className="section-options__from">
              <div className="section-options__from-inner">
                <Flag country={from} className="from-flag" />
              </div>
            </div>
          </div>
          <div className="section-options__to-block">
            <div className="section-options__title" >
              <FormattedMessage id="Language.Destination" />
            </div>
            <div className="section-options__to" >
              <div className="section-options__to-inner">
                <Flag country={to} className="to-flag" />
              </div>
            </div>
          </div>
          
        </>
      ) : (
        <>
          {/* ------------------ affichage spécial relecture ----- */}
          <div className="section-options__from">
            <div className="section-options__from-inner">
              <Flag country={to} className="from-flag" />
              {origin && origin.name && (
                <p className="small">
                  (<FormattedMessage id="Proofreading.Original.Language" /> :{' '}
                  {origin.name})
                </p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  profile: getProfile(state),
  intlLang: getLang(state),
  languagesList: settings(state).languagesList,
});

export default connect(mapStateToProps)(Languages);
