import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import request from "superagent";

import { getProfile } from "../account/selectors";
import {
  upload,
  uploadComplete,
  uploadError,
  uploadProgress,
} from "../actions";
import Attachment from "../attachments/Attachment";
import UploadField from "../components/formfields/UploadField";
import { widget } from "../constants";
import cookieStorage from "../cookie";
import AccountPropType from "./../common/proptypes/AccountPropType";
import OrderPropType from "./../common/proptypes/OrderPropType";
import { getCurrentOrder } from "./selectors";
import styles from "./Uploader.module.scss";

const handleUpload = async ({
  acceptedFiles,
  order,
  type,
  notifyUpload,
  notifyUploadProgress,
  notifyUploadComplete,
  notifyUploadError,
  user,
  errorMessage,
  tmx,
  overrideUpload,
  setLoading,
}) => {
  const cookie = cookieStorage.getItem("feathers-jwt");
  //console.log("gg acceptedFiles", acceptedFiles);
  if (!acceptedFiles.length) {
    errorMessage(<FormattedMessage id="Uploader.ErrorType" />);
  } else {
    errorMessage("");
  }

  acceptedFiles.forEach((file) => {
    // eslint-disable-next-line no-bitwise
    const nonce = [...Array(10)]
      .map(() => (~~(Math.random() * 36)).toString(36))
      .join("");
    const { name } = file;
    if (!overrideUpload)
      notifyUpload({
        name,
        percent: 0,
        error: null,
        words: null,
        path: null,
        uploading: true,
        nonce,
        authorId: user ? user._id : null,
        type,
      });

    console.log(
      overrideUpload ? { type, tmx } : { orderId: order._id, type, tmx }
    );

    request
      .post(`${process.env.REACT_APP_API_URL}/uploads`)
      .set("Authorization", cookie)
      .query({
        $client: overrideUpload
          ? { type, tmx }
          : { orderId: order._id, type, tmx },
      })
      .attach("uri", file, name)
      .on("progress", (event) =>
        !overrideUpload
          ? notifyUploadProgress({ name, percent: event.percent, nonce })
          : () => {}
      )
      .then(
        ({ body }) => {
          if (!overrideUpload)
            notifyUploadComplete({
              name,
              words: body.words,
              path: body.id,
              nonce,
              detectedLanguage: body.detectedLanguage,
              lang: body.lang,
              specialityId: body.domain,
              redundancy: body.redundancy,
            });
          else overrideUpload();
          setLoading(false);
        },
        (error) => {
          errorMessage(<FormattedMessage id="Uploader.Error500" />);
          if (!overrideUpload)
            notifyUploadError({
              name,
              error: error?.response?.body?.message,
              nonce,
            });
          setLoading(false);
        }
      );
  });
};

const Uploader = ({
  order,
  type,
  notifyUpload,
  notifyUploadProgress,
  notifyUploadComplete,
  notifyUploadError,
  user,
  uploadMessage,
  disabled,
  setBadFileExtension,
  attachments,
  wordsNumber,
  getIndexForAttachment,
  doRemoveFile,
  tmx,
  overrideUpload,
  loader,
}) => {
  const [badFileExtensionUploader, setBadFileExtensionUpdloader] = useState("");
  const [loading, setLoading] = useState(false);
  const errorMessage = widget
    ? setBadFileExtensionUpdloader // setBadFileExtension
    : setBadFileExtensionUpdloader;

  //console.log(attachments, wordsNumber);
  return (
    <div>
      <div
        className={
          badFileExtensionUploader && !attachments?.length
            ? styles.styledUploadField
            : ""
        }
      >
        {badFileExtensionUploader && !attachments?.length && (
          <div className={styles.ErrorMessage}>
            <FontAwesomeIcon
              style={{ marginRight: 5 }}
              icon={faInfoCircle}
              fontSize="16px"
            />
            <span>{badFileExtensionUploader}</span>
          </div>
        )}
        {attachments?.length && !wordsNumber ? (
          attachments.slice(0, attachments.length - 1).map((u, i) => (
            <div
              style={{ marginBottom: 10 }}
              key={`u.name-${i}`}
              className={`${
                widget
                  ? "flex-center " + styles.WidgetSectionResult
                  : "flex-start-center"
              }`}
            >
              <Attachment
                key={`${u.name}${u.nonce}`}
                attachment={u}
                index={getIndexForAttachment(order, u)}
                onRemove={() =>
                  doRemoveFile({ orderId: order._id, file: u.path })
                }
                type={type}
              />
              <div style={{ width: 60 }} />
            </div>
          ))
        ) : (
          <div className={styles.WidgetErrorUploader}>
            {/* {badFileExtension} */}
          </div>
        )}

        {!!attachments?.length && (
          <div className={widget ? "flex-center" : "flex-start-center"}>
            <div
              style={{ marginRight: 10 }}
              key={`u.name-${12}`}
              className={"flex-center " + styles.WidgetSectionResult}
            >
              <Attachment
                key={`${attachments[attachments?.length - 1]?.name}${
                  attachments[attachments?.length - 1]?.nonce
                }`}
                attachment={attachments[attachments?.length - 1]}
                index={getIndexForAttachment(order, attachments)}
                onRemove={() =>
                  doRemoveFile({
                    orderId: order._id,
                    file: attachments[attachments?.length - 1]?.path,
                  })
                }
                type={type}
              />
            </div>
            <UploadField
              className={styles.FileInfo}
              uploadMessage={uploadMessage}
              handleDrop={async (acceptedFiles) => {
                if (loader) setLoading(true);
                handleUpload({
                  acceptedFiles,
                  order,
                  type,
                  notifyUpload,
                  notifyUploadProgress,
                  notifyUploadComplete,
                  notifyUploadError,
                  user,
                  errorMessage,
                  tmx,
                  overrideUpload,
                  setLoading,
                });
                // if (loader) setLoading(false);
              }}
              disabled={disabled}
              showAsButton={true}
              loader={loading}
              accept={tmx ? ".tmx" : undefined}
            />
          </div>
        )}
        {!attachments?.length && (
          <UploadField
            className={styles.FileInfo}
            uploadMessage={uploadMessage}
            handleDrop={(acceptedFiles) => {
              if (loader) setLoading(true);
              handleUpload({
                acceptedFiles,
                order,
                type,
                notifyUpload,
                notifyUploadProgress,
                notifyUploadComplete,
                notifyUploadError,
                user,
                errorMessage,
                tmx,
                overrideUpload,
                setLoading,
              });
              // if (loader) setLoading(false);
            }}
            disabled={disabled}
            showAsButton={
              badFileExtensionUploader || (attachments?.length && !wordsNumber)
            }
            loader={loading}
            accept={tmx ? ".tmx" : undefined}

          />
        )}
        {badFileExtensionUploader && Boolean(attachments?.length) && (
          <div style={{ marginTop: 10 }} className={styles.ErrorMessage}>
            <FontAwesomeIcon
              style={{ marginRight: 5 }}
              icon={faInfoCircle}
              fontSize="16px"
            />
            <span>{badFileExtensionUploader}</span>
          </div>
        )}
      </div>
    </div>
  );
};

Uploader.propTypes = {
  order: OrderPropType.isRequired,
  type: PropTypes.string.isRequired,
  notifyUpload: PropTypes.func.isRequired,
  notifyUploadProgress: PropTypes.func.isRequired,
  notifyUploadError: PropTypes.func.isRequired,
  notifyUploadComplete: PropTypes.func.isRequired,
  user: AccountPropType,
  uploadMessage: PropTypes.string,
  setBadFileExtension: PropTypes.func,
};

Uploader.defaultProps = {
  user: null,
  uploadMessage: "File.Drop",
};

const stateToProps = (state) => {
  const order = getCurrentOrder(state);
  const user = getProfile(state);

  return {
    order,
    user,
  };
};

const dispatchToProps = {
  notifyUpload: upload,
  notifyUploadProgress: uploadProgress,
  notifyUploadError: uploadError,
  notifyUploadComplete: uploadComplete,
};

export default connect(stateToProps, dispatchToProps)(Uploader);
