import classnames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { faLanguage } from "@fortawesome/free-solid-svg-icons";

import { updateUserLanguages } from '../actions';
import CanTranslate from '../components-translator/CanTranslate';
import Card from '../layout/Card';
import { settings } from '../selectors';
import AccountDetailsLanguagesForm from './AccountDetailsLanguagesForm';
import styles from './AccountDetailsTranslatorCanTranslate.module.scss';

const AccountDetailsTranslatorCanTranslate = ({
  profile,
  doUpdateUserLanguages,
  languagesList,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [canTranslateItems, setCanTranslateItems] = useState(
    profile.canTranslate
  );

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  /**
   * after selectioning new couple of languages :
   * add to profile.canTranslate, with status = pending, ie waiting for admin agreement.
   * */
  const backFromAccountDetailsLanguageForm = (languageCouple) => {
    closeModal();
    if (languageCouple.couple) {
      if (
        !canTranslateItems.find((obj) => obj.couple === languageCouple.couple)
      ) {
        // test if not already existing
        const arr = [...canTranslateItems, languageCouple];
        setCanTranslateItems(arr);
        doUpdateUserLanguages({ userId: profile._id, canTranslate: arr });
      }
    }
  };

  // Back from CanTranslate = remove a couple of languages.
  const backFromCanTranslate = (removedCouple) => {
    const arr = canTranslateItems.filter(function (obj) {
      return obj.couple !== removedCouple.couple;
    });
    setCanTranslateItems(arr);
    doUpdateUserLanguages({ canTranslate: arr });
  };

  return (
    <>
      <Card
        fill
        faIcon={faLanguage}
        title="Account.CoupleOfTranslations"
        className="relative"
      >
        <Row>
          <Col sm="12">
            <p className="control-label">
              <FormattedMessage id="Settings.LanguagesTranslated" />
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <div className={styles.canTranslate}>
              {canTranslateItems.map((lang) => (
                <CanTranslate
                  key={lang.couple}
                  languageCouple={lang}
                  fromCanTranslate={backFromCanTranslate}
                  languagesList={languagesList[profile.lang]}
                />
              ))}
              {canTranslateItems.length === 0 ? (
                <p>
                  <FormattedMessage id="Account.NoCoupleSet" />
                </p>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <button
              className={classnames({
                'btn': true,
                [styles.buttonAdd]: true,
              })}
              onClick={openModal}
            >
              <FormattedMessage id="Account.AddCoupleOfTranslations" />
            </button>
          </Col>
        </Row>
        <Modal isOpen={modalIsOpen} toggle={closeModal}>
          <ModalHeader toggle={closeModal}>
            <FormattedMessage id="Account.ChooseTranslationCouples" />
          </ModalHeader>
          <ModalBody>
            <AccountDetailsLanguagesForm
              existingCouples={canTranslateItems}
              languageListWebsite={languagesList[profile.lang]}
              fromAccountDetailsLanguageForm={
                backFromAccountDetailsLanguageForm
              }
            />
          </ModalBody>
        </Modal>
      </Card>
    </>
  );
};
const mapStateToProps = (state) => ({
  languagesList: settings(state).languagesList,
});
const mapDispatchToProps = {
  doUpdateUserLanguages: updateUserLanguages,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsTranslatorCanTranslate);
