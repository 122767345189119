import PropTypes from "prop-types";
import React, { useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import CheckboxField from "../components/formfields/CheckboxField";
import Card from "../layout/Card";
import { settings } from "../selectors";
import { faMemory, faSpellCheck } from "@fortawesome/free-solid-svg-icons";

import { updateUser } from "./../actions";
import AccountPropType from "./../common/proptypes/AccountPropType";

const AccountDetailsTranslatorTMX = ({
  profile,
  doUpdateUser,
}) => {
  const browserHistory = useHistory();
  const intl = useIntl();
  const [formIsUpdated, setFormIsUpdated] = useState(false);

  return (
    <>
      <Prompt
        when={formIsUpdated}
        message={intl.formatMessage({ id: "General.YouHaveUnsavedChanges" })}
      />
      <Form
        onSubmit={(user) => doUpdateUser({ user, browserHistory })}
        initialValues={profile}
      >
        {({ handleSubmit, values }) => (
          <div>
            <FormSpy
              subscription={{ dirty: true }}
              onChange={(props) => setFormIsUpdated(props.dirty)}
            />
            <form onSubmit={handleSubmit}>
              <Card
                fill
                title="Account.SubMenu.TMX"
                faIcon={faMemory}
              >
                <Row>
                  <Col xs="12" className="my-3">
                    <p className="control-label">
                      <FormattedMessage id="Account.Translator.TMX.Explanations" />
                    </p>
                  </Col>
                  <Col xs="12" className="my-3">
                    <Field
                      component={CheckboxField}
                      name="accept_tmx"
                      type="checkbox"
                      customLabel={
                        <FormattedMessage id="Account.Translator.TMX.Label" />
                      }
                      className="align-self-center"
                    />
                  </Col>
                </Row>
              </Card>

              <Row>
                <Col align="center">
                  <Button type="submit" className="button button--inline">
                    <FormattedMessage id="Forms.Submit" />
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </Form>
    </>
  );
};

AccountDetailsTranslatorTMX.propTypes = {
  profile: AccountPropType.isRequired,
  doUpdateUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  languagesList: settings(state).languagesList,
});

const mapDispatchToProps = {
  doUpdateUser: updateUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsTranslatorTMX);
