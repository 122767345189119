import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import request from 'superagent';
import OrderPropType from './../common/proptypes/OrderPropType';
import AttachmentPropType from './../common/proptypes/AttachmentPropType';
import SplitPaneTranslator from '../layout/SplitPaneTranslator';
import Attachment from './Attachment';
import UploadField from '../components/formfields/UploadField';
import cookie from '../cookie';
import { getProfile } from '../account/selectors';
import {
  removeFile,
  upload,
  uploadProgress,
  uploadError,
  uploadComplete,
} from '../actions';

import styles from './AttachmentPairs.module.scss';
import { getIndexForAttachment } from '../orders/orderUtils';

class AttachmentPairs extends React.Component {
  uploadFiles = (acceptedFiles) => {
    const {
      order,
      notifyUpload,
      notifyUploadProgress,
      notifyUploadComplete,
      notifyUploadError,
      user,
      sourceAttachment,
      type,
    } = this.props;
    acceptedFiles.forEach((file) => {
      // eslint-disable-next-line no-bitwise
      const nonce = [...Array(10)]
        .map(() => (~~(Math.random() * 36)).toString(36))
        .join('');
      const { name } = file;
      notifyUpload({
        name,
        percent: 0,
        error: null,
        words: null,
        path: null,
        uploading: true,
        nonce,
        authorId: user._id,
        type: type !== undefined ? type : user.role,
        translationFor: sourceAttachment.path,
      });

      request
        .post(`${process.env.REACT_APP_API_URL}/uploads`)
        .set('Authorization', cookie.getItem('feathers-jwt'))
        .query({
          $client: {
            orderId: order._id,
            type: type,
            translationFor: sourceAttachment.path,
          },
        })
        .attach('uri', file, name)
        .on('progress', (event) =>
          notifyUploadProgress({ name, percent: event.percent, nonce })
        )
        .then(
          ({ body }) =>
            notifyUploadComplete({
              name,
              words: body.words,
              path: body.id,
              nonce,
            }),
          (error) =>
            notifyUploadError({
              name,
              error: error.response.body.message,
              nonce,
            })
        );
    });
  };

  render() {
    const {
      sourceAttachment,
      destinationAttachments,
      editable,
      doRemoveFile,
      order,
      uploadMessage,
      type,
    } = this.props;
    return (
      <>
        {destinationAttachments.map((a) => (
          <Attachment
            key={`${a.path}${a.nonce}`}
            attachment={a}
            link={!editable}
            readonly={!editable}
            showRemove={false}
            index={getIndexForAttachment(order, a)}
            onRemove={() => doRemoveFile({ orderId: order._id, file: a.path, _id: a._id })}
          />
        ))}

        {editable && (
          <UploadField
            className={styles.Upload}
            handleDrop={this.uploadFiles}
            uploadMessage={uploadMessage}
          />
        )}
      </>
    );
  }
}

AttachmentPairs.propTypes = {
  sourceAttachment: AttachmentPropType.isRequired,
  destinationAttachments: PropTypes.arrayOf(AttachmentPropType),
  editable: PropTypes.bool,
  order: OrderPropType.isRequired,
  doRemoveFile: PropTypes.func.isRequired,
  notifyUpload: PropTypes.func.isRequired,
  notifyUploadProgress: PropTypes.func.isRequired,
  notifyUploadError: PropTypes.func.isRequired,
  notifyUploadComplete: PropTypes.func.isRequired,
};

AttachmentPairs.defaultProps = {
  destinationAttachments: [],
  editable: false,
};

const stateToProps = (state) => ({
  user: getProfile(state),
});

const dispatchToProps = {
  doRemoveFile: removeFile,
  notifyUpload: upload,
  notifyUploadProgress: uploadProgress,
  notifyUploadError: uploadError,
  notifyUploadComplete: uploadComplete,
};

export default connect(stateToProps, dispatchToProps)(AttachmentPairs);
