import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import FieldHolder from './FieldHolder';

import styles from './TextareaFieldTranslator.module.scss';

const TextareaFieldTranslator = (props) => {
  const {
    placeholder, input, readOnly, rows,
  } = props;
  const intl = useIntl();
  const translatedPlaceholder = placeholder ? intl.formatMessage({ id: placeholder }) : '';

  return (
    <FieldHolder {...props}>
      <FormControl
        componentClass="textarea"
        placeholder={translatedPlaceholder}
        readOnly={readOnly}
        {...input}
        rows={rows}
        className={styles.Textarea}
      />
    </FieldHolder>
  );
};

TextareaFieldTranslator.propTypes = {
  input: PropTypes.object.isRequired,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
};

TextareaFieldTranslator.defaultProps = {
  rows: 3,
  placeholder: undefined,
  readOnly: false,
};

export default TextareaFieldTranslator;
