import { settings } from "../selectors";
import { getMaxDuration } from "./times";

export const MAX = 9007199254740991;

const cachedPrice = {};

export function wordCount(text) {
  return text ? text.match(/\S+/g).length : 0;
}

export const getPriceDetailsForMST = (state, mst, words, proofreading) => {
  const minutes = mst.asMinutes();

  //if (cachedPrice[minutes]) {
  //  return cachedPrice[minutes];
  //}
  const priceBreaks = proofreading
    ? [...settings(state).priceBreaksAskProofreading]
    : [...settings(state).priceBreaks];
  let details;
  let previous;

  // Loop through price breaks until we find a MST break bigger than the current;
  for (const next of priceBreaks) {
    // Normalise down from infinity to something reasonable
    if (+next.mst === MAX)
      next.mst = getMaxDuration(state, words, proofreading).asMinutes();

    if (+next.mst === minutes) {
      previous = { ...next };
    } else if (+next.mst > minutes) {
      details = { ...next }; // Take a copy of the pricebreak details so we can modify it
      if (previous) {
        // do some smoothing based on the difference between now and the previous
        const multiplier =
          (minutes - +previous.mst) / (+next.mst - +previous.mst);
        details.uplift =
          +previous.uplift - (+previous.uplift - +next.uplift) * multiplier;
        details.margin =
          +previous.margin - (+previous.margin - +next.margin) * multiplier;
      }
      cachedPrice[minutes] = details;
      return details;
    } else {
      previous = { ...next }; // Do nothing, but remember this for later
    }
  }
  // We got to the end of the price breaks, most likely by just being over the new max
  cachedPrice[minutes] = previous;
  return previous;
};

export const round = (number) => {
  const factor = Math.pow(10, 2);
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
};

export const getPrice = (
  state,
  mst,
  words,
  basePrice,
  time,
  maxTime,
  proofreading = null,
  averageRedundancy,
  redundantWords = 0
) => {
  const pricing = getPriceDetailsForMST(state, mst, words, proofreading);
  const pricePerWord = basePrice + basePrice * pricing.uplift;

  console.log(words, redundantWords, pricePerWord, basePrice ,pricing.uplift)

  const priceClassic = round(
    Math.max(
      words * pricePerWord,
      getMinPrice(state, time, maxTime, proofreading)
    )
  );
  const priceWithRedudancy = round(
    Math.max(
      (words - redundantWords) * pricePerWord +
        redundantWords * (0.5 * pricePerWord),
      getMinPrice(state, time, maxTime, proofreading)
    )
  );

  return [priceWithRedudancy, priceClassic]
};

export const getMinPrice = (state, time, maxTime, proofreading) =>
  !proofreading
    ? ((maxTime - time) / maxTime) *
        (+settings(state).minFastPrice - +settings(state).minSlowPrice) +
      +settings(state).minSlowPrice
    : ((maxTime - time) / maxTime) *
        (+settings(state).proofreadingMinFastPrice -
          +settings(state).proofreadingMinSlowPrice) +
      +settings(state).proofreadingMinSlowPrice;
