import React, { useState } from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import styles from "./AccountDetailsLanguagesForm.module.scss";
import { Row, Col, Alert, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import SelectField from "../components/formfields/SelectField";
import { isEqual, uniqWith } from "lodash";
import Button from "../components/Button";

const AccountDetailsLanguagesForm = ({
  existingCouples,
  languageListWebsite,
  fromAccountDetailsLanguageForm,
}) => {
  const steps = ["origin", "destination"];
  const [selectedLanguage, setSelectedLanguage] = useState({
    origin: null,
    destination: null,
  });
  const [stepSelected, setSelectedStep] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);

  const capitalize = (word) => word.charAt(0).toUpperCase() + word.substr(1);

  const allLanguagesList = () => {
    const table = languageListWebsite
      .filter((value, index) => index > 15)
      .map((lang) => ({
        value: lang.key,
        text: lang.name,
      }))
      .sort((a, b) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));
    return uniqWith(table, isEqual);
  };

  const destinationList = () => {
    return allLanguagesList().filter(
      (lang) => lang.value !== selectedLanguage.origin.key
    );
  };

  function closeModal() {
    setIsOpen(false);
  }

  const chooseLanguageItem = (value) => {
    if (!value.name) {
      value = languageListWebsite.find((lang) => lang.key === value.key);
    }
    closeModal();
    setSelectedLanguage({ ...selectedLanguage, [steps[stepSelected]]: value });
    setSelectedStep(stepSelected + 1);
  };

  const showListOfLanguages = (languageForm) => {
    let usedLanguages = [];
    usedLanguages[languageForm] = languageListWebsite;
    // Remove selected "origin language" and "exisiting couples" from list
    if (selectedLanguage.origin?.key) {
      usedLanguages[languageForm] = languageListWebsite.filter(function (obj) {
        return obj.key !== selectedLanguage.origin.key;
      });
      const alreadyUsedOrigin = existingCouples.filter((obj) => {
        return obj.couple.split("-", 2)[0] === selectedLanguage.origin.key;
      });
      alreadyUsedOrigin.forEach((obj) => {
        const dest = obj.couple.split("-", 2)[1];
        usedLanguages[languageForm] = usedLanguages[languageForm].filter(
          function (obj) {
            return obj.key !== dest;
          }
        );
      });
    }

    return usedLanguages[languageForm].map((value, index) => {
      if (index <= 15) {
        return (
          <Col
            xs="6"
            sm="4"
            key={value.key}
            className={`${styles.languageList_item}`}
          >
            <span
              className={`${styles.languageList_elt} ${
                selectedLanguage[languageForm] === value.key ? "selected" : ""
              }`}
              onClick={() => chooseLanguageItem(value)}
            >
              {value.name}
            </span>
          </Col>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className={styles.formMask}>
        <div
          className={`${styles.formContainer} ${styles["step" + stepSelected]}`}
        >
          <div className={styles.stepOne}>
            <Row>
              <Col>
                <h4 className="mb-2">
                  <span className="icon-gear mr-2"></span>
                  <FormattedMessage id="AccountDetailsLanguageForm.Origin" />
                </h4>
              </Col>
            </Row>
            <Row className="my-3">{showListOfLanguages("origin")}</Row>
            <Row className="justify-content-center">
              <Col xs="6" sm="4" className={`${styles.languageList_item}`}>
                <span
                  className={`${styles.languageList_elt} `}
                  onClick={() => setIsOpen(true)}
                >
                  <FormattedMessage id="AccountDetailsLanguageForm.Other" />
                </span>
              </Col>
            </Row>
          </div>
          <div className={styles.stepTwo}>
            <Row>
              <Col>
                <h4>
                  <span className="icon-gear mr-2"></span>
                  <FormattedMessage id="AccountDetailsLanguageForm.Destination" />
                </h4>
              </Col>
            </Row>
            <Row className="my-3">{showListOfLanguages("destination")}</Row>
            <Row className="justify-content-center">
              <Col xs="6" sm="4" className={`${styles.languageList_item}`}>
                <span
                  className={`${styles.languageList_elt} `}
                  onClick={() => setIsOpen(true)}
                >
                  <FormattedMessage id="AccountDetailsLanguageForm.Other" />
                </span>
              </Col>
            </Row>
            <Button onClick={() => setSelectedStep(0)}>Retour</Button>
          </div>

          <Modal
            isOpen={modalIsOpen}
            toggle={closeModal}
            className={`${styles.modalOnModal}`}
          >
            <ModalHeader toggle={closeModal}>
              {steps[stepSelected] && (
                <FormattedMessage
                  id={`AccountDetailsLanguageForm.${capitalize(
                    steps[stepSelected]
                  )}Choice`}
                />
              )}
            </ModalHeader>
            <ModalBody className="p-5">
              <Form onSubmit={(value) => chooseLanguageItem(value)}>
                {({ handleSubmit }) => (
                  <div>
                    <OnChange name="key">
                      {() => {
                        handleSubmit();
                      }}
                    </OnChange>

                    <Field
                      component={SelectField}
                      name="key"
                      options={
                        selectedLanguage.origin
                          ? destinationList()
                          : allLanguagesList()
                      }
                      placeholder="LanguageOne.Choose"
                    />
                  </div>
                )}
              </Form>
            </ModalBody>
          </Modal>

          <div className={styles.stepThree}>
            <Row>
              <Col>
                <h4>
                  <span className="icon-gear mr-2" />
                  <FormattedMessage id="AccountDetailsLanguageForm.Validation" />
                </h4>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <p className="m-2 text-center">
                  {selectedLanguage.origin?.name}
                  <span className="icon-arrow-right align-middle mx-1 px-0" />
                  {selectedLanguage.destination?.name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="text-center">
                <button
                  className="button button--inline"
                  onClick={() =>
                    fromAccountDetailsLanguageForm({
                      couple: null,
                      status: null,
                    })
                  }
                >
                  <FormattedMessage id="AccountDetailsLanguageForm.Cancel" />
                </button>
              </Col>
              <Col xs="6" className="text-center">
                <button
                  className="button button--inline"
                  onClick={() =>
                    fromAccountDetailsLanguageForm({
                      couple:
                        selectedLanguage.origin.key +
                        "-" +
                        selectedLanguage.destination.key,
                      status: "PENDING",
                    })
                  }
                  disabled={!selectedLanguage.destination}
                >
                  <FormattedMessage id="AccountDetailsLanguageForm.Validate" />
                </button>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-5">
                <Alert color="danger">
                  <p className="font-weight-bold">
                    <FormattedMessage id="AccountDetailsLanguageForm.Important" />
                  </p>

                  <FormattedHTMLMessage id="AccountDetailsLanguageForm.validationByTeam" />
                </Alert>
              </Col>
              <Button onClick={() => setSelectedStep(1)}>Retour</Button>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetailsLanguagesForm;
