import accounting from 'accounting';
import { get } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';

import TextField from '../components/formfields/TextField';
import { taxStatuses, timezone } from '../constants';
import { updateInvoice } from './../actions';
import OrderInvoiceSummary from './OrderInvoiceSummary';

const required = (value) => (value ? undefined : 'Forms.Required');

const InvoiceForm = ({ invoice, profile, doUpdateInvoice }) => {
  if (!invoice) {
    return null;
  }

  const monthStart = moment.tz(new Date(), timezone).startOf('month');
  const invoiceMonthStart = invoice
    ? moment.tz(invoice.createdAt, timezone).startOf('month')
    : null;

  const nextMonth = monthStart > invoiceMonthStart ? true : false;
  const alreadySent = invoice.status ? true : false;
  const invoiceEditable = nextMonth && !alreadySent;
  const exemptionText = get(
    taxStatuses,
    `[${profile.taxStatus}].exemptionText`,
    ''
  );

  return (
    <Form
      initialValues={invoice}
      onSubmit={({ translatorInvoiceId }) =>
        doUpdateInvoice({ ...invoice, translatorInvoiceId })
      }
    >
      {({ handleSubmit, submitting, valid }) => (
        <form onSubmit={handleSubmit}>
          

          <div className="Card">
            <div className="Title">
              <FormattedMessage id="Invoice.Id" />
            </div>
            <div className="section-general">
              <Field
                name="translatorInvoiceId"
                disabled={!invoiceEditable}
                component={TextField}
                placeholder="Invoice.NoId"
                validate={required}
              />
              {alreadySent ? (
                <p className="text-danger">
                  <FormattedMessage id="Invoice.AlreadySent" />
                </p>
              ) : <p className="text-danger">
                <FormattedMessage id="Invoice.NextMonth" />
              </p>
              }
            </div>
          </div>
          

          
          <div className="Card">
            <div className="Title">
              <FormattedMessage id="Invoice.Orders" />
            </div>
            <div className="section-general">
              {invoice.orders && (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="Invoice.OrderId" />
                      </th>
                      <th>
                        <FormattedMessage id="Invoice.Words" />
                      </th>
                      <th>
                        <FormattedMessage id="Translator.Order.Pay" />
                      </th>
                      <th>
                        <FormattedMessage id="Invoice.Completed" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(invoice.orders).map((index) => {
                      const {
                        orderId,
                        words,
                        pay,
                        completedAt,
                      } = invoice.orders[index];

                      return (
                        <OrderInvoiceSummary
                          key={orderId}
                          count={parseInt(index, 10)}
                          orderId={orderId}
                          words={words}
                          pay={pay}
                          completedAt={completedAt}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
          </div>

          
          <div className="Card">
            <div className="Title">
              <FormattedMessage id="Invoice.Totals" />
            </div>
            <div className="section-invoice">
              {!!invoice.tax && (
                <div>
                  <div className="section-invoice__row">
                    <div className="section-invoice__row-label">Total HT</div>
                    <div className="section-invoice__row-amount">
                      {accounting.formatMoney(
                        invoice.subTotal,
                        '€',
                        2,
                        '.',
                        ','
                      )}
                    </div>
                  </div>
                  <div className="section-invoice__row">
                    <div className="section-invoice__row-label">
                      Total TVA 20.00 %
                    </div>
                    <div className="section-invoice__row-amount">
                      {accounting.formatMoney(invoice.tax, '€', 2, '.', ',')}
                    </div>
                  </div>
                </div>
              )}

              <div className="section-invoice__row">
                <div className="section-invoice__row-label">
                  Total {!!invoice.tax && 'TTC'}
                </div>
                <div className="section-invoice__row-amount">
                  {accounting.formatMoney(invoice.total, '€', 2, '.', ',')}
                </div>
              </div>
              {exemptionText && (
                <div className="section-invoice__row">
                  <div className="section-invoice__row-label" />
                  <div className="section-invoice__row-amount">
                    {exemptionText}
                  </div>
                </div>
              )}

              {invoiceEditable && (
                <button
                  type="submit"
                  disabled={submitting || !valid}
                  className="button"
                >
                  <FormattedMessage id="Invoice.Submit" />
                </button>
              )}
            </div>
          </div>
        </form>
        // </div>
      )}
    </Form>
  );
};

const actionsToProps = {
  doUpdateInvoice: updateInvoice,
};
export default connect(null, actionsToProps)(InvoiceForm);
