import React, { useState } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getProfile } from '../account/selectors';
import { rejectOrderAndRedirect } from '../actions';
// import Modal from "../components-translator/Modal";
import RejectionModal from '../orders-translator/RejectionModal';
import { timezone } from '../constants';
import Countdown from '../components-translator/Countdown';

import Thumbnail from '../attachments/Thumbnail';
import { getClientUploads } from '../orders/orderUtils';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styles from './ReviewLink.module.scss';

const ReviewLink = ({ form, user, reject, order, history }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const deadline = moment.tz(order.deadline, timezone);
  const someoneElseReviewing = order.reviewer && order.reviewer !== user._id;
  const reviewing = order.reviewer === user._id;
  const uploads = getClientUploads(order);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const checkIfSomeoneElseReview = (e) => {
    if (!someoneElseReviewing) {
      openModal();
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const closeModalFromComponent = () => closeModal();

  const rejectOrder = () => {
    //this.setState({ submitting: true });
    const reasonsObject = form.RejectionFormWizard.values.reason || {};
    const reasonsArray = [];

    // build reasons array
    for (const [key, value] of Object.entries(reasonsObject)) {
      if (value !== false) {
        if (key === 'otherDetail') {
          reasonsArray.push(value);
        } else {
          reasonsArray.push(key);
        }
      }
    }

    closeModal();
    reject(order, reasonsArray);
  };

  return (
    <div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (!someoneElseReviewing) {
            history.push(`/review/${order._id}`);
          }
        }}
      >
        {reviewing && (
          <div className="section-request__review pl-3">
            <FormattedMessage id="ReviewPage.Countdown.Start" />
            <Countdown time={moment.tz(order.reviewTime, timezone)} />
            <FormattedMessage id="ReviewPage.Countdown.End" />
          </div>
        )}

        <div
          className={`section-request${
            someoneElseReviewing ? ' section-request__disabled' : ''
          }`}
        >
          <div
            className="section-request__reject"
            onClick={(e) => checkIfSomeoneElseReview(e)}
          >
            &#xe920;
          </div>
          <div className={styles.OrderInfo}>
            {uploads.map((u, index) => (
              <div className={styles.UploadInfo} key={index}>
                <Thumbnail key={u.path} file={u.path} />
                {u.name}
              </div>
            ))}
            {!!order.text && (
              <div className={styles.UploadInfo}>{order.text}</div>
            )}
          </div>
          <div className="section-request__typeOffer">
            <span>
              <FormattedMessage
                id={
                  order.proofreading
                    ? 'Account.translatorProofreading.label'
                    : 'OrderLink.Translation'
                }
              />
            </span>
          </div>
          <div className="section-request__client">
            <span>
              {order.userDetails ? order.userDetails.givenName : 'INVALID'}
            </span>
          </div>
          <div className="section-request__price">
            <div className="section-request__price-inner">
              {accounting.formatMoney(order.pay, '', 2, '.', ',')}
            </div>
          </div>
          <div className="section-request__timing">
            <div className="section-request__timing-inner">
              {deadline.calendar()}
            </div>
          </div>

          <div className="section-request__arrow" />
        </div>
      </div>
      <Modal isOpen={modalIsOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          <FormattedMessage id="Translator.Reject.ReasonTitle" />
        </ModalHeader>
        <ModalBody>
          <RejectionModal
            handleReject={rejectOrder}
            showNotGeneral={order.specificity === 'general'}
            backFromRejectionModal={closeModalFromComponent}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

ReviewLink.propTypes = {
  order: PropTypes.object.isRequired,
};

ReviewLink.defaultProps = {
  order: null,
};

const stateToProps = (state) => ({
  form: state.form,
  user: getProfile(state),
});

const actionsToProps = (dispatch, ownProps) => ({
  reject: (order, reason) =>
    dispatch(
      rejectOrderAndRedirect({
        order,
        reason,
        browserHistory: ownProps.history,
      })
    ),
});

export default withRouter(connect(stateToProps, actionsToProps)(ReviewLink));
