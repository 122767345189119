import React from 'react';
import { FormattedMessage } from 'react-intl';
import Header from '../components/Header';

import ContactForm from './ContactForm';
import { Container, Row, Col } from 'reactstrap';

const ContactPage = () => (
  <>
    <Row className="header-container">
      <Col className="content-container">
        <Header />
      </Col>
    </Row>

    <Container >
      <div className="content-container body">
        <Row>
          <Col>
            <div className="section-success-message section-success-message--show">
              <p>
                <FormattedMessage id="Contact.HelpMessage" />
              </p>
            </div>
            <ContactForm />
          </Col>
        </Row>
      </div>
    </Container>
  </>
);

export default ContactPage;
