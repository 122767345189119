import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import CheckboxField from "../components/formfields/CheckboxField";
import {
  PATCH_ORDER_FAILED,
  PATCH_ORDER_FULFILLED,
  rateOrder,
  updateUser,
} from "../actions";
import ActionLink from "../components/ActionLink";
import CheckboxGroup from "../components/formfields/CheckboxGroup";
import TextareaField from "../components/formfields/TextareaField";
import TextField from "../components/formfields/TextField";
import { createFormAction } from "../utils/ReduxFormSaga";
import { getProfile } from "../account/selectors";

class FeedbackModal extends React.Component {
  state = {
    rating: 0,
  };

  setRating = (rating) => {
    this.setState({ rating });
  };

  onSubmit = (payload, dispatch) => {

    const rating = {
      ...payload,
      clientStars: this.state.rating,
      _id: this.props.order._id,
    };
    const formAction = createFormAction(rateOrder, [
      PATCH_ORDER_FULFILLED,
      PATCH_ORDER_FAILED,
    ]);
    formAction(rating, dispatch);
    this.props.onClose();
  };

  questionHTML() {
    let questionHTML = undefined;
    const { rating } = this.state;
    const { feedback } = this.props;
    if (rating >= 5) {
      const other = feedback && feedback.includes("Feedback.Good.Other");

      const options = [
        { value: "Feedback.Good.Speed" },
        { value: "Feedback.Good.Quality" },
        { value: "Feedback.Good.Interaction" },
        { value: "Feedback.Good.Easy" },
        { value: "Feedback.Good.Value" },
        { value: "Feedback.Good.Other" },
      ];

      questionHTML = (
        <div>
          <div className="feedback-modal__question">
            <FormattedMessage id="Feedback.Good.Question" />
          </div>
          <div className="feedback-modal__answers">
            <CheckboxGroup
              name="clientFeedback"
              options={options}
              labelClass="feedback-modal__label"
            />
            {other && (
              <Field
                component={TextField}
                name="clientFeedbackOther"
                placeholder=""
                label=""
                className="feedback-modal__input-text"
              />
            )}
            <div className="feedback-modal__question">
              <FormattedMessage id="Feedback.Review" />
            </div>
            <div className="feedback-modal__answers">
              <Field
                component={TextareaField}
                name="clientReview"
                placeholder=""
                label=""
                className="feedback-modal__input-text"
              />
            </div>
          </div>
        </div>
      );
    } else if (rating > 0) {
      const other = feedback && feedback.includes("Feedback.Bad.Other");
      const options = [
        { value: "Feedback.Bad.Ease" },
        { value: "Feedback.Bad.Quality" },
        { value: "Feedback.Bad.Interaction" },
        { value: "Feedback.Bad.Price" },
        { value: "Feedback.Bad.Speed" },
        { value: "Feedback.Bad.Other" },
      ];

      questionHTML = (
        <div>
          <div className="feedback-modal__question">
            <FormattedMessage id="Feedback.Bad.Question" />
          </div>
          <div className="feedback-modal__answers">
            <CheckboxGroup
              name="clientFeedback"
              options={options}
              labelClass="feedback-modal__label"
            />
            {other && (
              <Field
                component={TextField}
                name="clientFeedbackOther"
                placeholder=""
                label=""
                className="feedback-modal__input-text"
              />
            )}
          </div>
        </div>
      );
    }
    return questionHTML;
  }

  render() {
    const { handleSubmit } = this.props;
    const givenName = this.props.order.translatorDetails.givenName;
    const { rating } = this.state;
    const questionHTML = this.questionHTML();

    return (
      <div className="feedback-modal">
        <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="feedback-modal__photo">
            <img
              src={this.props.order.translatorDetails.picture}
              alt={givenName}
              className="feedback-modal__img"
            />
            <div>
              <FormattedMessage id="Order.WriteReview" /> {givenName}
            </div>
          </div>
          <div className="feedback-modal__rating">
            <div className={`rating rating--${rating}`}>
              <div
                className="rating__star"
                onClick={() => {
                  this.setRating(1);
                }}
              />
              <div
                className="rating__star"
                onClick={() => {
                  this.setRating(2);
                }}
              />
              <div
                className="rating__star"
                onClick={() => {
                  this.setRating(3);
                }}
              />
              <div
                className="rating__star"
                onClick={() => {
                  this.setRating(4);
                }}
              />
              <div
                className="rating__star"
                onClick={() => {
                  this.setRating(5);
                }}
              />
            </div>
          </div>
          <div className="feedback-modal__details">
            <div className="feedback-modal__details-inner">{questionHTML}</div>
          </div>

          <div className="feedback-modal__submit">
            <button className="button button--inline">
              <FormattedMessage id="Forms.Submit" />
            </button>
            <div className="feedback-modal__skip">
              <ActionLink onClick={this.props.onClose}>
                <FormattedMessage id="Feedback.Close" />
              </ActionLink>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

FeedbackModal = reduxForm({
  form: "feedback",
})(FeedbackModal);

const selector = formValueSelector("feedback");

FeedbackModal.propTypes = {
  doUpdateUser: PropTypes.func.isRequired,
};
const stateToProps = (state) => ({
  feedback: selector(state, "clientFeedback"),
  user: getProfile(state),
});
const mapDispatchToProps = {
  doUpdateUser: updateUser,
};
export default connect(stateToProps, mapDispatchToProps)(FeedbackModal);
