import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProfile } from '../account/selectors';
import Account from '../account/Account';
import AccountPropType from './../common/proptypes/AccountPropType';
import Header from '../components/Header';
import { Container, Row, Col } from 'reactstrap';
import { isTranslator } from '../account/selectors';

const AccountPage = ({ profile, isTranslator }) => {
  let left = null;
  let center = null;
  if (isTranslator) {
    left = <Link to="/translator/settings" className="icon-arrow-left pink" />;
    center = <Link to="/" className="header__icon icon-my-brian" />;
  }

  return (
    <>
      <Row className="header-container">
        <Col className="content-container">
          <Header left={left} center={center} />
        </Col>
      </Row>

      <Container >
        <div className="content-container body">
          <Row>
            <Col>
              {profile && (
                <Account profile={profile} isTranslator={isTranslator} />
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

AccountPage.propTypes = {
  profile: AccountPropType.isRequired,
};

const stateToProps = (state) => ({
  profile: getProfile(state),
  isTranslator: isTranslator(state),
});

export default connect(stateToProps)(AccountPage);
