import React from "react";
import { FormattedMessage } from "react-intl";

const Loading = () => (
  <div>
    <FormattedMessage id="Loading" />
  </div>
);

export default Loading;
