import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import NewButton from './NewButton';

const PaymentButton = ({
  order,
  user,
  company,
  enabled,
  onConfirm,
  onLogin,
  priceWithTax,
  discountedPriceWithTax,
  discountReason,
}) => {
  const loggedIn = !!user;

  const price =
    discountReason && discountedPriceWithTax
      ? discountedPriceWithTax
      : priceWithTax;
  const canChargeCompany =
    company &&
    order &&
    company.creditLimit - price >= get(company, 'balance', 0);

  if (!loggedIn) {
    return (
      <NewButton type="button" onClick={onLogin}>
        <FormattedMessage id="Login" />
      </NewButton>
    );
  }
  if (canChargeCompany) {
    return (
      <NewButton disabled={!enabled} onClick={onConfirm}>
        <FormattedMessage
          id="Order.ChargeTo"
          values={{ company: company.name }}
        />
      </NewButton>
    );
  }

  return (
    <div>
      {company && (
        <ControlLabel className={`section-textarea__message warning`}>
          <FormattedMessage
            id="Order.ChargeTo.NotEnoughCredit"
            values={{ company: company.name }}
          />
        </ControlLabel>
      )}
      <NewButton disabled={!enabled} onClick={onConfirm}>
        <FormattedMessage id="Confirm" />
      </NewButton>
    </div>
  );
};

PaymentButton.propTypes = {
  order: PropTypes.object,
  user: PropTypes.object,
  company: PropTypes.object,
  enabled: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
};

PaymentButton.defaultProps = {
  order: undefined,
  user: undefined,
  company: undefined,
  enabled: true,
};

export default PaymentButton;
