import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import { getProfile } from '../account/selectors';
import { getInvoice } from '../actions';
import Header from '../components/Header';
import InvoiceForm from '../invoices/InvoiceForm';

const EditInvoicePageTranslator = ({
  invoice,
  profile,
  match,
  doGetInvoice,
}) => {
  useEffect(() => {
    doGetInvoice(match.params.id);
  }, [doGetInvoice, match]);

  return (
    <>
      <Row className="header-container">
        <Col className="content-container">
          <Header />
        </Col>
      </Row>

      <Container >
        <div className="content-container body">
          <Row>
            <Col>
              <InvoiceForm
                initialValues={invoice}
                invoice={invoice}
                profile={profile}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

EditInvoicePageTranslator.propTypes = {
  invoice: PropTypes.object,
  profile: PropTypes.object.isRequired,
  doGetInvoice: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  invoice: state.invoices.data,
  profile: getProfile(state),
});

const dispatchToProps = {
  doGetInvoice: getInvoice,
};

export default connect(
  stateToProps,
  dispatchToProps
)(EditInvoicePageTranslator);
