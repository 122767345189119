import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import cookie from '../cookie';

const UserIsAuthenticated = connectedRouterRedirect({
  authenticatedSelector: (state) => !!state.auth.user, // how to get the user state
  redirectPath: '/login',
  authenticatingSelector: (state) => state.auth.isLoading || !!cookie.getItem('feathers-jwt'),
  wrapperDisplayName: 'UserIsAuthenticated', // a nice name for this auth check
});

export default UserIsAuthenticated;
