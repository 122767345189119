import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { useHistory } from "react-router-dom";
import AccountPropType from "./../common/proptypes/AccountPropType";
import { createNotification } from "../actions";

import TextField from "../components/formfields/TextField";
import TextareaField from "../components/formfields/TextareaField";
import SelectField from "../components/formfields/SelectField";
import { getProfile } from "../account/selectors";
import feathers from "../feathers";
import ButtonGroupField from "../components/formfields/ButtonGroupField";
import ConditionalField from "../components/formfields/ConditionalField";
import composeValidators from "../utils/composeValidators";

const required = (value) => (value ? undefined : "Forms.Required");
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Forms.InvalidEmail"
    : undefined;

const contactReasons = [
  "Contact.Reason.HelpWithOrder",
  "Contact.Reason.CorporateAccount",
  "Contact.Reason.BecomeTranslator",
  "Contact.Reason.SomethingElse",
];

const ContactForm = ({ profile, doCreateNotification }) => {
  const intl = useIntl();
  const history = useHistory();

  const submit = (values) => {
    const subject = intl.formatMessage({ id: values.reason });
    const message = {
      subject,
      from: values.email,
      templateData: { ...values, subject },
    };
    feathers.service("mailer").create(message);
    if (!profile) {
      doCreateNotification({ subject: "Notification.MessageSent" });
    }
    history.push("/");
  };

  const contactMethodOptions = ["Email", "Phone"].map((value) => ({
    value,
    text: intl.formatMessage({ id: value }),
  }));
  const contactReasonOptions = contactReasons.map((reason) => ({
    value: reason,
    text: intl.formatMessage({ id: reason }),
  }));
  
  return (
    <Form onSubmit={submit} initialValues={profile}>
      {({ handleSubmit, error, pristine, submitting, valid }) => (
        <form onSubmit={handleSubmit}>
          <div className="section-panel">
            <label className="control-label">
              {intl.formatMessage({ id: "Contact.Method" })}
            </label>
            <Field
              component={ButtonGroupField}
              name="method"
              options={contactMethodOptions}
              validate={required}
            />
                <ConditionalField when="method" is="Email">
                  <Field
                    component={TextField}
                    name="email"
                    label="Account.Email"
                    validate={composeValidators(email, required)}
                    placeholder="Forms.EmailPlaceholder"
                  />
                </ConditionalField>

                <ConditionalField when="method" is="Phone">
                  <Field
                    component={TextField}
                    name="phone"
                    label="Account.Phone"
                    validate={required}
                  />
                </ConditionalField>


            <Field
              component={TextField}
              name="givenName"
              label="Contact.Name"
              validate={required}
            />

            <Field
              component={SelectField}
              name="reason"
              label="Contact.Reason"
              options={contactReasonOptions}
              validate={required}
            />
            <ConditionalField
              when="reason"
              is="Contact.Reason.CorporateAccount"
            >
              <Field
                label="Company.Name"
                name="companyName"
                component={TextField}
                placeholder="Chat.Text"
                validate={required}
              />
            </ConditionalField>

            <Field
              key="message"
              name="message"
              component={TextareaField}
              placeholder="Chat.Text"
              label="Contact.Message"
              validate={required}
            />
          </div>

          
          <div className="section-general text-center">
            <button
              type="submit"
              disabled={error || !valid || pristine || submitting}
              className="button button--inline"
            >
              <FormattedMessage id="Forms.Submit" />
            </button>
          </div>
        </form>
      )}
    </Form>
  );
};

ContactForm.propTypes = {
  profile: AccountPropType,
  doCreateNotification: PropTypes.func.isRequired,
};

ContactForm.defaultProps = {
  profile: undefined,
};

const stateToProps = (state) => ({
  profile: getProfile(state),
});

const actionsToProps = {
  doCreateNotification: createNotification,
};

export default connect(stateToProps, actionsToProps)(ContactForm);
