import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import OrderPropType from '../../common/proptypes/OrderPropType';
import Flag from '../../components/Flag';
import OrderSummary from '../../orders/OrderSummary';
import { getOrderByIdForTranslator } from '../../orders/selectors';
import ActionLink from '../ActionLink';
import { hideChat, notifyViewingChat } from './../../actions';
import Chat from './Chat';
import style from './ChatWindow.module.scss';

const ChatWindowTranslator = ({
  orderId,
  close,
  order,
  doNotifyViewingChat,
  admin,
}) => {
  const person = admin ? null : get(order, 'userDetails.givenName', false);

  useEffect(() => {
    if (orderId) {
      doNotifyViewingChat({
        recipient: null, // used by admins only
        order: admin ? null : orderId,
      });
    } else {
      doNotifyViewingChat(false);
    }
    return () => {
      doNotifyViewingChat(false);
    };
  }, [orderId, doNotifyViewingChat, admin]);
  if (orderId) {
    return (
      <div className="chat__container">
        <div className="chat__header">
          <div className={style.Header}>
            <div>
              <ActionLink onClick={() => close()} className="icon-cross pink" />
            </div>
            <div>
              <span className="dark">
                {admin && <FormattedMessage id="Chat.Admin" />}
                {person && (
                  <FormattedMessage
                    id="Chat"
                    values={{
                      person,
                    }}
                  />
                )}
              </span>
            </div>

            {admin ? (
              <div />
            ) : (
              <Flag country={order.userDetails.lang} includeText={false} />
            )}
          </div>
        </div>
        <OrderSummary order={order} />
        <Chat orderId={orderId} />
      </div>
    );
  }
  return null;
};

ChatWindowTranslator.propTypes = {
  admin: PropTypes.bool,
  close: PropTypes.func.isRequired,
  doNotifyViewingChat: PropTypes.func.isRequired,
  order: OrderPropType,
  orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

ChatWindowTranslator.defaultProps = {
  admin: false,
  order: null,
  orderId: null,
};
const stateToProps = (state, ownProps) => ({
  order: getOrderByIdForTranslator(state, ownProps.orderId),
});

const dispatchToProps = {
  close: hideChat,
  doNotifyViewingChat: notifyViewingChat,
};

export default connect(stateToProps, dispatchToProps)(ChatWindowTranslator);
