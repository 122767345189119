import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import CheckboxField from '../components/formfields/CheckboxField';
import Card from '../layout/Card';
import { settings } from '../selectors';
import { faSpellCheck } from "@fortawesome/free-solid-svg-icons";

import { updateUser } from './../actions';
import AccountPropType from './../common/proptypes/AccountPropType';

const AccountDetailsTranslatorProofreading = ({
  profile,
  languagesList,
  doUpdateUser,
}) => {
  const browserHistory = useHistory();
  const intl = useIntl();
  const [formIsUpdated, setFormIsUpdated] = useState(false);
  // Only destination languages (l[1]) are token.
  const proofreadingLanguages = profile.canTranslate
    .map((lang) => {
      const l = lang.couple.split('-', 2);
      return languagesList[profile.lang].find((obj) => obj.key === l[1]);
    })
    .filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
  return (
    <>
      <Prompt
        when={formIsUpdated}
        message={intl.formatMessage({ id: 'General.YouHaveUnsavedChanges' })}
      />
      <Form
        onSubmit={(user) => doUpdateUser({ user, browserHistory })}
        initialValues={profile}
      >
        {({ handleSubmit, values }) => (
          <div>
            <FormSpy
              subscription={{ dirty: true }}
              onChange={(props) => setFormIsUpdated(props.dirty)}
            />
            <form onSubmit={handleSubmit}>
              <Card
                fill
                title="Account.translatorProofreading.title"
                faIcon={faSpellCheck}
              >
                <Row>
                  <Col xs="12" className="my-3">
                    <p className="control-label">
                      <FormattedMessage id="Account.translatorProofreading.explanations" />
                    </p>
                    <p className="control-label">
                      <FormattedMessage id="Account.translatorProofreading.explanations2" />
                    </p>
                    <ul>
                      {proofreadingLanguages &&
                        proofreadingLanguages.map((language) => (
                          <li key={language.name}>{language.name}</li>
                        ))}
                    </ul>
                  </Col>
                  <Col xs="12" className="my-3">
                    <Field
                      component={CheckboxField}
                      name="proofreading"
                      type="checkbox"
                      customLabel={
                        <FormattedMessage id="Account.translatorProofreading.label2" />
                      }
                      className="align-self-center"
                    />
                  </Col>
                  <Col xs="12" className="my-3">
                    <p className="control-label">
                      <FormattedMessage id="Account.translatorProofreading.explanations3" />
                    </p>
                  </Col>
                </Row>
              </Card>

              <Row>
                <Col align="center">
                  <Button type="submit" className="button button--inline">
                    <FormattedMessage id="Forms.Submit" />
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        )}
      </Form>
    </>
  );
};

AccountDetailsTranslatorProofreading.propTypes = {
  profile: AccountPropType.isRequired,
  doUpdateUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  languagesList: settings(state).languagesList,
});

const mapDispatchToProps = {
  doUpdateUser: updateUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsTranslatorProofreading);
